import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h20v20H-3z" />
        <path
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
          d="M1 9.352L5.564 13 13 1"
        />
      </g>
    </svg>
  )
})
