import { fetchingStatusesSelector } from 'selectors/app'
import { selectCurrentNavigationSearches } from 'selectors/mailboxes'
import { constructSearchQueryString } from 'util/search'
import { doFetchTicketAggregationCountsFor } from './doFetchTicketAggregationCountsFor'

export function doFetchTicketAggregationCountsForUnloadedNavigationSections(
  overrides = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const statuses = fetchingStatusesSelector(state)
    const currentNavigationSearches = selectCurrentNavigationSearches(state)

    currentNavigationSearches.forEach(search => {
      const overriddenSearch = Object.assign({}, search, overrides)
      const queryString = constructSearchQueryString(overriddenSearch)
      if (statuses[`ticketAggregationCounts-${queryString}`] !== false) {
        dispatch(doFetchTicketAggregationCountsFor(overriddenSearch))
      }
    })
  }
}
