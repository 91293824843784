import { batchActions } from 'util/redux'
import { STATES as STORE_STATES } from 'ducks/bootstrap/constants'

export default function createBootstrapLoader(
  loaders,
  options = { refetch: false }
) {
  // Additional wrapper to to allow us to continue using the perform
  // pattern that we use everywhere in the app
  return () => {
    return async (dispatch, getState) => {
      const { bootstrap = {} } = getState()
      const unloadedStates = [STORE_STATES.INITIAL]
      if (options.refetch) {
        unloadedStates.push(STORE_STATES.LOADED)
      }
      const unloadedLoaders = loaders.filter(({ key }) =>
        unloadedStates.includes(bootstrap[key])
      )

      const startActions = unloadedLoaders.map(loader => loader.start())
      dispatch(batchActions(startActions))

      const loadPromises = unloadedLoaders.map(loader =>
        loader.promise(getState)
      )
      // Complete action can be a failure or successs.
      const completeActions = await Promise.all(loadPromises)
      dispatch(batchActions(completeActions))
    }
  }
}
