import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="M15.6 11.8c0-.4.4-.8.8-.8s.8.4.8.8v7.7c0 .9-.7 1.5-1.5 1.5h-5c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h4.9v-7.6zm-3.3-1.9c-.4-.6-.7-1.3-.9-2.1H8.5V20c0 .8-.8 1.2-1.4.8l-1.6-1.1-1.6 1.1c-.6.4-1.4 0-1.4-.8V7.8c0-.9.7-1.6 1.6-1.6h7.4C11.9 3.8 14 2 16.4 2c2.8 0 5.1 2.3 5.1 5 0 2.8-2.3 5-5.1 5-.9 0-1.8-.2-2.5-.7-.1.1-.3.1-.5.1h-2.8c-.4 0-.8-.4-.8-.8s.4-.8.8-.8l1.7.1zM6.9 7.8H4.1v10.9l.8-.5c.4-.3.9-.3 1.3 0l.8.5V7.8h-.1zM20 7c0-2-1.6-3.5-3.6-3.5S12.8 5.1 12.8 7c0 2 1.6 3.5 3.6 3.5C18.4 10.6 20 9 20 7zm-2.8-.7h1.4c.4 0 .7.3.7.8 0 .4-.3.7-.7.7h-1.4v1.4c0 .4-.3.8-.7.8-.4 0-.7-.3-.7-.7V7.8h-1.4c-.4 0-.7-.3-.7-.7 0-.4.3-.7.7-.7h1.4V4.9c0-.4.3-.8.7-.8.4 0 .7.3.7.8v1.4zm-6.5 8.1c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h2.8c.4 0 .8.4.8.8s-.4.8-.8.8h-2.8zm0 2.9c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h2.8c.4 0 .8.4.8.8s-.4.8-.8.8h-2.8z"
      />
    </svg>
  )
})
