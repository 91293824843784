import * as types from 'constants/action_types'
import { deleteTicketsMutation } from 'api/tickets'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { doGraphqlRequest } from 'ducks/requests/operations'

export function doHardDeleteTickets(ticketIds) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketsById = selectTicketsById(state)
    const tickets = ticketIds.map(id => ticketsById[id])

    return dispatch(
      doGraphqlRequest(
        types.DELETE_TICKETS,
        deleteTicketsMutation,
        {
          tickets,
          deleteMode: 'HARD',
        },
        {
          includeLegacyPayload: true,
          transformVariables: variables => {
            return {
              deleteMode: variables.deleteMode,
              ticketIds: variables.tickets.map(t => t.id),
            }
          },
          transformResponse: data => {
            return {
              tickets: (data?.deleteTickets || []).map(
                ticket => ticketsById[ticket.id]
              ),
            }
          },
        }
      )
    )
  }
}
