import { selectLabelSelection } from 'selectors/labelings'
import { doHandleLabelSelection } from '../shared/doHandleLabelSelection'

// Newer API wrapper around doHandleLabelSelection. In mobile we pass
// around a `labelSelection` object everywhere though was never mutated from the
// one in the store. We simplify this by selecting here.
export function doSelectLabel(label) {
  return (dispatch, getState) => {
    const labelSelection = selectLabelSelection(getState())

    return dispatch(doHandleLabelSelection(label, labelSelection))
  }
}
