import { UPDATE_LABELS } from 'constants/action_types'

export const doUpdateLabels = (labels, removedLabels) => {
  return {
    type: UPDATE_LABELS,
    data: {
      labels,
      removedLabels,
    },
  }
}
