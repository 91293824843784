import { v4 as uuidV4 } from 'uuid'
import { BEGIN, REVERT } from 'redux-optimist'
import * as types from 'constants/action_types'
import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'
import { labelChangePayload } from 'optimistic/label'
import { selectCurrentFolderId } from 'selectors/app'
import { selectAdded, selectRemoved } from 'selectors/labelings'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { getLength } from 'util/arrays'
import debug from 'util/debug'
import { getId } from 'util/labels'

export default function applyLabelings(state, dispatch, ticketId) {
  const addedLabels = selectAdded(state)
  const removedLabels = selectRemoved(state)
  const transactionId = uuidV4()
  const addedLabelIds = addedLabels.map(getId)
  const removedLabelIds = removedLabels.map(getId)
  const payload = {
    addedLabels,
    removedLabels,
    addedLabelIds,
    removedLabelIds,
    ticketId,
  }

  // Do nothing if no net change.
  const addedCount = getLength(addedLabelIds)
  const removedCount = getLength(removedLabelIds)
  if (addedCount + removedCount <= 0) {
    debug('no net label changes to apply!')
    return false
  }

  dispatch({
    type: types.UPDATE_LABELING_REQUEST,
    data: payload,
    optimist: { type: BEGIN, id: transactionId },
  })

  const requestPayload = { ticketId }
  if (addedCount) requestPayload.labels = addedLabels
  if (removedCount) requestPayload.removeLabels = removedLabels

  const changesetId = uuidV4()
  const optimisticData = labelChangePayload(
    state,
    ticketId,
    addedLabels,
    removedLabels,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state),
    changesetId
  )

  return dispatch(
    doSendChangeset(ticketId, requestPayload, {
      optimisticData,
      changesetId,
      skipTicketTouch: true,
      disableNavigateToFolder: true,
    })
  )
    .then(() => {
      return dispatch({
        type: types.UPDATE_LABELING_SUCCESS,
        data: payload,
        optimist: { type: REVERT, id: transactionId },
      })
    })
    .catch(err => {
      return dispatch({
        type: types.UPDATE_LABELING_FAIL,
        data: { ...payload, err },
        optimist: { type: REVERT, id: transactionId },
      })
    })
}
