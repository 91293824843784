import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.7 11.6h-1.1V8.3A3.3 3.3 0 0 0 19.3 5h-11A3.3 3.3 0 0 0 5 8.3v13.2a1.1 1.1 0 0 0 .682 1.012c.13.061.274.091.418.088a1.1 1.1 0 0 0 .781-.32l3.091-3.101H11.6v1.584a3.3 3.3 0 0 0 3.3 3.3h7.612l2.607 2.618A1.1 1.1 0 0 0 25.9 27a.924.924 0 0 0 .418-.088A1.1 1.1 0 0 0 27 25.9v-11a3.3 3.3 0 0 0-3.3-3.3Zm-12.1 3.3v2.079H9.521a1.1 1.1 0 0 0-.781.319l-1.54 1.55V8.3a1.1 1.1 0 0 1 1.1-1.1h11a1.1 1.1 0 0 1 1.1 1.1v3.3h-5.5a3.3 3.3 0 0 0-3.3 3.3Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
