import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 15"
      {...rest}
      ref={forwardedRef}
    >
      <g>
        <path
          className="stroke"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M1.22 1.08L5 5.84"
        />
        <path
          className="stroke"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M8.73 1.08L6.5 3.5"
        />
        <path
          className="stroke"
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M5 5.84V13"
        />
        <path
          className="fill"
          d="M5 14.93l3.63-3.43a.33.33 0 0 0-.21-.57H1.53a.33.33 0 0 0-.21.57z"
        />
      </g>
    </svg>
  )
})
