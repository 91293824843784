import { titleChangePayload } from 'optimistic/title'

import * as types from 'constants/action_types'

import {
  selectCurrentFolderId,
  selectEditingTicketTitleDraft,
} from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import {
  selectHasTicketTitleChanged,
  selectCurrentTicketTitle,
} from 'selectors/tickets'

import { doSendChangeset } from 'actions/tickets/changeset/doSendChangeset'

export const doUpdateTitle = ticketId => (dispatch, getState) => {
  const state = getState()
  const hasTitleChanged = selectHasTicketTitleChanged(state)
  const previousTitle = selectCurrentTicketTitle(state)
  const title = selectEditingTicketTitleDraft(state) // Ideally passed as an arg.

  dispatch({
    type: types.TICKET_TITLE_UPDATE_REQUEST,
    data: { hasTitleChanged, ticketId, title },
  })

  if (!hasTitleChanged) return false

  const optimisticData = titleChangePayload(
    state,
    ticketId,
    title,
    previousTitle,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state)
  )

  return dispatch(
    doSendChangeset(
      ticketId,
      { title },
      { optimisticData, disableNavigateToFolder: true }
    )
  )
}
