import {
  changeEntity,
  mergeEntityChanges,
  clearEntities,
} from 'ducks/entities/actionUtils'
import { FETCH_GROUPS_SUCCESS } from 'constants/actionTypes/groups'

export default function successAction(res) {
  const data = res.json.data
  const groups = (data && data.groups) || []
  return {
    type: FETCH_GROUPS_SUCCESS,
    payload: { ...data },
    ...mergeEntityChanges([
      ...groups.map(agent => {
        return changeEntity('team', agent.id, agent)
      }),
      clearEntities('team'),
    ]),
  }
}
