import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h24v24H-3z" />
        <g className="stroke" strokeLinecap="round" strokeWidth="1.956">
          <path d="M1.775 5.56a8.002 8.002 0 0 0 4.043 10.781M9 17a7.973 7.973 0 0 0 5.523-2.213A8 8 0 1 0 3.343 3.343" />
          <path d="M11.673 12.42A4.333 4.333 0 0 0 13.34 9c0-1.4-.663-2.645-1.692-3.44M9 4.66a4.34 4.34 0 0 0 0 8.68" />
        </g>
      </g>
    </svg>
  )
})
