import { selectDeletedSearch } from 'selectors/left_nav'
import { selectPinnedSearches } from 'selectors/pinned_searches'
import { doFetchTicketSearchCountsFor } from './doFetchTicketSearchCountsFor'

export function doFetchTicketSearchCountsForPinnedSearches(
  overrides,
  options = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const starredSearches = selectPinnedSearches(state).map(search => {
      return Object.assign({}, search, overrides)
    })
    // We add in deleted search to starred search, because it is a search item
    // under the closed subsection. But it won't come back in the aggregations
    // because they exclude deleted tickets.
    starredSearches.push(selectDeletedSearch(state))
    return dispatch(doFetchTicketSearchCountsFor(starredSearches, options))
  }
}
