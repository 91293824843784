import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M25.473 23.91a7.696 7.696 0 0 0-3.013-11.62 8.795 8.795 0 1 0-15.6 6.915l-1.528 1.517a1.1 1.1 0 0 0-.231 1.199 1.1 1.1 0 0 0 1.011.681h6.256A7.696 7.696 0 0 0 19.305 27h6.596a1.1 1.1 0 0 0 1.012-.682 1.1 1.1 0 0 0-.231-1.198l-1.21-1.21Zm-13.864-4.606c.002.368.031.736.088 1.1H8.762l.385-.374a1.1 1.1 0 0 0 0-1.562 6.52 6.52 0 0 1-1.935-4.661 6.597 6.597 0 0 1 6.596-6.597 6.53 6.53 0 0 1 6.212 4.398h-.715a7.696 7.696 0 0 0-7.696 7.696Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
