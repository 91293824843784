import * as React from 'react'

function SvgComponent(props, svgRef) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
      {...props}
    >
      <path
        d="M11.947 6.076a.762.762 0 00-.762.092l-3.642 2.88H4.762A.762.762 0 004 9.81v4.571c0 .42.341.762.762.762h2.78l3.605 2.88c.134.107.3.166.472.168a.695.695 0 00.328-.077.762.762 0 00.434-.685V6.762c0-.293-.17-.56-.434-.686zm-1.09 9.768l-2.575-2.057a.762.762 0 00-.472-.168H5.524v-3.048H7.81a.762.762 0 00.472-.167l2.575-2.057v7.497zm6.598-8.061a.765.765 0 00-1.082 1.082 4.571 4.571 0 01-.29 6.735.762.762 0 00.488 1.341c.178 0 .351-.062.488-.175a6.095 6.095 0 00.396-8.983zM15.3 9.939a.765.765 0 10-1.082 1.082c.286.284.448.67.45 1.074 0 .45-.198.876-.541 1.166a.762.762 0 00-.1 1.074.762.762 0 001.075.092 3.048 3.048 0 001.09-2.332 3.093 3.093 0 00-.892-2.156z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}

const ForwardRef = React.forwardRef(SvgComponent)
export default ForwardRef
