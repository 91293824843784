export const getChangesetParamsIfNeeded = ticket => {
  const hash = window.location.hash
  const hashId = hash ? hash.replace(/^#changeset-/, '') : undefined
  if (!hashId) return null
  const record = ticket.actions.records.find(
    r => `${hashId}-collapsed` === r.changeset
  )
  if (!record) return null
  return {
    ticketId: ticket.id,
    changesetId: hashId,
    subchangesetIds: record.change.subchangeset_ids,
  }
}
