import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="m15.3262762.15031298c-.5726833-.09767261-1.1525873-.14794841-1.733699-.15030955-1.9826763-.00160601-3.9240035.561336-5.5925772 1.62172724-1.6728613-1.04646843-3.61388266-1.59559359-5.59257723-1.58216956-.5811117.0023577-1.16101564.0526335-1.73369894.15030611-.38754629.06615634-.66883702.40172887-.66320546.79108478v9.4930174c-.00337864.2353332.09916336.4599737.27985282.6128908s.42076119.2182299.65499211.178194c2.31295309-.3965314 4.68962607.1482947 6.5912517 1.5109719l.0958728.0553759h.0878833c.1789625.0738346.3802953.0738346.5592578 0h.0878833l.0958728-.0553759c1.8884827-1.3932297 4.2663312-1.9697183 6.5912517-1.5979913.2342309.0400359.4743026-.0252768.6549921-.1781939s.2832315-.3775576.2798523-.6128908v-9.49301743c-.0172137-.3713585-.2931658-.68081003-.6632049-.74361969zm-8.1252158 10.51351672c-1.47813139-.7699722-3.12343997-1.1718249-4.79363763-1.1708054h-.7989396v-7.91084787c.26609242-.01518489.53284718-.01518489.7989396 0 1.70444641-.00186516 3.37169632.49339232 4.79363763 1.42395261zm7.1904564-1.139162h-.7989396c-1.6701976-.0010195-3.3155062.4008332-4.7936376 1.1708054v-7.68934406c1.4219413-.93056029 3.0891912-1.42581777 4.7936376-1.42395261.2660925-.01518489.5328472-.01518489.7989396 0zm.9347594 3.2830018c-.5726833-.0976726-1.1525873-.1479484-1.733699-.1503095-1.9826763-.0016061-3.9240035.5613359-5.5925772 1.6217272-1.6685737-1.0603913-3.60990095-1.6233333-5.59257723-1.6217272-.5811117.0023611-1.16101564.0526369-1.73369894.1503095-.21005261.0330062-.398145.1475788-.52261302.3183389-.12446801.1707602-.17503749.3836126-.14050685.5914086.08453139.4277103.50250903.7071598.93475934.624957 2.31295309-.3965314 4.68962607.1482947 6.5912517 1.5109719.2773633.1955388.6494067.1955388.92677 0 1.9016256-1.3626772 4.2782986-1.9075033 6.5912517-1.5109719.4322503.0822028.8502279-.1972467.9347593-.624957.0345307-.207796-.0160388-.4206484-.1405068-.5914086-.124468-.1707601-.3125604-.2853327-.522613-.3183389z"
      />
    </svg>
  )
})
