import {
  changeEntity,
  mergeEntityChanges,
  clearEntities,
} from 'ducks/entities/actionUtils'
import searchesActionModule from 'ducks/requests/modules/searchesActionModule'
import { FETCH_AGENTS_SUCCESS } from 'constants/action_types'
import { AGENT_ACTIVE_OR_ARCHIVED_DATA_TABLE_QUERY_ID } from 'ducks/agents/constants'

export default function successAction(
  res,
  { queryId = AGENT_ACTIVE_OR_ARCHIVED_DATA_TABLE_QUERY_ID } = {}
) {
  const data = res.json.data
  const agents = (data && data.agents) || []
  const action = {
    type: FETCH_AGENTS_SUCCESS,
    data: { ...data },
    ...mergeEntityChanges([
      ...agents.map(agent => {
        return changeEntity('agent', agent.id, agent)
      }),
      clearEntities('agent'),
    ]),
  }

  return searchesActionModule('SUCCESS', action, agents, {
    searches: {
      extractPagination: nodes => {
        return {
          type: 'agent',
          nodes,
          startCursor: 1,
          endCursor: 1,
          hasNextPage: false,
          hasPreviousPage: false,
          totalCount: nodes.length,
          totalPageCount: 1,
        }
      },
      cursor: 'all',
      queryId,
    },
  })
}
