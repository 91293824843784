import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="#6772e5"
      d="M13.706 9.663c0-1.394 1.162-1.931 3.025-1.931 2.713 0 6.156.831 8.869 2.294V1.633C22.644.452 19.694.002 16.737.002c-7.231 0-12.05 3.775-12.05 10.087 0 9.869 13.55 8.269 13.55 12.525 0 1.65-1.431 2.181-3.419 2.181-2.95 0-6.763-1.219-9.756-2.844v8.031a24.75 24.75 0 0 0 9.75 2.025c7.413 0 12.519-3.188 12.519-9.6 0-10.637-13.625-8.731-13.625-12.744z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
