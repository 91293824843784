import {
  showErrorNotification,
  showWarningNotification,
  dismissNotification,
} from 'ducks/notifications'

import { selectShowExperimentalRealtimeWarnings } from 'selectors/app'

const DISCONNECTED = 'realtime-disconnected'
const BACKLOG_DESYNC = 'realtime-backlog-desync'

let backlogDesynchronized = false

export function doRealtimeConnected() {
  return (dispatch, getState) => {
    // Feature flag - can be eventually removed
    if (!selectShowExperimentalRealtimeWarnings(getState())) return false

    return dispatch(dismissNotification(DISCONNECTED))
  }
}

export function doRealtimeDisconnected() {
  return (dispatch, getState) => {
    // If something is seriously wrong, we dont want to continually pop up
    // disconnect and reconnect errors.
    if (backlogDesynchronized) return Promise.resolve(false)

    // Feature flag - can be eventually removed
    if (!selectShowExperimentalRealtimeWarnings(getState())) return false

    return dispatch(
      showWarningNotification({
        header: 'Connectivity issue',
        id: DISCONNECTED, // prevents duplicate notifications
        message: 'Attempting to re-establish connection',
        messageEllipsis: true,
      })
    )
  }
}

export function doRealtimeBacklogDesync() {
  return (dispatch, getState) => {
    backlogDesynchronized = true

    // Feature flag - can be eventually removed
    if (!selectShowExperimentalRealtimeWarnings(getState())) return false

    return dispatch(
      showErrorNotification({
        header: 'Connection interrupted',
        id: BACKLOG_DESYNC,
        message: 'Please refresh your browser.',
      })
    )
  }
}
