import graphql, { mapErrorsToFormFields } from 'api/graphql'

export const CREATE_MUTATION = `
  mutation CreateLabel($name: String!, $color: String) {
    createLabel(name: $name, color: $color) {
      id
      name
      color
    }
  }
`

const getData = res => res.json.data
const getResult = key => data => data[key]

export const createLabel = (token, label) =>
  graphql(token, CREATE_MUTATION, label)
    .then(getData)
    .then(getResult('createLabel'))
    .catch(mapErrorsToFormFields)

export const UPDATE_MUTATION = `
  mutation UpdateLabel($id: ID!, $name: String, $color: String) {
    updateLabel(id: $id, name: $name, color: $color) {
      id
      name
      color
      labelingsCount
    }
  }
`

export const updateLabel = (token, label) =>
  graphql(token, UPDATE_MUTATION, label)
    .then(getData)
    .then(getResult('updateLabel'))
    .catch(mapErrorsToFormFields)

export const DELETE_MUTATION = `
  mutation DeleteLabel($id: ID!) {
    deleteLabel(id: $id) {
      id
    }
  }
`

export const deleteLabel = (token, id) =>
  graphql(token, DELETE_MUTATION, { id })
    .then(getData)
    .then(getResult('deleteLabel'))
