export const basicFields = `
  id
  name
  agents {
    id
  }
`

export const groupsQuery = () => `
  query GroupsQuery {
    groups {
      ${basicFields}
    }
  }
`
