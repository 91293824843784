export const basicFields = `
  id
  name
  matchType
  displayCountWhenInactive
  hideIfZeroConversations
  visible
  default
  type
  conditions {
    id
    param
    operator
    value
  }
`

export const query = folderId => `
  query FolderQuery {
    folders(id: "${folderId}") {
      ${basicFields}
    }
  }
`

export const collectionQuery = () => `
  query FoldersQuery {
    folders(byType: "All") {
      ${basicFields}
    }
  }
`
