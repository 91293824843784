import { GraphQlValidationError } from 'util/global-exceptions'
import { isEmpty } from 'util/arrays'
import debug from 'util/debug'

// Maps GQL errors and re-throws them in a form-friendly way
//
// Given this awfully special payload from GQL:
//
// {
//   data: {
//   errors: [{
//     originalError:{
//       name: [
//         "has already been taken"
//       ]
//     },
//     message:"...",
//     status:400
//   }]
// }
//
// Or the more sane 20191028 error payload
//
// {
//   data: {
//   errors: [{
//     message: "...",
//     extensions: {
//       fields: [{
//         name: [ "has already been taken" ]
//       }]
//     },
//     status: 400
//   }],
// }
//
// This fn will return
//
//   { name: ['has already been taken'] }
//
const mapErrorsToFormFields = payload => {
  debug('Mapping GQL errors', { payload })
  if (!payload) throw payload

  const { errors } = payload
  if (!errors || isEmpty(errors)) throw payload

  const first = errors[0]

  // > 201910 error payload. Fields are available inside extensions.
  if (first && first.extensions && first.extensions.fields) {
    const newError = { ...first.extensions.fields, _mapped: true }
    throw new GraphQlValidationError(newError)
  }

  // Legacy error payload. We try to grab fields out of originalError
  if (first && first.originalError) {
    const newError = { ...first.originalError, _mapped: true }
    throw new GraphQlValidationError(newError)
  }

  throw payload
}

export default mapErrorsToFormFields
