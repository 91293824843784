import { redirect } from 'redux-first-router'
import { v4 as uuidv4 } from 'uuid'
import * as pages from 'constants/pages'
import { isEmpty, uniq } from 'util/arrays'

import { selectVisibleFields } from 'selectors/page'

import { REPORTS_MAIN_PAGE } from 'ducks/reports/pages'
import { doSwitchToForward } from 'ducks/drafts2/operations/doSwitchToForward'

export const doOpenMainPage = () => {
  return {
    type: pages.MAIN_PAGE,
  }
}

export const doOpenTicketPage = (
  ticketId,
  { expand, show, viaKeyboard, replying, forwarding, changesetId } = {}
) => (dispatch, getState) => {
  dispatch({
    type: pages.TICKET_PAGE,
    payload: {
      id: ticketId,
      changesetId,
      viaKeyboard,
      replying,
    },
    meta: buildMeta(expand, show),
  })

  if (forwarding) {
    const state = getState()
    const draftId = state.drafts2.byTicketId[ticketId]?.reply
    dispatch(doSwitchToForward(ticketId, 'reply', draftId))
  }
}

export function doOpenTicketChangesetPage(ticketId, changesetId) {
  return {
    type: pages.TICKET_CHANGESET_PAGE,
    payload: {
      id: ticketId,
      changesetId,
      hashId: uuidv4().slice(0, 6),
    },
  }
}

export const doOpenNewConversationPage = ({ to } = {}) => {
  return {
    type: pages.NEW_CONVERSATION_PAGE,
    meta: buildMeta(true, [], to),
  }
}
export const doOpenNewTicketPage = doOpenNewConversationPage
export const doOpenNewEmailPage = doOpenNewConversationPage

export const doOpenLogConversationPage = ({ show = [] } = {}) => {
  return {
    type: pages.LOG_CONVERSATION_PAGE,
    meta: buildMeta(true, show),
  }
}

function buildMeta(expand = undefined, show = [], to) {
  if (!expand && isEmpty(show)) return undefined

  return {
    query: {
      expand,
      show: isEmpty(show) ? undefined : show.join(','),
      to,
    },
  }
}

export const doOpenReplyPage = (
  ticketId,
  { changesetId, expand, show } = {}
) => {
  if (changesetId) {
    return {
      type: pages.TICKET_REPLY_CHANGESET_PAGE,
      payload: {
        id: ticketId,
        changesetId,
      },
      meta: buildMeta(expand, show),
    }
  }

  return {
    type: pages.TICKET_REPLY_PAGE,
    payload: {
      id: ticketId,
    },
    meta: buildMeta(expand, show),
  }
}

export const doOpenReplyDirectPage = (
  ticketId,
  { changesetId, expand, show } = {}
) => {
  if (changesetId) {
    return {
      type: pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE,
      payload: {
        id: ticketId,
        changesetId,
      },
      meta: buildMeta(expand, show),
    }
  }
  return {
    type: pages.TICKET_REPLY_DIRECT_PAGE,
    payload: {
      id: ticketId,
    },
    meta: buildMeta(expand, show),
  }
}

export const doOpenForwardPage = (ticketId, { expand, show } = {}) => {
  return {
    type: pages.TICKET_FORWARD_PAGE,
    payload: {
      id: ticketId,
    },
    meta: buildMeta(expand, show),
  }
}

export const doOpenForwardPageExpanded = (ticketId, args = {}) =>
  doOpenForwardPage(ticketId, { ...args, expand: true })

export const doOpenForwardChangesetPage = (
  ticketId,
  changesetId,
  { expand, show } = {}
) => {
  return {
    type: pages.TICKET_FORWARD_CHANGESET_PAGE,
    payload: {
      id: ticketId,
      changesetId,
    },
    meta: buildMeta(expand, show),
  }
}

// Thunk that grabs the currently visible fields, and concatenates the new one
// to that list. So we can more easily build up a query string like
// `show=cc,bcc,subject`
export const doShowReplyHeaderRow = (
  pageActionCreator, // one of doOpen[Reply/ReplyDirect/Forward]Page
  ticketId,
  showField
) => (dispatch, getState) => {
  const visibleFields = selectVisibleFields(getState())

  return dispatch(
    redirect(
      pageActionCreator(ticketId, {
        expand: true,
        show: uniq(visibleFields.concat(showField)),
      })
    )
  )
}

// Thunk that grabs the currently visible fields, and concatenates the new one
// to that list. So we can more easily build up a query string like
// `show=cc,bcc,subject`
const doShowNewPageHeaderRow = actionFn => showField => (
  dispatch,
  getState
) => {
  const visibleFields = selectVisibleFields(getState())
  return dispatch(
    redirect(
      actionFn({
        show: uniq(visibleFields.concat(showField)),
      })
    )
  )
}

export const doShowNewEmailHeaderRow = doShowNewPageHeaderRow(
  doOpenNewEmailPage
)

export const doShowNewConversationHeaderRow = doShowNewPageHeaderRow(
  doOpenNewConversationPage
)

export const doOpenNotePage = ticketId => {
  return {
    type: pages.TICKET_COMMENT_PAGE,
    payload: {
      id: ticketId,
    },
  }
}

export const doOpenRawEmailPage = commentId => {
  return {
    type: pages.RAW_EMAIL_PAGE,
    payload: {
      id: commentId,
    },
  }
}

export const doOpenReportsMainPage = () => ({ type: REPORTS_MAIN_PAGE })

export const doOpenSettingsMainPage = () => ({ type: pages.SETTINGS_PAGE })
