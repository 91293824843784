import grooveAPI from 'api/groove'
import {
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_COMPLETE,
  SEARCH_USER_FAIL,
} from 'constants/action_types'
import {
  oauthTokenSelector,
  selectAccountPrefersScopedContactsSearch,
} from 'selectors/app'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectMailboxesById } from 'selectors/mailboxes/selectMailboxesById'
import { selectKnownMailboxes } from 'selectors/mailboxes/selectKnownMailboxes'
import { selectDraftByTicketId } from 'ducks/drafts2/selectors'
import { selectPreviousUserSearchResults } from 'selectors/users'
import debug from 'util/debug'
import { batchActions } from 'util/redux'
import { emptyObj } from 'util/objects'

export function doSearchUsers(term) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const ticketId = selectCurrentTicketId(state)
    const { isForwarding, mailboxId } =
      selectDraftByTicketId(state, ticketId, 'reply') || emptyObj

    dispatch({ type: SEARCH_USER_REQUEST })

    const success = {
      type: SEARCH_USER_SUCCESS,
      data: { term, isForwarding },
    }
    const complete = { type: SEARCH_USER_COMPLETE }

    const cacheKey = (isForwarding ? 'forward:' : '') + term
    const previousSearchResults = selectPreviousUserSearchResults(state)[
      cacheKey
    ]
    if (previousSearchResults) {
      success.data.users = previousSearchResults
      dispatch(batchActions([success, complete]))
      return Promise.resolve({ contacts: previousSearchResults })
    }

    const exists = ['email']
    const defaultRoles = ['admin', 'owner', 'agent']
    const role = defaultRoles.concat(isForwarding ? 'collaborator' : 'enduser')

    const scopeByMailbox = selectAccountPrefersScopedContactsSearch(state)
    let mailboxIds = null
    if (scopeByMailbox) {
      const mailboxesById = selectMailboxesById(state)
      const draftMailbox = mailboxesById[mailboxId]
      if (draftMailbox) {
        mailboxIds = [draftMailbox.id]
      } else {
        const known = selectKnownMailboxes(state)
        if (known && known[0] && known[0].id) {
          mailboxIds = [known[0].id]
        }
      }
    }

    return grooveAPI
      .get(token, 'v1/contacts.json', {
        term,
        exists,
        role,
        mailbox_ids: mailboxIds,
      })
      .then(res => {
        success.data.users = res.json.contacts
        dispatch(success)
        return res.json
      })
      .catch(err => {
        debug(err)
        dispatch({ type: SEARCH_USER_FAIL })
        return null
      })
      .then(contacts => {
        dispatch(complete)
        return contacts
      })
  }
}
