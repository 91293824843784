import * as types from 'constants/action_types'

import { doFetchCurrentFolderWithTickets } from 'actions/tickets'
import { doFetchTicketAggregationCountsFor } from 'actions/search/doFetchTicketAggregationCountsFor'

import { selectCurrentPath as currentPathSelector } from 'selectors/location'
import { selectCurrentMailboxFolders } from 'selectors/folders'
import {
  selectOpenTicketsNavSectionLists,
  selectSnoozedTicketsNavSectionLists,
  selectClosedTicketsNavSectionLists,
} from 'selectors/left_nav'
import { selectPinnedSearches } from 'selectors/pinned_searches'
import { buildNavSearch } from 'selectors/mailboxes'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import { selectTicketSearchOperatorValueMap } from 'selectors/search'

import { findFirst } from 'util/arrays'
import { matchFilter } from 'util/folders'
import { doesTicketMatchSearch, constructSearchQueryString } from 'util/search'

export function doSetCurrentFolder() {
  return (dispatch, getState) => {
    const state = getState()
    const path = currentPathSelector(state)
    return dispatch({
      type: types.INIT_FOLDER,
      data: { folderId: path.split('/').pop() },
    })
  }
}

function doMatchOnFolder(folderId) {
  return dispatch => {
    dispatch({ type: types.TICKET_MATCHES_FOLDER, data: { folderId } })
    // At this  point now we will have a currentFolder in redux, so go and
    // fetch it.
    dispatch(doFetchCurrentFolderWithTickets())
    return folderId
  }
}

function doMatchOnSearch(searchObject) {
  return (dispatch, getState) => {
    const state = getState()
    const valueMap = selectTicketSearchOperatorValueMap(state)
    const searchQueryString = constructSearchQueryString(searchObject, valueMap)

    dispatch({ type: types.TICKET_MATCHES_SEARCH, data: { searchQueryString } })

    // our search trigger will pick up this change so no need to dispatch an
    // action here

    return searchQueryString
  }
}

export function doSetCurrentFolderFromTicket() {
  return (dispatch, getState) => {
    const state = getState()
    const ticket = selectCurrentTicket(state)
    const mailboxId = selectCurrentMailboxId(state)

    // We need this since we may do several API calls to find the first
    // search/folder that matches.
    dispatch({
      type: types.TICKET_MATCH_START,
      data: { ticketId: ticket.id, mailboxId },
    })

    // helpers
    const matchSearch = search => doesTicketMatchSearch(ticket, search)

    const matchFolder = folder =>
      matchFilter(folder.conditions, folder.matchType, ticket)

    // Asynchronously fetch the searches for the given Nav section, then once
    // the promise is resolved, check if any of the fetched searches match the
    // ticket. Returns a promise that resolves to a matched search (or null).
    const checkNavSection = (section, selectorFn) => {
      const navSearchObject = buildNavSearch(mailboxId, [section])

      return dispatch(doFetchTicketAggregationCountsFor(navSearchObject)).then(
        () => {
          const searchLists = selectorFn(getState())
          // We have Left Nav 'list' objects in hand. These are arranged by group
          // e.g. Top, Group, Agent, Labels. We need the queryIds from each of
          // these group items, preserving the order.
          const searchQueryStrings = searchLists.reduce(
            (accumulator, group) => {
              group.items.map(groupItem => accumulator.push(groupItem.queryId))
              return accumulator
            },
            []
          )

          return findFirst(searchQueryStrings, matchSearch)
        }
      )
    }

    // Match in the same order as they appear in the Left Nav
    let match

    match = findFirst(selectPinnedSearches(state), matchSearch)
    if (match) return dispatch(doMatchOnSearch(match))

    match = findFirst(selectCurrentMailboxFolders(state), matchFolder)
    if (match) return dispatch(doMatchOnFolder(match.id))

    // Nav Section checks (open/snoozed/closed)
    return checkNavSection('open', selectOpenTicketsNavSectionLists)
      .then(sectionMatch => {
        if (sectionMatch) return sectionMatch
        return checkNavSection('snoozed', selectSnoozedTicketsNavSectionLists)
      })
      .then(sectionMatch => {
        if (sectionMatch) return sectionMatch
        return checkNavSection('closed', selectClosedTicketsNavSectionLists)
      })
      .then(sectionMatch => {
        if (sectionMatch) return sectionMatch
        // Fallback to some Default/catch-all searches. It's entirely possible
        // a customer has no filters, or the ticket does not match any of our
        // 'standard' filters/searches either.
        return findFirst(
          ['is:open', 'is:closed', 'is:pending', 'is:deleted'],
          matchSearch
        )
      })
      .then(sectionMatch => {
        if (sectionMatch) {
          dispatch(doMatchOnSearch(sectionMatch))
          return sectionMatch
        }
        throw new Error(
          `Could not derive ${app.t('folder')} from ticket ${ticket.id}`
        )
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err.stack)
        dispatch({
          type: types.TICKET_MATCH_ERROR,
          data: { err, ticketId: ticket.id, mailboxId },
        })
      })
  }
}
