import config from '../../config'

const getHeaders = token => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
})

const getURL = (id, email) =>
  `${config.graphql_url.replace(/\/graphql$/, '')}/integrations/${id}/${email}`

const get = (id, email, token) => {
  const request = new Request(getURL(id, email), {
    method: 'GET',
    mode: 'cors',
    redirect: 'follow',
    headers: new Headers(getHeaders(token)),
  })
  return fetch(request).then(resp => resp.json())
}
export default get
