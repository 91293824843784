import * as types from 'constants/action_types'
import * as modals from 'constants/modal_types'

export function doShowModal(modalType, modalProps) {
  // Add a history entry so we can listen for window.onpopstate
  history.pushState({ popup: modalType }, 'Assign')

  return {
    type: types.SHOW_MODAL,
    data: {
      modalType,
      modalProps,
    },
  }
}

export function doHideModal(modalType) {
  return {
    type: types.HIDE_MODAL,
    data: {
      modalType,
    },
  }
}

export function doUpdateModalProps(modalProps) {
  return {
    type: types.UPDATE_MODAL_PROPS,
    data: {
      modalProps,
    },
  }
}

export const doSelectModalTab = activeTab => doUpdateModalProps({ activeTab })

export const doShowDropdownModal = ({
  mouseY,
  mouseX,
  DropdownComponent,
  ...rest
}) => {
  return doShowModal(modals.DROPDOWN, {
    DropdownComponent,
    mouseX,
    mouseY,
    ...rest,
  })
}

export const doHideDropdownModal = () => {
  return doHideModal(modals.DROPDOWN)
}

export function doSetTicketAssignmentFilter(term) {
  return {
    type: types.SET_TICKET_ASSIGNMENT_FILTER,
    data: {
      term,
    },
  }
}

export function doSetTicketGroupAssignmentFilter(term) {
  return {
    type: types.SET_TICKET_GROUP_ASSIGNMENT_FILTER,
    data: {
      term,
    },
  }
}

export function doDrillDownOnTeam(groupId) {
  return {
    type: types.GROUP_DRILL_DOWN,
    data: { drilledDownGroupId: groupId },
  }
}

export const doShowCannedReplyModal = modalProps =>
  doShowModal(modals.CANNED_REPLIES, modalProps)

export const doHideCannedReplyModal = () => doHideModal(modals.CANNED_REPLIES)

export const doShowSnoozeModal = props => doShowModal(modals.SNOOZING, props)
export const doHideSnoozeModal = props => doHideModal(modals.SNOOZING, props)

export const doShowTicketPreviewModal = (ticketId, offset) =>
  doShowModal(modals.TICKET_PREVIEW, {
    ticketPreviewTicketId: ticketId,
    offset,
  })

export const doHideTicketPreviewModal = () =>
  doHideModal(modals.TICKET_PREVIEW, { ticketPreviewTicketId: null })

export const doShowChatAvailabilityModal = () =>
  doShowModal(modals.CHAT_AVAILABILITY)
export const doHideChatAvailabilityModal = () =>
  doHideModal(modals.CHAT_AVAILABILITY)

export const doShowChatPresenceOverviewModal = () =>
  doShowModal(modals.CHAT_PRESENCE_OVERVIEW)

export const doHideChatPresenceOverviewModal = () =>
  doHideModal(modals.CHAT_PRESENCE_OVERVIEW)

export const doShowTagsAssignModal = () => doShowModal(modals.LABELING_EDIT)
export const doHideTagsAssignModal = () => doHideModal(modals.LABELING_EDIT)
