import { doOpenTicketPage } from 'actions/pages'
import { selectHasTicketLoadedChangesets } from 'selectors/currentChangesets'
import metrics from 'util/metrics'

export const doNavigateToTicket = ticketId => (dispatch, getState) => {
  const state = getState()

  try {
    const hot = selectHasTicketLoadedChangesets(state, ticketId)
    metrics.startTimer(`ticket-load-${ticketId}`, {
      ticketId,
      metric: 'ticket-load',
      logname: 'main',
      hot,
    })
  } catch (e) {
    // pass
  }

  return dispatch(doOpenTicketPage(ticketId))
}
