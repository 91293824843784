import { BEGIN, COMMIT, REVERT } from 'redux-optimist'

import graphql from 'api/graphql'
import {
  UPDATE_NOTE_REQUEST,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAILURE,
} from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'

function doUpdateNoteRequest(ticketId, noteId, noteText, changeId) {
  return {
    type: UPDATE_NOTE_REQUEST,
    data: { ticketId, noteId, noteText },
    optimist: { type: BEGIN, id: changeId },
  }
}

function doUpdateNoteSuccess(ticketId, noteId, noteText, changeId) {
  return {
    type: UPDATE_NOTE_SUCCESS,
    data: { ticketId, noteId, noteText },
    optimist: { type: COMMIT, id: changeId },
  }
}

function doUpdateNoteFailure(ticketId, noteId, noteText, changeId) {
  return {
    type: UPDATE_NOTE_FAILURE,
    data: { ticketId, noteId, noteText },
    optimist: { type: REVERT, id: changeId },
  }
}

export default function doUpdateNote(ticketId, noteId, noteText) {
  return (dispatch, getState) => {
    const changeId = `noteUpdate:${noteId}:${noteText}`
    dispatch(doUpdateNoteRequest(ticketId, noteId, noteText, changeId))
    const state = getState()
    const token = oauthTokenSelector(state)

    const query = `
      mutation UpdateNote($ticketId: String, $id: String, $body: String) {
        updateNote(ticketId: $ticketId, id: $id, body: $body) {
          success
        }
      }
    `
    const variables = {
      id: noteId,
      ticketId,
      body: noteText,
    }

    return graphql(token, query, variables)
      .then(() => {
        dispatch(doUpdateNoteSuccess(ticketId, noteId, noteText, changeId))
      })
      .catch(() => {
        dispatch(doUpdateNoteFailure(ticketId, noteId, noteText, changeId))
      })
  }
}
