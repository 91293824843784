import { UPDATE_ACTIVE_LABEL_INDEX } from 'constants/action_types'

export const doUpdateActiveLabel = index => {
  return {
    type: UPDATE_ACTIVE_LABEL_INDEX,
    data: {
      activeLabelIndex: index,
    },
  }
}
