import graphql from 'api/graphql'
import * as types from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectTicketSearchOperatorValueMap } from 'selectors/search'
import debug from 'util/debug'
import {
  constructSearchQueryId,
  constructGraphQLSearchQueryObject,
} from 'util/search'
import { selectFoldersById } from 'ducks/folders/selectors/folders'

export function doFetchTicketSearchCountsFor(searches, options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const foldersById = selectFoldersById(state)
    const ticketSearchOperatorValueMap = selectTicketSearchOperatorValueMap(
      state
    )

    dispatch({ type: types.SEARCHES_REQUEST, data: { searches } })

    const starredSearchesForAPI = searches.map(
      constructGraphQLSearchQueryObject
    )
    const starredSearchIds = searches.map(constructSearchQueryId)
    const token = oauthTokenSelector(state)
    const query = `
      query TicketSearchCounts($queries: [SearchQuery]) {
        searches {
          tickets(queries: $queries, bulkCounts: true) {
            metadata {
              totalCount
              timestamp
              mailboxCounts
            }
          }
        }
      }
    `
    const variables = { queries: starredSearchesForAPI }

    return graphql(token, query, variables)
      .then(res => {
        const response = res.json.data.searches
        const counts = response.tickets.reduce((accumulator, result, index) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[starredSearchIds[index]] = {
            count: result.metadata.totalCount || 0,
            timestamp: result.metadata.timestamp,
          }
          return accumulator
        }, {})

        dispatch({
          type: types.SEARCHES_SUCCESS,
          data: {
            response,
            searches: counts,
            metricType: options.metricType,
            trackMetrics: options.trackMetrics,
            isAutomatic: options.isAutomatic,
            isManual: options.isManual,
            foldersById,
            ticketSearchOperatorValueMap,
          },
        })
      })
      .catch(err => {
        debug(err)
        dispatch({ type: types.SEARCHES_FAIL, err })
      })
  }
}
