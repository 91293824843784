// CRUD-related Label (NOT Labeling!) actions
import { v4 as uuidV4 } from 'uuid'
import { BEGIN, REVERT } from 'redux-optimist'

import graphql from 'api/graphql'
import grooveAPI from 'api/groove'
import { createLabel, updateLabel, deleteLabel } from 'api/labels'
import * as types from 'constants/action_types'
import { doMarkFetchingStatus } from 'actions/app/doMarkFetchingStatus'
import { oauthTokenSelector, fetchingStatusesSelector } from 'selectors/app'
import {
  selectTopLabels,
  selectTopLabelsAreLoaded,
  selectLabelIds,
} from 'selectors/labels'
import { selectLabelSearchResultsByTerm } from 'selectors/labelings'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { wait } from 'util/promises'
import { difference } from 'util/arrays'
import { batchActions } from 'util/redux'
import bootstrap from 'util/bootstrap'

// GR: this is a red flag. The Labels module shouldnt really be calling
// into Labelings. However doUpdateLabels was written a long time ago, and as
// such our fetch action is coupled to updating Labelings... :-/
import { doUpdateLabels } from 'actions/labelings/shared/doUpdateLabels'

// we only allow one search globally
let searchTimeoutId
const searchDelay = 500

export function doFetchLabelsByIds(labelIds, options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const fetchStatuses = fetchingStatusesSelector(state)
    let ids = labelIds

    if (fetchStatuses.fetchLabelsById) return null

    // TODO. Refactor. GR: this is likely unnecessary because the function may
    // return early (if no ids.)
    dispatch(doMarkFetchingStatus('fetchLabelsById', true))

    const token = oauthTokenSelector(state)
    if (options.reload === false) {
      const currentLabelIds = selectLabelIds(state)
      ids = difference(ids, currentLabelIds)
    }
    ids = ids.map(x => parseInt(x, 10)).filter(x => !!x)

    if (ids.length === 0) return null

    const query = `
      query LabelsByIDs {
        labels(ids: ${JSON.stringify(ids)}) {
          records {
            id
            name
            color
            labelingsCount
          }
        }
      }
    `

    return graphql(token, query).then(res => {
      const labels = res.json.data.labels
      return dispatch(
        batchActions(
          doMarkFetchingStatus('fetchLabelsById', false),
          doUpdateLabels(labels.records)
        )
      )
    })
  }
}

export function doFetchLabelsByName(names) {
  return (dispatch, getState) => {
    if (!names || names.length === 0) return null
    const state = getState()
    const fetchStatuses = fetchingStatusesSelector(state)
    if (fetchStatuses.fetchLabelsByName) return null

    dispatch({
      type: types.FETCH_LABELS_BY_NAME_REQUEST,
      data: {
        names,
      },
    })

    const token = oauthTokenSelector(state)
    const query = `
      query LabelsByNames {
        labels(names: ${JSON.stringify(names)}) {
          records {
            id
            name
            color
            labelingsCount
          }
        }
      }
    `
    return graphql(token, query)
      .then(res => {
        const { records: labels } = res.json.data.labels

        return dispatch({
          type: types.FETCH_LABELS_BY_NAME_SUCCESS,
          data: {
            names,
            labels,
          },
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_LABELS_BY_NAME_FAIL,
          data: {
            names,
            err,
          },
        })
      })
  }
}

export function doSearchLabels(term, { storeTerm = true } = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const cached = selectLabelSearchResultsByTerm(state)[term]

    if (searchTimeoutId) clearTimeout(searchTimeoutId)
    if (cached) {
      dispatch({
        type: types.SEARCH_LABELS_REQUEST,
        data: {
          term,
          storeTerm,
        },
      })
      return Promise.resolve()
    }

    return new Promise(resolve => {
      searchTimeoutId = setTimeout(
        () => resolve(dispatch(doFetchLabelsByKeyword(term, { storeTerm }))),
        searchDelay
      )
    })
  }
}

export function doFetchLabelsByKeyword(term, { storeTerm = true } = {}) {
  return (dispatch, getState) => {
    const state = getState()

    const searchLabelRequest = {
      type: types.SEARCH_LABELS_REQUEST,
      data: { term, storeTerm },
    }

    if (term === '') return dispatch(searchLabelRequest)

    dispatch(
      batchActions([searchLabelRequest, doMarkFetchingStatus('labels', true)])
    )
    const token = oauthTokenSelector(state)

    return grooveAPI
      .get(token, 'v1/tags/search.json', {
        keyword: term,
      })
      .then(res => {
        const data = res.json
        const actions = []
        const tags = data.tags.map(tag => ({
          ...tag,
          labelingsCount: tag.labelings_count,
        }))

        // TODO We dont need a bunch of separate actions here.
        actions.push(doUpdateLabels(tags))
        actions.push(doUpdateLabelSearchResults(term, tags))
        actions.push(doMarkFetchingStatus('labels', false))

        dispatch(batchActions(actions))
      })
  }
}

export function doWaitForLabelToIndex(label, attempt = 1) {
  return (dispatch, getState) => {
    if (attempt === 1) dispatch(doMarkFetchingStatus('labels', true))
    if (attempt > 10) {
      dispatch(doMarkFetchingStatus('labels', false))
      return Promise.reject('Timeout while waiting for label to index')
    }

    const state = getState()
    const token = oauthTokenSelector(state)

    return grooveAPI
      .get(token, 'v1/tags/search.json', {
        keyword: label.name,
      })
      .then(res => {
        const {
          json: { tags },
        } = res
        if (tags.some(t => parseInt(t.id, 10) === parseInt(label.id, 10))) {
          return Promise.resolve(label)
        }
        return wait(1000).then(() =>
          dispatch(doWaitForLabelToIndex(label, attempt + 1))
        )
      })
  }
}

export function doFetchTopLabels(mailboxId = null) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    dispatch({ type: types.FETCH_TOP_LABELS_REQUEST, data: { mailboxId } })

    let argsStr = `sortBy: "usage"`
    if (mailboxId) argsStr += `, mailboxId: "${mailboxId}"`

    const query = `
      query TopLabelsQuery {
        labels(${argsStr}) {
          records {
            id
            name
            color
            labelingsCount
          }
        }
      }
    `

    return bootstrap.wait().then(() => {
      return graphql(token, query)
        .then(res => res.json.data)
        .then(data =>
          dispatch({
            type: types.FETCH_TOP_LABELS_SUCCESS,
            data: {
              labels: data.labels.records || [],
              mailboxId,
            },
          })
        )
    })
  }
}

// Conditionally fetches top labels if no results cached
export function doFetchTopLabelsForCurrentMailbox() {
  return (dispatch, getState) => {
    const state = getState()
    const mailboxId = selectCurrentMailboxId(state)
    const topLabels = selectTopLabels(state)
    const isLoaded = selectTopLabelsAreLoaded(state)

    if (isLoaded) return Promise.resolve(topLabels)

    return dispatch(doFetchTopLabels(mailboxId))
  }
}

export const doCreateLabel = (
  { name, color = '' },
  { addAsSelected = false } = {}
) => (dispatch, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)
  const transactionID = uuidV4()

  if (!name) return false

  dispatch({
    type: types.CREATE_LABEL_REQUEST,
    data: { name, color, addAsSelected },
    optimist: { type: BEGIN, id: transactionID },
  })

  return createLabel(token, { name, color })
    .then(label => {
      dispatch({
        type: types.CREATE_LABEL_SUCCESS,
        data: { label, name, color, addAsSelected },
        optimist: { type: REVERT, id: transactionID },
      })
      return label
    })
    .catch(err => {
      dispatch({
        type: types.CREATE_LABEL_FAIL,
        data: { err, name, color, addAsSelected },
        optimist: { type: REVERT, id: transactionID },
      })
      throw err
    })
}

export const doCreateLabelAndAddAsSelected = label =>
  doCreateLabel(label, { addAsSelected: true })

export const doUpdateLabel = ({ id, name, color }) => (dispatch, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)
  const transactionID = uuidV4()

  dispatch({
    type: types.UPDATE_LABEL_REQUEST,
    data: { id, name, color },
    optimist: { type: BEGIN, id: transactionID },
  })

  return updateLabel(token, { id, name, color })
    .then(label =>
      dispatch({
        type: types.UPDATE_LABEL_SUCCESS,
        data: { label, id, name, color },
        optimist: { type: REVERT, id: transactionID },
      })
    )
    .catch(err => {
      dispatch({
        type: types.UPDATE_LABEL_FAIL,
        data: { err, id, name, color },
        optimist: { type: REVERT, id: transactionID },
      })

      throw err
    })
}

export const doDeleteLabel = id => (dispatch, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)

  dispatch({
    type: types.DELETE_LABEL_REQUEST,
    data: { id },
  })

  return deleteLabel(token, id)
    .then(label =>
      dispatch({
        type: types.DELETE_LABEL_SUCCESS,
        data: { label, id },
      })
    )
    .catch(err => {
      dispatch({
        type: types.DELETE_LABEL_FAIL,
        data: { err, id },
      })
    })
}

function doUpdateLabelSearchResults(term, labels) {
  return {
    type: types.UPDATE_LABEL_SEARCH_RESULTS,
    data: {
      term,
      labels,
    },
  }
}
