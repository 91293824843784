import graphql from 'api/graphql'
import * as types from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectRemainingAssignmentGroups } from 'selectors/assignments'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'

export const doFetchTopAssignments = groupId => (dispatch, getState) => {
  const mailboxId = selectCurrentMailboxId(getState())
  return dispatch(fetchTopAssignments(mailboxId, groupId))
}

export const doFetchRemainingTopAssignments = () => (dispatch, getState) => {
  const state = getState()
  const remainingAssignmentGroups = selectRemainingAssignmentGroups(state)
  const mailboxId = selectCurrentMailboxId(state)

  return Promise.all(
    remainingAssignmentGroups.map(remainingGroupId =>
      dispatch(fetchTopAssignments(mailboxId, remainingGroupId))
    )
  )
}

function fetchTopAssignments(mailboxId = null, groupId = null) {
  return (dispatch, getState) => {
    dispatch({
      type: types.FETCH_TOP_ASSIGNMENTS_REQUEST,
      data: { mailboxId, groupId },
    })

    const state = getState()
    const token = oauthTokenSelector(state)
    const query = `
      query TopAssignmentsQuery($limit: Int, $mailboxId: String, $groupId: String) {
        topAgentAssignments(limit: $limit, mailboxId: $mailboxId, groupId: $groupId) {
          assignee_id
          count
        }
      }
    `

    const variables = {
      mailboxId,
      groupId,
    }

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: types.FETCH_TOP_ASSIGNMENTS_SUCCESS,
          data: {
            assignments: data.topAgentAssignments || [],
            mailboxId,
            groupId,
          },
        })
      })
      .catch(err => {
        dispatch({
          type: types.FETCH_TOP_ASSIGNMENTS_FAIL,
          data: { err, mailboxId, groupId },
        })
        console.error(err) // eslint-disable-line no-console
      })
  }
}
