import { hash, reverseHashInt } from 'util/scatterSwap'
import { getRawId } from 'util/globalId'

export const applyAutomaticActionsToChangeset = (
  variables,
  automaticActions
) => {
  const newVariables = Object.assign({}, variables)

  automaticActions.forEach(action => {
    const { type, value } = action

    switch (type) {
      case 'status':
        newVariables.state = value
        break
      case 'labels_by_id': {
        const tagsToAdd = []
        const values = (value || '').split(',')

        values.forEach(v => {
          const tagId = reverseHashInt(getRawId(v))
          if (tagId !== null) tagsToAdd.push(tagId)
        })

        newVariables.tagsToAddById = tagsToAdd
        break
      }
      case 'label_remove': {
        const tagId = reverseHashInt(getRawId(value))
        if (tagId !== null) newVariables.tagsToRemoveById = [tagId]

        break
      }
      case 'label_remove_all':
        newVariables.removeAllLabels = true
        break
      case 'assignee_id':
        newVariables.assignee = hash(value)
        newVariables.group = null
        newVariables.removeAssignee = false
        newVariables.removeGroup = true
        break
      case 'assignee_group_id':
        newVariables.group = hash(value)
        newVariables.assignee = null
        newVariables.removeAssignee = true
        newVariables.removeGroup = false
        break
      case 'snooze_until':
        newVariables.snoozeUntil = value
        delete newVariables.removeSnooze
        break
      default:
    }
  })

  return newVariables
}
