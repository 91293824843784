import graphql from 'api/graphql'
import { query } from 'api/agents'
import { doMarkFetchingStatus } from 'actions/app/doMarkFetchingStatus'

import * as types from 'constants/action_types'

import { selectAgents } from 'selectors/agents/base'
import { oauthTokenSelector, fetchingStatusesSelector } from 'selectors/app'

import { isEmpty } from 'util/arrays'
import { batchActions } from 'util/redux'

import doFetchAgents from './doFetchAgents'

export { doFetchAgents }

export function doFetchAgent(id) {
  return (dispatch, getState) => {
    const state = getState()
    const fetchingStatuses = fetchingStatusesSelector(state)
    const fetchingStatusKey = 'fetchAgent'
    if (fetchingStatuses[fetchingStatusKey]) return
    dispatch(doMarkFetchingStatus(fetchingStatusKey, true))
    const token = oauthTokenSelector(state)
    graphql(token, query(id)).then(res => {
      const data = res.json.data
      // Prevent showing not yet accepted agent in the state.agents.byId
      if (data.agent.id && data.agent.hasAcceptedInvitation !== false) {
        dispatch(
          batchActions(
            doUpdateAgent(data.agent),
            doMarkFetchingStatus(fetchingStatusKey, false)
          )
        )
      } else {
        dispatch(doMarkFetchingStatus(fetchingStatusKey, false))
      }
    })
  }
}

export function doUpdateAgent(agent) {
  return {
    type: types.UPDATE_AGENT,
    data: agent,
  }
}

export function doRemoveAgent(id) {
  return {
    type: types.REMOVE_AGENT,
    data: id,
  }
}

export function doUpdateInaccessibleMailboxes(mailboxes) {
  return {
    type: types.UPDATE_INACCESSIBLE_MAILBOXES,
    data: {
      mailboxes,
    },
  }
}

// Conditionally fetches agents, or returns those already in store.
export function doLoadAgents() {
  return (dispatch, getState) => {
    const agents = selectAgents(getState())
    if (!agents || isEmpty(agents)) return dispatch(doFetchAgents())
    return Promise.resolve(agents)
  }
}
