import { redirect } from 'redux-first-router'
import { selectDeviceTypeIsDesktop } from 'selectors/app'
import { selectAutoLoadFirstTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoLoadFirstTicketInList'
import { selectCurrentTicketSearchResultTicketIds } from 'selectors/search'
import {
  selectIsOnSearchPageWithoutFirstTicketLoaded,
  selectCurrentPage,
  selectCurrentPayload,
  selectQueryParams,
} from 'selectors/location'
import debug from 'util/debug'

export const doOpenFirstTicketForLoad = firstTicketId => (
  dispatch,
  getState
) => {
  const state = getState()
  if (!selectAutoLoadFirstTicketInList(state)) return null
  if (!selectDeviceTypeIsDesktop(state)) return null
  const ticketId =
    firstTicketId || selectCurrentTicketSearchResultTicketIds(state)[0]
  if (ticketId && selectIsOnSearchPageWithoutFirstTicketLoaded(state)) {
    const type = selectCurrentPage(state)
    const query = selectQueryParams(state)
    const payload = selectCurrentPayload(state)

    debug('redirect to search with current first ticket loaded')
    return dispatch(
      redirect({
        type,
        payload,
        meta: { query: { ...query, conversationId: ticketId } },
      })
    )
  }
  return null
}
