import graphql from 'api/graphql'
import { createQueue, queueify } from 'util/queue'
import { chunk } from 'util/arrays'
import { oauthTokenSelector } from 'selectors/app'
import { actionPartials, actorPartials } from 'api/tickets'
import * as types from 'constants/action_types'
import GA from 'util/googleAnalytics'
import metrics from 'util/metrics'
import debug from 'util/debug'
import { selectShouldUseSpeedyGroove } from 'ducks/currentUser/selectors/preferences/selectShouldUseSpeedyGroove'
import { selectRequestByType } from 'ducks/requests/selectors'
import { camelize } from 'util/strings'

function runChunk(token, query, ticketIds, callback) {
  return graphql(token, query, { ticketIds }).then(callback)
}
const queueId = createQueue(1)
const queueRunChunk = queueify(queueId, runChunk)

export function doFetchTicketActions(ticketId) {
  return (dispatch, getState) => {
    if (ticketId === 'new') return false
    if (!ticketId) return false

    const requestKey = `${types.FETCH_TICKET_ACTIONS}_${ticketId}`
    const state = getState()
    const request = selectRequestByType(state, requestKey)
    if (request.loading) return false
    const token = oauthTokenSelector(state)

    const query = `
      query TicketActionsQuery($ticketId: String, $inlineNotes: Boolean) {
        actions(ticketId: $ticketId, inlineNotes: $inlineNotes) {
          records {
            id
            created_at
            changeset
            preview
            change_type
            change {
              ${actionPartials}
            }
            actor {
              ${actorPartials}
            }
          }
        }
      }
    `

    const variables = {
      ticketId,
      inlineNotes: true,
    }

    GA.track('app', 'load-actions', 'list')

    dispatch({
      type: types.FETCH_TICKET_ACTIONS_REQUEST,
      data: { ticketId },
      meta: { requestKey: camelize(requestKey) },
    })

    return graphql(token, query, variables)
      .then(res => {
        metrics.enhanceTimer(`ticket-load-${ticketId}`, {
          requestId: res.requestId,
          responseRuntime: res.runtime,
          responseLatency: res.latency,
        })
        const data = res.json.data
        dispatch({
          type: types.FETCH_TICKET_ACTIONS_SUCCESS,
          data: {
            ticketId,
            actions: data.actions,
          },
        })
      })
      .catch(err => {
        debug(err)

        dispatch({
          type: types.FETCH_TICKET_ACTIONS_FAIL,
          data: { ticketId, err },
        })

        // throw err
      })
  }
}

export function doBulkFetchTicketActions(ticketIds, force = false) {
  return (dispatch, getState) => {
    const state = getState()
    const shouldPreload = selectShouldUseSpeedyGroove(state)
    if (!force && !shouldPreload) return
    const token = oauthTokenSelector(state)

    const query = `
      query TicketBulkActionsQuery($ticketIds: [String]) {
        bulkActions(ticketIds: $ticketIds) {
          ticketId
          records {
            id
            created_at
            changeset
            preview
            change_type
            change {
              ${actionPartials}
            }
            actor {
              ${actorPartials}
            }
          }
        }
      }
    `

    const chunks = chunk(ticketIds, 5)

    chunks.forEach(ticketIdsChunk => {
      queueRunChunk(token, query, ticketIdsChunk, response => {
        const datas = response.json.data.bulkActions
        const payloads = datas.map(data => {
          return {
            ticketId: data.ticketId,
            actions: { records: data.records },
          }
        })
        return dispatch({
          type: types.BULK_FETCH_TICKET_ACTIONS_SUCCESS,
          data: payloads,
        })
      })
    })
  }
}
