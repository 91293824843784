import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <title>icons/Google</title>
      <defs>
        <path
          id="a"
          d="M22.733 9.818H11.767v4.637h6.312c-.588 2.945-3.049 4.636-6.312 4.636-3.85 0-6.953-3.164-6.953-7.091s3.102-7.09 6.953-7.09c1.659 0 3.156.6 4.333 1.58L19.523 3c-2.086-1.855-4.76-3-7.756-3C5.242 0 0 5.345 0 12s5.242 12 11.767 12C17.651 24 23 19.636 23 12c0-.71-.107-1.473-.267-2.182z"
        />
        <path
          id="c"
          d="M22.733 9.818H11.767v4.637h6.312c-.588 2.945-3.049 4.636-6.312 4.636-3.85 0-6.953-3.164-6.953-7.091s3.102-7.09 6.953-7.09c1.659 0 3.156.6 4.333 1.58L19.523 3c-2.086-1.855-4.76-3-7.756-3C5.242 0 0 5.345 0 12s5.242 12 11.767 12C17.651 24 23 19.636 23 12c0-.71-.107-1.473-.267-2.182z"
        />
        <path
          id="e"
          d="M22.733 9.818H11.767v4.637h6.312c-.588 2.945-3.049 4.636-6.312 4.636-3.85 0-6.953-3.164-6.953-7.091s3.102-7.09 6.953-7.09c1.659 0 3.156.6 4.333 1.58L19.523 3c-2.086-1.855-4.76-3-7.756-3C5.242 0 0 5.345 0 12s5.242 12 11.767 12C17.651 24 23 19.636 23 12c0-.71-.107-1.473-.267-2.182z"
        />
        <path
          id="g"
          d="M22.733 9.818H11.767v4.637h6.312c-.588 2.945-3.049 4.636-6.312 4.636-3.85 0-6.953-3.164-6.953-7.091s3.102-7.09 6.953-7.09c1.659 0 3.156.6 4.333 1.58L19.523 3c-2.086-1.855-4.76-3-7.756-3C5.242 0 0 5.345 0 12s5.242 12 11.767 12C17.651 24 23 19.636 23 12c0-.71-.107-1.473-.267-2.182z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path fill="#FBBC05" d="M-1.07 19.09V4.91L8.023 12z" mask="url(#b)" />
        </g>
        <g>
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            fill="#EA4335"
            d="M-1.07 4.91L8.023 12l3.744-3.327 12.838-2.128V-1.09H-1.07z"
            mask="url(#d)"
          />
        </g>
        <g>
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path
            fill="#34A853"
            d="M-1.07 19.09L14.977 6.546l4.225.546 5.403-8.182v26.182H-1.07z"
            mask="url(#f)"
          />
        </g>
        <g>
          <mask id="h" fill="#fff">
            <use xlinkHref="#g" />
          </mask>
          <path
            fill="#4285F4"
            d="M24.605 25.09L8.023 12l-2.14-1.636 18.722-5.455z"
            mask="url(#h)"
          />
        </g>
      </g>
    </svg>
  )
})
