import React from 'react'

export default React.forwardRef(({ className, id, ...rest }, forwardedRef) => {
  return (
    <svg
      id={id}
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h20v20H-3z" />
        <g className="stroke" strokeWidth="1.5" strokeLinecap="round">
          <path d="M1 6.98a5.966 5.966 0 0 0 1.79 4.267A5.956 5.956 0 0 0 6.98 12.96 5.98 5.98 0 1 0 1 6.981z" />
          <path d="M6.977 3.226v3.726l3.084-1.324" />
        </g>
      </g>
    </svg>
  )
})
