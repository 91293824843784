import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        strokeWidth="1"
        fill="currentColor"
        d="M7.257 3.298C7.5 3.232 7.75 3.2 8 3.202c1.766 0 3.198 1.432 3.198 3.197v1.07c0 .44.358.8.8.8.44 0 .8-.36.8-.8V6.4c-.003-2.34-1.693-4.336-4-4.726v-.87c0-.443-.357-.8-.8-.8-.44 0-.798.357-.798.8v.878l-.36.064c-.428.115-.683.556-.568.984.115.428.555.682.984.568zm8.507 11.33L1.37.234C1.06-.078.55-.078.237.235c-.313.314-.313.822 0 1.136L3.53 4.66c-.216.556-.327 1.146-.327 1.742v2.543c-.956.338-1.597 1.24-1.6 2.255v1.6c0 .44.358.8.8.8h2.51c.38 1.39 1.644 2.358 3.087 2.358 1.443 0 2.707-.967 3.086-2.36h1.383l2.157 2.167c.15.152.355.237.568.237.213 0 .417-.085.567-.237.152-.15.237-.354.237-.567 0-.213-.086-.418-.238-.568h.002zM4.8 6.397c-.01-.145-.01-.292 0-.44L7.67 8.8H4.8V6.4zM8 14.396c-.57-.003-1.09-.308-1.375-.8h2.75c-.283.492-.807.797-1.375.8zM3.204 12v-.8c0-.443.358-.8.8-.8h5.27L10.87 12H3.204z"
      />
    </svg>
  )
})
