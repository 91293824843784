import failAction from './failAction'
import requestAction from './requestAction'
import successAction from './successAction'
import loader from './loader'

export default {
  createFetchGroupsRequestAction: requestAction,
  createFetchGroupsSuccessAction: successAction,
  createFetchGroupsFailAction: failAction,
  createFetchGroupsLoader: loader,
}
