import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h20v20H-3z" />
        <path
          d="M1 6.98a5.966 5.966 0 0 0 1.79 4.267A5.956 5.956 0 0 0 6.98 12.96 5.98 5.98 0 1 0 1 6.981z"
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5.976 5.338a.89.89 0 1 1-1.78 0 .89.89 0 0 1 1.78 0M9.765 5.338a.89.89 0 1 1-1.78 0 .89.89 0 0 1 1.78 0"
          className="fill"
        />
        <path
          d="M8.979 8.686a2.826 2.826 0 0 1-3.997 0"
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
})
