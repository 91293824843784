import * as types from 'constants/action_types'

export const doStartEditLabel = label => dispatch => {
  return dispatch({
    type: types.BEGIN_EDIT_LABEL,
    data: { label },
  })
}

export const doCancelCreateLabel = () => dispatch => {
  return dispatch({
    type: types.CANCEL_EDIT_LABEL,
  })
}

export const doStartDeleteLabel = label => dispatch => {
  return dispatch({
    type: types.BEGIN_DELETE_LABEL,
    data: { label },
  })
}

export const doCancelDeleteLabel = () => dispatch => {
  return dispatch({ type: types.CANCEL_DELETE_LABEL })
}

export const doBeginManageLabels = () => dispatch => {
  return dispatch({ type: types.MANAGE_LABELS_BEGIN })
}

export const doCancelManageLabels = () => dispatch => {
  return dispatch({ type: types.MANAGE_LABELS_END })
}
