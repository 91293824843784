export { default as angleDown } from './angleDown'
export { default as alertCircle } from './alert-circle.svg'
export { default as angleLeftB } from './angleLeftB'
export { default as angleRight } from './angleRight'
export { default as angleRightB } from './angleRightB'
export { default as announcements } from './announcements.svg'
export { default as arrowCircleLeft } from './arrowCircleLeft'
export { default as arrowLeft } from './arrow-left'
export { default as arrowRight } from './arrow-right.svg'
export { default as at } from './at.svg'
export { default as audio } from './audio'
export { default as award } from './award.svg'
export { default as bill } from './bill.svg'
export { default as billing } from './billing.svg'
export { default as blog } from './blog.svg'
export { default as bookOpen } from './book-open.svg'
export { default as book } from './book.svg'
export { default as books } from './books.svg'
export { default as box } from './box.svg'
export { default as building } from './building.svg'
export { default as buildingV2 } from './building-v2.svg'
export { default as business } from './business.svg'
export { default as calendar } from './calendar-alt.svg'
export { default as calendarV2 } from './calendar-v2.svg'
export { default as channel } from './channel.svg'
export { default as chatBubbleUser } from './chatBubbleUser'
export { default as chatsNew } from './chats.svg'
export { default as chatsV2 } from './chatsV2.svg'
export { default as checkCircleFill } from './check-circle-fill.svg'
export { default as check } from './check'
export { default as clock } from './clock-three.svg'
export { default as codeBranch } from './codeBranch'
export { default as coins } from './coins.svg'
export { default as conversationStatus } from './conversation-status.svg'
export { default as conversationType } from './conversation-type.svg'
export { default as conversations } from './conversations.svg'
export { default as copy } from './copy.svg'
export { default as dropdown } from './corner-right-down.svg'
export { default as delete } from './delete'
export { default as dollar } from './dollar-alt.svg'
export { default as downloadAlt } from './downloadAlt'
export { default as draggableDots } from './draggabledots.svg'
export { default as edit } from './edit-alt.svg'
export { default as editV2 } from './edit-v2.svg'
export { default as emailNew } from './email.svg'
export { default as emailV2 } from './emailV2.svg'
export { default as emailClosed } from './email-closed.svg'
export { default as emailClosedV2 } from './email-closed-v2.svg'
export { default as mail } from './mail.svg'
export { default as envelopeAlt } from './envelopeAlt'
export { default as externalLink } from './external-link-alt.svg'
export { default as eye } from './eye.svg'
export { default as eyeSlash } from './eye-slash.svg'
export { default as ellipsisVertical } from './ellipsis-v.svg'
export { default as facebook } from './facebook.svg'
export { default as feature } from './feature.svg'
export { default as file } from './file-alt.svg'
export { default as fileEdit } from './file-edit-alt.svg'
export { default as filter } from './filter.svg'
export { default as focusAdd } from './focus-add.svg'
export { default as folders } from './folders.svg'
export { default as gift } from './gift.svg'
export { default as globe } from './globe.svg'
export { default as gmail } from './gmail.svg'
export { default as googleButton } from './google-button.svg'
export { default as googleGroups } from './google-groups.svg'
export { default as googleLogoAlt } from './googleLogoAlt.svg'
export { default as graduation } from './graduation-cap.svg'
export { default as happiness } from './happiness.svg'
export { default as hashtag } from './hashtag.svg'
export { default as help } from './help.svg'
export { default as history } from './history'
export { default as hourglass } from './hourglass.svg'
export { default as institution } from './university.svg'
export { default as image } from './image.svg'
export { default as inbox } from './inbox.svg'
export { default as instantReplies } from './instant-replies.svg'
export { default as interactionCount } from './interaction-count.svg'
export { default as inviteTeammate } from './invite-teammate.svg'
export { default as knowledge } from './knowledge-base.svg'
export { default as label } from './label'
export { default as language } from './language.svg'
export { default as lineChart } from './chart-line.svg'
export { default as layoutNew } from './layout.svg'
export { default as link } from './link-h'
export { default as linkedin } from './linkedin.svg'
export { default as location } from './location-point.svg'
export { default as logout } from './logout.svg'
export { default as message } from './message.svg'
export { default as mobile } from './mobile-android.svg'
export { default as network } from './network.svg'
export { default as notebook } from './diary.svg'
export { default as notify } from './notify.svg'
export { default as officeLogo } from './officeLogo.svg'
export { default as optionsHorizontal } from './optionsHorizontal'
export { default as outlookLogo } from './outlookLogo.svg'
export { default as page } from './file.svg'
export { default as paperclip } from './paperclip.svg'
export { default as people } from './users-alt.svg'
export { default as person } from './user.svg'
export { default as pieChart } from './chart-pie-alt.svg'
export { default as pin } from './pin-filled.svg'
export { default as phone } from './phone.svg'
export { default as print } from './print'
export { default as productivity } from './productivity.svg'
export { default as profile } from './profile.svg'
export { default as receipt } from './bill.svg'
export { default as recipient } from './recipient.svg'
export { default as redo } from './redo'
export { default as sortDown } from './sort-amount-down.svg'
export { default as sortUp } from './sort-amount-up.svg'
export { default as sorting } from './sorting'
export { default as slidersVerticalAlt } from './sliders-v-alt.svg'
export { default as spam } from './spam'
export { default as snooze } from './snooze'
export { default as snoozeV2 } from './snoozeV2.svg'
export { default as star } from './star'
export { default as stars } from './stars.svg'
export { default as rules } from './rules.svg'
export { default as search } from './search.svg'
export { default as send } from './send.svg'
export { default as settings } from './settings.svg'
export { default as shortcutsNew } from './shortcuts.svg'
export { default as shuffle } from './shuffle.svg'
export { default as social } from './social.svg'
export { default as starSelected } from './starSelected'
export { default as starsCircle } from './stars-circle.svg'
export { default as status } from './status.svg'
export { default as stopwatch } from './stopwatch.svg'
export { default as tag } from './tag'
export { default as tagV2 } from './tagV2.svg'
export { default as teammates } from './teammates.svg'
export { default as threeStars } from './three-stars.svg'
export { default as timer } from './timer.svg'
export { default as times } from './times.svg'
export { default as tips } from './tips.svg'
export { default as trash } from './trash-alt.svg'
export { default as usersAlt } from './users-alt.svg'
export { default as warningTriangle } from './warning-triangle.svg'
export { default as warning } from './warning.svg'
export { default as calendarAlt } from './calendar-alt'
export { default as twitter } from './twitter.svg'
export { default as userCheck } from './user-check.svg'
export { default as userPlus } from './user-plus.svg'
export { default as window } from './window.svg'
export { default as windows } from './browser.svg'
export { default as messagesBubble } from './messages-bubble.svg'
export { default as minus } from './minus'
export { default as microsoftLogo } from './microsoftLogo.svg'
export { default as robot } from './robot.svg'
export { default as sync } from './sync'
export { default as pencil } from './pencil.svg'
export { default as write } from './write.svg'
export { default as xCircleFill } from './x-circle-fill.svg'
export { default as bellRing } from './bell-ring.svg'
export { default as bell } from './bell.svg'
export { default as santa } from './santa.svg'
export { default as designTool } from './design-tool.svg'
export { default as discoBall } from './disco-ball.svg'
export { default as expand } from './expand.svg'
export { default as shortenHorizontal } from './shorten-horizontal.svg'
export { default as toolBox } from './tool-box.svg'
export { default as wrench } from './wrench.svg'
