import Bugsnag from '@bugsnag/js'
import { doShowSnackbar } from 'actions/snackbar'
import appGraphql from 'api/app_graphql'
import * as types from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import debug, { logError } from 'util/debug'
import { EDITOR_MAX_UPLOAD_SIZE, toPlainObject } from 'util/file'
import { selectDraftById } from 'ducks/drafts2/selectors'
import storage from 'util/storage'
import {
  selectTicketDraftAttachments,
  selectUploadingAttachmentByTicketIdAndFileName,
} from 'selectors/attachments'
import { handleDraftChange } from 'ducks/drafts2/operations'
import { humanFileSize } from 'util/strings'
import { emptyFunc } from 'util/functions'

const MAX_ATTACHMENTS = 25

function buildBugsnagError(title, error) {
  return new Error(title, {
    severity: 'error',
    metaData: {
      meta: { error },
    },
  })
}

function notify(msg, err) {
  debug(msg, err)
  Bugsnag.notify(buildBugsnagError(msg, err))
}

export function doUploadFile(
  getState,
  dispatch,
  file,
  uploadData,
  forNote,
  draftId,
  options = {}
) {
  const { skipAttachmentValidation = false } = options
  const ticketId = selectCurrentTicketId(getState())
  const plainFileObject = toPlainObject(file)
  const { key, previewUrl, request } = uploadData
  const xhr = new XMLHttpRequest()
  let isDeletedFromAttachments = false

  dispatch({
    type: types.ATTACHMENT_UPLOAD_STARTED,
    data: {
      ticketId,
      file: plainFileObject,
      key,
      forNote,
      expiringUrl: previewUrl,
      draftId,
      uploadRequest: xhr,
    },
  })

  return new Promise((resolve, reject) => {
    const formData = new FormData()

    request.data.forEach(({ name, value }) => {
      if (name === 'file') {
        formData.append(name, file)
      } else {
        formData.append(name, value)
      }
    })

    xhr.upload.onprogress = e => {
      const { total, loaded } = e
      const percentage = Math.round(loaded / total * 100)

      if (!skipAttachmentValidation) {
        const currentAttachment = selectUploadingAttachmentByTicketIdAndFileName(
          getState(),
          ticketId,
          plainFileObject.name
        )
        // The attachments for this ticket have been removed, so abort the upload
        // This happens when the user deleting the draft while the upload is in progress
        // src/reducers/attachments.js: deleteForTicket
        if (!currentAttachment && xhr.abort) {
          isDeletedFromAttachments = true
          xhr.abort()
          return
        }
      }

      dispatch({
        type: types.ATTACHMENT_UPLOAD_PROGRESSED,
        data: {
          draftId,
          file: plainFileObject,
          percentage,
          ticketId,
          forNote,
          expiringUrl: previewUrl,
        },
      })
    }
    xhr.onload = e => {
      /* eslint-disable eqeqeq */
      const { status } = e.target
      if (status === 200 || status === 204) {
        const currentAttachment = selectUploadingAttachmentByTicketIdAndFileName(
          getState(),
          ticketId,
          plainFileObject.name
        )
        if (skipAttachmentValidation || currentAttachment) {
          /* eslint-enable eqeqeq */
          const payload = {
            file: plainFileObject,
            url: request.url,
            ticketId,
            key,
            forNote,
            expiringUrl: previewUrl,
            draftId,
          }
          resolve(payload)
          dispatch({
            type: types.ATTACHMENT_UPLOAD_FINISHED,
            data: payload,
          })
        } else {
          // Should reject here because the draft is deleted
          reject({
            status: xhr.status,
            error: new Error(
              'Attachment upload finished but the draft is deleted'
            ),
            shouldHideSnackbar: true,
          })
        }
      } else {
        notify('Attachment upload: API error', e)
      }
    }
    xhr.onerror = err => {
      notify('Attachment upload: network error', err)

      reject({ status: xhr.status, error: err })
      dispatch({
        type: types.ATTACHMENT_UPLOAD_FAILED,
        data: {
          draftId,
          ticketId,
          file: plainFileObject,
          key,
          forNote,
          expiringUrl: previewUrl,
          error: err,
          status: xhr.status,
        },
      })
    }

    xhr.onabort = err => {
      reject({
        status: xhr.status,
        error: err,
        errorMessage: 'Attachment upload was cancelled',
        shouldHideSnackbar: isDeletedFromAttachments,
      })
      if (isDeletedFromAttachments) {
        return
      }
      dispatch({
        type: types.ATTACHMENT_UPLOAD_FAILED,
        data: {
          draftId,
          ticketId,
          file: plainFileObject,
          key,
          forNote,
          expiringUrl: previewUrl,
          error: err,
          status: xhr.status,
        },
      })
    }

    xhr.open(request.method, request.url)
    xhr.send(formData)
  })
}

export function getUploadPayload(state, fileName) {
  return new Promise(resolve => {
    const token = oauthTokenSelector(state)
    const query = `
      mutation UploadPayloadGenerate($fileName: String) {
        uploadPayloadGenerate(input: {fileName: $fileName}) {
          key
          previewUrl
          request {
            url
            method
            contentType
            data {
              name
              value
            }
          }
        }
      }
    `
    const variables = { fileName }
    appGraphql(token, query, variables).then(res => {
      resolve(res.json.data.uploadPayloadGenerate)
    })
  })
}

export function doUploadFileError(file, forNote, draftId, error) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketId = selectCurrentTicketId(state)
    const draftAttachments = selectTicketDraftAttachments(state) || []
    const isInStore = draftAttachments.some(a => a?.file_name === file?.name)

    if (!isInStore) {
      // add to store so it shows in editor
      dispatch({
        type: types.ATTACHMENT_UPLOAD_STARTED,
        data: {
          ticketId,
          file,
          key: null,
          forNote,
          expiringUrl: null,
          draftId,
          uploadRequest: null,
        },
      })
    }

    dispatch({
      type: types.ATTACHMENT_UPLOAD_FAILED,
      data: {
        draftId,
        ticketId,
        file,
        key: null,
        forNote,
        expiringUrl: null,
        error,
      },
    })
  }
}

export function doUploadFiles(fileList, forNote, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const draft = selectDraftById(state, draftId)

    if (
      draft?.attachments &&
      Object.keys(draft.attachments).length + fileList.length > MAX_ATTACHMENTS
    ) {
      dispatch(
        doShowSnackbar(
          `Oops. You can only add up to ${MAX_ATTACHMENTS} attachments to a single message!`
        )
      )
      return Promise.resolve()
    }

    // We need a presigned URL for each file.
    const promises = []

    for (let i = 0; i < fileList.length; i += 1) {
      const file = toPlainObject(fileList[i])
      const { size } = file

      if (size && size > EDITOR_MAX_UPLOAD_SIZE) {
        const error = {
          type: 'size_exceeds_limit',
          message: `Attachment exceeds ${humanFileSize(
            EDITOR_MAX_UPLOAD_SIZE
          )} size limit`,
        }

        dispatch(doUploadFileError(file, forNote, draftId, error))
      } else {
        promises.push(
          getUploadPayload(state, file.name).then(uploadData => {
            return doUploadFile(
              getState,
              dispatch,
              fileList[i],
              uploadData,
              forNote,
              draftId
            )
          })
        )
      }
    }

    return Promise.all(promises).catch(err => {
      logError(err)
      if (!err.shouldHideSnackbar) {
        dispatch(
          doShowSnackbar(
            err?.errorMessage ||
              'Oops. There was a problem while uploading your attachments'
          )
        )
      }
    })
  }
}

export function doDeleteFile(file, forNote, draftId) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketId = selectCurrentTicketId(state)

    dispatch({
      type: types.DELETE_ATTACHMENT,
      data: {
        ticketId,
        file,
        forNote,
        draftId,
      },
    })
  }
}

export function doUploadHistory() {
  return (_, getState) => {
    const state = getState()
    const date = new Date().toJSON()
    const payload = {
      actions: window.actionHistory.entries,
      state,
      storage: storage.dump(),
    }
    const contents = JSON.stringify(payload)
    const currentUser = selectCurrentUser(state)
    const filename = `errordumps/${currentUser.id}-${date}.json`
    const file = new File([contents], filename, { type: 'application/json' })

    return getUploadPayload(state, filename).then(uploadData => {
      // emptyFunc so that doUploadFile doesn't try to dispatch
      return doUploadFile(getState, emptyFunc, file, uploadData, false, null, {
        skipAttachmentValidation: true,
      })
    })
  }
}

export const doHandleUploadComplete = (
  ticketId,
  draftId,
  draftType = 'reply'
) => (dispatch, getState) => {
  const draft = selectDraftById(getState(), draftId)
  if (!draft) {
    return
  }
  handleDraftChange(dispatch, draftType, draftId, ticketId, null, {
    touched: true,
  })
}

window.doUploadHistory = doUploadHistory
