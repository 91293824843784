import accountIntegrations from 'api/accountIntegrations'

import {
  SIDEBARCARD_FETCH_DATA_REQUEST,
  SIDEBARCARD_FETCH_DATA_FAILURE,
  SIDEBARCARD_FETCH_DATA_SUCCESS,
  SIDEBARCARD_CHANGE_OPEN_STATE,
} from 'constants/action_types'

import { oauthTokenSelector } from 'selectors/app'
import { selectCurrentContactOrCustomerEmail } from 'ducks/crm/contacts'
import { downcase } from 'util/strings'

export function doSidebarCardRequestStart(id, item = {}, meta) {
  return {
    type: SIDEBARCARD_FETCH_DATA_REQUEST,
    meta,
    item: { id, ...item },
  }
}

export function doSidebarCardRequestSuccess(id, item = {}, meta) {
  return {
    type: SIDEBARCARD_FETCH_DATA_SUCCESS,
    meta,
    item: { id, ...item },
  }
}

export function doSidebarCardRequestFailure(id, item = {}, meta) {
  return {
    type: SIDEBARCARD_FETCH_DATA_FAILURE,
    meta,
    item: { id, ...item },
  }
}

export const doFetchAccountIntegrationData = (provider, cardId) => (
  dispatch,
  getState
) => {
  const state = getState()
  const email = selectCurrentContactOrCustomerEmail(state)

  dispatch(doSidebarCardRequestStart(cardId, { provider }))

  return accountIntegrations(cardId, downcase(email), oauthTokenSelector(state))
    .then(data =>
      dispatch(doSidebarCardRequestSuccess(cardId, { data, provider }))
    )
    .catch(error =>
      dispatch(doSidebarCardRequestFailure(cardId, { error, provider }))
    )
}

export const doChangeOpenState = (id, isOpen) => dispatch =>
  dispatch({
    type: SIDEBARCARD_CHANGE_OPEN_STATE,
    item: { id, isOpen },
  })
