import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <title>icons/Add_folder-20</title>
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h20v20H-3z" />
        <g stroke="#7B8C9E" strokeLinecap="round" strokeWidth="1.5">
          <path d="M7.012 5.676v3.902M8.951 7.639H5.05M1.044 10.382v1.931c0 .367.3.667.667.667h7.086M6.89 1.003H3.393c-1.298 0-2.35 1.067-2.35 2.384v6.995" />
          <path d="M8.753 12.98h1.854c1.297 0 2.349-1.068 2.349-2.384V2.869M7 1.104l.8 1.738 5.156.027" />
        </g>
      </g>
    </svg>
  )
})
