import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
      ref={forwardedRef}
    >
      <defs>
        <path
          d="M17.4452644,12.4680111 L12.408837,17.5091947 C11.0381599,18.7265054 8.95557481,18.665241 7.65892596,17.3694644 C6.3622771,16.0736878 6.30097151,13.9925035 7.51910163,12.6227485 L14.0387488,6.10748699 C14.8471107,5.34013073 16.1153307,5.34013073 16.9236926,6.10748699 C17.7128534,6.90669397 17.7128534,8.19128323 16.9236926,8.99049021 L11.300497,14.6017592 C11.149096,14.7646967 10.9222111,14.8345401 10.7053076,14.7849799 C10.4884041,14.7354198 10.3144348,14.5739856 10.2489323,14.3614879 C10.1834298,14.1489903 10.2363454,13.9177127 10.3877464,13.7547752 L14.5684701,9.58500783 C14.8880324,9.26566051 14.8880324,8.74789623 14.5684701,8.42854892 C14.2489078,8.10920161 13.730795,8.1092016 13.4112327,8.42854891 L9.230509,12.6146044 C8.80797862,13.0335759 8.57032611,13.6037922 8.57032611,14.1986274 C8.57032611,14.7934626 8.80797862,15.3636789 9.230509,15.7826504 C10.1181176,16.627566 11.5130789,16.627566 12.4006874,15.7826504 L18.0157335,10.1632373 C19.3621655,8.71924479 19.3224365,6.4690365 17.925869,5.07340846 C16.5293015,3.67778043 14.2775785,3.63807813 12.8326141,4.98360437 L6.31296691,11.4988659 C4.48645719,13.5204728 4.57611714,16.6207129 6.51641165,18.5335291 C8.45670616,20.4463454 11.5599651,20.4938063 13.5579248,18.6412214 L18.6025018,13.6081818 C18.8092223,13.4016003 18.8899559,13.1005012 18.8142909,12.8183057 C18.738626,12.5361101 18.5180578,12.3156903 18.2356722,12.2400762 C17.9532867,12.1644621 17.651985,12.2451414 17.4452644,12.4517229 L17.4452644,12.4680111 Z"
          id="attach-path-1"
        />
      </defs>
      <g id="new-icons/attach-24" fill="none" fillRule="evenodd">
        <g id="Menu-color/Light-grey">
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <mask id="attach-mask-2" fill="#fff">
            <use xlinkHref="#attach-path-1" />
          </mask>
          <use
            className="fill"
            id="Mask"
            fill="#000"
            fillRule="nonzero"
            xlinkHref="#attach-path-1"
          />
          <g
            className="fill"
            id="Colors/@7E8F9F"
            mask="url(#attach-mask-2)"
            fill="var(--color-monochrome-mediumDark)"
          >
            <rect id="Rectangle" width="24" height="24" />
          </g>
        </g>
      </g>
    </svg>
  )
})
