import graphql from 'api/graphql'
import { actionPartials, actorPartials } from 'api/tickets'
import metrics from 'util/metrics'
import {
  FETCH_CHANGESET_TICKET_ACTIONS_REQUEST,
  FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS,
  FETCH_CHANGESET_TICKET_ACTIONS_FAIL,
} from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectIsChangesetFetched } from 'selectors/currentChangesets'
import debug from 'util/debug'
import GA from 'util/googleAnalytics'

export function doFetchChangesetTicketActions(
  ticketId,
  sourceChangesetId,
  changesetIds
) {
  return (dispatch, getState) => {
    if (ticketId === 'new') return false
    if (!ticketId) return false

    const isFetched = selectIsChangesetFetched(getState(), sourceChangesetId)
    if (isFetched) return false

    dispatch({
      type: FETCH_CHANGESET_TICKET_ACTIONS_REQUEST,
      data: { ticketId, sourceChangesetId },
    })

    const state = getState()
    const token = oauthTokenSelector(state)

    const query = `
      query TicketActionsQuery($ticketId: String, $changesetIds: [String]) {
        actions(ticketId: $ticketId, changesetIds: $changesetIds) {
          records {
            id
            created_at
            changeset
            preview
            change_type
            change {
              ${actionPartials}
            }
            actor {
              ${actorPartials}
            }
          }
        }
      }
    `

    const variables = {
      ticketId,
      changesetIds,
    }

    GA.track('app', 'load-actions', 'changeset')

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        metrics.enhanceTimer(`changeset-load-${sourceChangesetId}`, {
          responseRuntime: res.runtime,
          responseLatency: res.latency,
        })
        dispatch({
          type: FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS,
          data: { ticketId, sourceChangesetId, actions: data.actions.records },
        })
      })
      .catch(err => {
        debug(err)

        dispatch({
          type: FETCH_CHANGESET_TICKET_ACTIONS_FAIL,
          data: { ticketId, sourceChangesetId, err },
        })
      })
  }
}
