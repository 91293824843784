import {
  selectMissingCurrentSearchLabelNames,
  selectMissingPinnedSearchLabelIds,
} from 'selectors/search/labels'

import { doFetchLabelsByIds, doFetchLabelsByName } from './api'

export default function doFetchMissingLabelsForCurrentAndPinnedSearch() {
  return (dispatch, getState) => {
    const state = getState()
    const missingPinnedSearchLabelIds = selectMissingPinnedSearchLabelIds(state)
    const missingCurrentSearchLabelNames = selectMissingCurrentSearchLabelNames(
      state
    )
    const promises = []

    if (missingPinnedSearchLabelIds) {
      promises.push(dispatch(doFetchLabelsByIds(missingPinnedSearchLabelIds)))
    }
    if (missingCurrentSearchLabelNames) {
      promises.push(
        dispatch(doFetchLabelsByName(missingCurrentSearchLabelNames))
      )
    }
    return Promise.all(promises)
  }
}
