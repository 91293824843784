import graphql from 'api/graphql'
import {
  FETCH_TICKET_LINKED_RESOURCES,
  FETCH_TICKET_LINKED_RESOURCES_FAIL,
  FETCH_TICKET_LINKED_RESOURCES_REQUEST,
  FETCH_TICKET_LINKED_RESOURCES_SUCCESS,
} from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import debug from 'util/debug'
import { selectRequestByType } from 'ducks/requests/selectors'
import { camelize } from 'util/strings'

export function doFetchTicketLinkedResources(ticketId) {
  return (dispatch, getState) => {
    if (ticketId === 'new') return false
    if (!ticketId) return false

    const requestKey = `${FETCH_TICKET_LINKED_RESOURCES}_${ticketId}`
    const state = getState()
    const request = selectRequestByType(state, requestKey)
    if (request.loading) return false
    const token = oauthTokenSelector(state)

    const query = `
      query TicketLinkedExternalResourcesQuery($id: Int) {
        ticket(id: $id) {
          linkedExternalResources {
            externalId
            linkedAt
            provider
            title
            url
          }
        }
      }
    `

    const variables = {
      id: ticketId,
    }

    dispatch({
      type: FETCH_TICKET_LINKED_RESOURCES_REQUEST,
      meta: { ticketId },
      requestKey: camelize(requestKey),
    })

    return graphql(token, query, variables)
      .then(res => {
        const data = res.json.data
        dispatch({
          type: FETCH_TICKET_LINKED_RESOURCES_SUCCESS,
          meta: { ticketId },
          payload: data.ticket.linkedExternalResources,
        })
      })
      .catch(err => {
        debug(err)
        dispatch({
          type: FETCH_TICKET_LINKED_RESOURCES_FAIL,
          error: true,
          meta: { ticketId },
          payload: err,
        })
      })
  }
}
