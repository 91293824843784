import Bugsnag from '@bugsnag/js'
import { v4 as uuidV4 } from 'uuid'

import { restorePayload, bulkRestorePayload } from 'optimistic/restore'

import { doShowSnackbar } from 'actions/snackbar'

import { statusChangePayload } from 'optimistic/status'
import { bulkDeletePayload } from 'optimistic/delete'

import { selectCurrentFolderId } from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectRawTicket } from 'selectors/tickets/byId/selectRawTicket'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectTicketById } from 'selectors/tickets/byId/selectTicketById'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'

import debug from 'util/debug'
import { isEmpty as isEmptyObj } from 'util/objects'
import { batchActions } from 'util/redux'
import {
  isClosed,
  isSpam,
  invertTicketState,
  inverseStateName,
} from 'util/ticketState'

import { doOpenDrawer } from 'ducks/drawers/actions'
import { DRAWER_TICKET_MERGE } from 'ducks/drawers/types'

import { doSendBulkChangeset } from '../changeset'
import { doSendChangeset } from '../changeset/doSendChangeset'
import { doUnsnoozeAndKeepClosed } from '../snooze'
import { doDeleteTicket } from './doDeleteTicket'
import { doHardDeleteTickets } from './doHardDeleteTickets'

function logError(msg, meta) {
  debug(msg, meta)
  Bugsnag.notify(new Error(msg), event => {
    event.addMetadata('metaData', {
      meta,
    })
  })
  return true
}

export const doChangeTicketState = (ticketId, newState, options = {}) => (
  dispatch,
  getState
) => {
  const optimisticData = statusChangePayload(
    getState(),
    ticketId,
    newState,
    selectCurrentFolderId(getState()),
    selectLatestTicketSearchQueryObject(getState()),
    {
      userInitiated: options.userInitiated,
      changesetId: uuidV4(),
    }
  )

  return dispatch(
    doSendChangeset(
      ticketId,
      { state: newState, requiredState: options.requiredState },
      {
        ...options,
        optimisticData,
      }
    )
  ).catch(err => {
    const verb = newState === 'opened' ? 'open' : 'close'
    logError(`Ticket ${verb} failed`, { ticketId, err, newState })
    dispatch(doShowSnackbar(`Oops, we couldn't ${verb} your conversation.`))
  })
}

export function doMarkAsRead(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const ticket = selectRawTicket(state, ticketId)
    if (ticket && ticket.state !== 'unread') {
      // NOTE (jscheel) If we _know_ that the ticket is not unread, skip this.
      return
    }
    dispatch(
      doChangeTicketState(ticketId, 'opened', {
        userInitiated: false,
        requiredState: 'unread',
      })
    )
  }
}

// Touches updatedAt if ticket was closed.
export const doMarkAsUnread = ticketId => (dispatch, getState) => {
  const state = getState()
  const ticket = selectTicketsById(state)[ticketId]
  return dispatch(
    doChangeTicketState(ticketId, 'unread', { userInitiated: isClosed(ticket) })
  )
}

export const doOpenTicket = (ticketId, options = {}) => {
  return doChangeTicketState(ticketId, 'opened', options)
}

export const doCloseTicket = (ticketId, options = {}) => {
  return doChangeTicketState(ticketId, 'closed', options)
}

export const doMarkTicketAsSpam = ticketId => dispatch =>
  dispatch(doChangeTicketState(ticketId, 'spam'))

export const doToggleTicketAsSpam = ticketId => (dispatch, getState) => {
  const state = getState()
  const ticket = selectTicketById(state, ticketId)

  if (!ticket || isEmptyObj(ticket)) return false
  if (isSpam(ticket)) return dispatch(doOpenTicket(ticketId))
  return dispatch(doMarkTicketAsSpam(ticketId))
}

export function doMarkCurrentTicketAsSpam() {
  return (dispatch, getState) => {
    const state = getState()
    const currentTicketId = selectCurrentTicketId(state)
    dispatch(doMarkTicketAsSpam(currentTicketId))
  }
}

export function doDeleteCurrentTicket(deleteMode = 'SOFT') {
  return (dispatch, getState) => {
    const state = getState()
    const currentTicketId = selectCurrentTicketId(state)
    return dispatch(doDeleteTicket(currentTicketId, { deleteMode }))
  }
}

function detectTicketNotDeleted(byId) {
  return id => {
    return byId[id] && !byId[id].deleted_at
  }
}

export const isDeleteHard = (ticketIds, state) => {
  const byId = selectTicketsById(state)
  return !ticketIds.some(detectTicketNotDeleted(byId))
}

export function doBulkDeleteTickets(ticketIds) {
  return (dispatch, getState) => {
    if (!ticketIds) return null
    const hardDelete = isDeleteHard(ticketIds, getState())

    if (hardDelete) {
      return doHardDeleteTickets(ticketIds)(dispatch, getState)
    }

    return doBulkSoftDeleteTickets(ticketIds)(dispatch, getState)
  }
}

function doBulkSoftDeleteTickets(ticketIds) {
  return (dispatch, getState) => {
    const state = getState()
    const byId = selectTicketsById(state)
    const filteredTicketIds = ticketIds.filter(detectTicketNotDeleted(byId))
    const optimisticData = bulkDeletePayload(
      state,
      filteredTicketIds,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )
    const inputs = filteredTicketIds.map(id => {
      return { ticketId: id, delete: true }
    })

    return dispatch(
      doSendBulkChangeset(inputs, { optimisticData }) // disableNavigateToFolder: true
    )
  }
}

export const doRestoreTicket = ticketId => (dispatch, getState) => {
  const state = getState()
  const optimisticData = restorePayload(
    state,
    ticketId,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state)
  )
  return dispatch(
    doSendChangeset(ticketId, { restore: true }, { optimisticData })
  )
}

export function doRestoreTickets(ticketIds) {
  return (dispatch, getState) => {
    const state = getState()
    const optimisticData = bulkRestorePayload(
      state,
      ticketIds,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )
    const inputs = ticketIds.map(id => {
      return { ticketId: id, restore: true }
    })

    const actions = []
    actions.push(doSendBulkChangeset(inputs, { optimisticData }))
    dispatch(batchActions(actions))
  }
}

export function doInvertTicketState(ticketId, stateName) {
  const newStateName = invertTicketState(stateName)
  if (stateName === 'deleted') {
    return batchActions(
      doRestoreTicket(ticketId),
      doShowSnackbar(`Conversation restored`)
    )
  }
  return batchActions(
    doChangeTicketState(ticketId, newStateName),
    doShowSnackbar(`Conversation ${stateName}`, {
      link: `/tickets/${ticketId}`,
      linkText: 'Go back to conversation',
    })
  )
}

export function doToggleTicketState(
  ticketId,
  stateLabel,
  isSnoozed,
  isDeleted
) {
  return dispatch => {
    // GR: Perf: All this logic is ancient, and relies on decorated ticket. Defo
    // can be optimized
    const stateName = inverseStateName(stateLabel)

    if (isDeleted) {
      dispatch(doShowSnackbar(`Conversation restored`))
      return dispatch(doRestoreTicket(ticketId))
    }
    if (isSnoozed) {
      dispatch(doShowSnackbar(`Conversation closed`))
      return dispatch(doUnsnoozeAndKeepClosed(ticketId))
    }

    dispatch(
      doShowSnackbar(`Conversation ${stateName}`, {
        link: `/tickets/${ticketId}`,
        linkText: 'Go back to conversation',
      })
    )
    return dispatch(doChangeTicketState(ticketId, stateName))
  }
}

export const doOpenTicketMergeDrawer = ticketId => dispatch => {
  dispatch(doOpenDrawer(ticketId, DRAWER_TICKET_MERGE, ticketId))
}
