import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    width={136}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    fill="#E8AA08"
    ref={ref}
    {...props}
  >
    <path d="M20.471 10.052a.847.847 0 0 0-.73-.56l-4.83-.695-2.164-4.325A.85.85 0 0 0 11.983 4a.85.85 0 0 0-.764.472L9.055 8.789l-4.83.703a.846.846 0 0 0-.688.569.828.828 0 0 0 .212.836l3.506 3.347-.849 4.752a.83.83 0 0 0 .34.837.86.86 0 0 0 .891.058l4.346-2.234 4.33 2.243a.8.8 0 0 0 .39.1c.18 0 .355-.055.5-.159a.831.831 0 0 0 .34-.837l-.849-4.752 3.506-3.346a.83.83 0 0 0 .271-.854zm28 0a.847.847 0 0 0-.73-.56l-4.83-.695-2.164-4.325A.85.85 0 0 0 39.983 4a.85.85 0 0 0-.764.472l-2.164 4.317-4.83.703a.846.846 0 0 0-.688.569.828.828 0 0 0 .212.836l3.506 3.347-.849 4.752a.83.83 0 0 0 .34.837.86.86 0 0 0 .891.058l4.346-2.234 4.33 2.243a.8.8 0 0 0 .39.1c.18 0 .355-.055.5-.159a.831.831 0 0 0 .34-.837l-.849-4.752 3.506-3.346a.83.83 0 0 0 .271-.854zm28 0a.847.847 0 0 0-.73-.56l-4.83-.695-2.164-4.325A.85.85 0 0 0 67.983 4a.85.85 0 0 0-.764.472l-2.164 4.317-4.83.703a.846.846 0 0 0-.688.569.828.828 0 0 0 .212.836l3.506 3.347-.849 4.752a.83.83 0 0 0 .34.837.86.86 0 0 0 .891.058l4.346-2.234 4.33 2.243a.8.8 0 0 0 .39.1c.18 0 .355-.055.5-.159a.831.831 0 0 0 .34-.837l-.849-4.752 3.506-3.346a.83.83 0 0 0 .271-.854zm28 0a.847.847 0 0 0-.73-.56l-4.83-.695-2.164-4.325A.85.85 0 0 0 95.983 4a.85.85 0 0 0-.764.472l-2.164 4.317-4.83.703a.846.846 0 0 0-.688.569.828.828 0 0 0 .212.836l3.506 3.347-.849 4.752a.83.83 0 0 0 .34.837.86.86 0 0 0 .891.058l4.346-2.234 4.33 2.243a.8.8 0 0 0 .39.1c.18 0 .355-.055.5-.159a.831.831 0 0 0 .34-.837l-.849-4.752 3.506-3.346a.83.83 0 0 0 .271-.854zm28 0a.847.847 0 0 0-.73-.56l-4.83-.695-2.164-4.325a.85.85 0 0 0-.764-.472.85.85 0 0 0-.764.472l-2.164 4.317-4.83.703a.846.846 0 0 0-.688.569.828.828 0 0 0 .212.836l3.506 3.347-.849 4.752a.83.83 0 0 0 .34.837.86.86 0 0 0 .891.058l4.346-2.234 4.33 2.243a.8.8 0 0 0 .39.1c.18 0 .355-.055.5-.159a.831.831 0 0 0 .34-.837l-.849-4.752 3.506-3.346a.83.83 0 0 0 .271-.854z" />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
