import { batchActions } from 'util/redux'
import { basicFields as mailboxFields } from 'ducks/mailboxes/api'
import { doMarkFetchingStatus } from 'actions/app/doMarkFetchingStatus'
import graphql from 'api/graphql'
import {
  oauthTokenSelector,
  fetchingStatusesSelector,
} from 'selectors/app/base'
import debug from 'util/debug'
import { doUpdateInaccessibleMailboxes } from './doUpdateInaccessibleMailboxes'

export function doFetchInaccessibleMailboxes() {
  return (dispatch, getState) => {
    const state = getState()
    const fetchingStatuses = fetchingStatusesSelector(state)
    const fetchingStatusKey = 'fetchInaccessibleMailboxes'
    // ensure we never have more than one concurrent request
    if (fetchingStatuses[fetchingStatusKey]) return
    dispatch(doMarkFetchingStatus(fetchingStatusKey, true))

    const query = `
      query InaccessibleMailboxQuery {
        mailboxes(inaccessible: true) {
          ${mailboxFields}
        }
      }
    `
    const token = oauthTokenSelector(state)

    graphql(token, query)
      .then(res => {
        const data = res.json.data
        dispatch(
          batchActions(
            doUpdateInaccessibleMailboxes(data.mailboxes),
            doMarkFetchingStatus(fetchingStatusKey, false)
          )
        )
      })
      .catch(error => {
        debug(error)
      })
  }
}
