import graphql from 'api/graphql'
import { basicFieldsWithTwitter } from 'api/tickets'
import { toGraphQL } from 'util/search/sorting'

export function searchQuery() {
  return `
    query TicketSearchQuery($page: Int, $sortOrder: String, $searchQuery: SearchQuery, $perPage: Int) {
      search {
        tickets(query: $searchQuery, order: $sortOrder, page: $page, per_page: $perPage) {
          metadata {
            totalCount
            timestamp
            currentPage
            totalPages
          }
          records {
            ${basicFieldsWithTwitter}
            drafts {
              body
            }
            conversationDrafts {
              id
              version
              draftType
              payload
            }
            search_summary {
              body
              note
              forwarded
              author {
                id
                name
                first_name
                last_name
                email
                avatar_url
                type
              }
            }
          }
        }
      }
    }
  `
}

const mapPayload = res => {
  const data = res.json.data
  const search = data.search
  const {
    currentPage,
    totalPages,
    totalCount,
    timestamp,
  } = search.tickets.metadata
  const tickets = search.tickets.records

  return {
    currentPage,
    totalPages,
    totalCount,
    tickets,
    timestamp,
    runtime: res.runtime,
    latency: res.latency,
  }
}

// Takes an API query object, and the sortOrder (e.g. newest/oldest)
//
// Maps the sortOrder into sthg that GQL understands e.g.
//
//   'oldest' -> 'updated_at asc'
export const searchTickets = (
  token,
  query,
  sortOrder,
  page,
  perPage,
  sortOptions
) =>
  graphql(token, searchQuery(), {
    searchQuery: query,
    sortOrder: toGraphQL(sortOrder, sortOptions),
    page,
    perPage,
  }).then(mapPayload)
