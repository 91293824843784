import grooveApi from '../groove'

const GITHUB_ENDPOINT_PREFIX = 'v1/integrations/github/'

const checkProxyResponse = ({ json }) => {
  const {
    meta: { status },
    data,
  } = json
  if (status === 200 || status === 201) {
    return Promise.resolve(json)
  }
  if (status >= 400) Promise.reject(data)
  return Promise.reject()
}

const getNextLoadPath = ({ pagination }) =>
  pagination && (pagination.next || pagination.last)

const keepGoingIfNeeded = (token, counter = 1) => ({ meta, data }) => {
  if (counter > 100) return Promise.reject('Too many api calls')
  const nextLoadPath = getNextLoadPath(meta)
  if (nextLoadPath) {
    return grooveApi
      .get(token, nextLoadPath)
      .then(checkProxyResponse)
      .then(({ meta: newMeta, data: newData }) =>
        keepGoingIfNeeded(token, counter + 1)({
          meta: newMeta,
          data: [...data, ...newData],
        })
      )
  }
  return Promise.resolve(data)
}

const transformPostData = data => ({ data })

const get = (token, path, params) =>
  grooveApi
    .get(token, GITHUB_ENDPOINT_PREFIX + path, params)
    .then(checkProxyResponse)
    .then(keepGoingIfNeeded(token))

const post = (token, path, data) =>
  grooveApi
    .post(
      token,
      GITHUB_ENDPOINT_PREFIX + path,
      null,
      JSON.stringify(transformPostData(data))
    )
    .then(checkProxyResponse)

const groovifyGithubUrl = url => url.replace('https://api.github.com/', '')

export const listRepos = token => get(token, 'user/repos')

export const listLabels = (token, repo) => get(token, `repos/${repo}/labels`)

export const listAssignees = (token, repo) =>
  get(token, `repos/${repo}/assignees`)

export const listIssues = (token, repo) => get(token, `repos/${repo}/issues`)

export const createIssue = (token, { repo, ...data }) =>
  post(token, `repos/${repo}/issues`, {
    title: data.title,
    ...(data.body && { body: data.body }),
    ...(data.label && { labels: [data.label] }),
    ...(data.assignee && { assignees: [data.assignee] }),
  }).then(({ data: response }) => response)

export const attachTicketToIssue = (
  token,
  repoUrl,
  issueNumber,
  ticketUrl,
  text
) =>
  post(token, `${groovifyGithubUrl(repoUrl)}/issues/${issueNumber}/comments`, {
    body: `${text}\n${ticketUrl}`,
  }).then(({ data }) => data)
