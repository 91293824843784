import { generateRandomLabelColor } from 'util/labels'
import { isEmpty } from 'util/strings'
import * as types from 'constants/action_types'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import {
  selectActiveLabelIndex,
  selectLabelSearchTerm,
  selectLabelSelection,
  selectCurrentSelectableLabels,
  selectLabelsForEditing,
} from 'selectors/labelings'
import {
  doCreateLabel,
  doSearchLabels,
  doWaitForLabelToIndex,
} from 'actions/labels'

import applyLabelings from './applyLabelings'
import { doHandleLabelSelection } from './doHandleLabelSelection'
import { doUpdateActiveLabel } from './doUpdateActiveLabel'

export const doLabelCurrentTicket = () => (dispatch, getState) => {
  const state = getState()
  const currentTicket = selectCurrentTicket(state)
  const ticketId = currentTicket.id

  dispatch({ type: types.LABELINGS_SAVED, data: { ticketId } })

  return applyLabelings(state, dispatch, ticketId)
}

// Toggle label (via label selection state mechanism) and immediately update
// via API.
export function doToggleLabel(ticketId, label) {
  return (dispatch, getState) => {
    if (!ticketId || !label) return false
    const state = getState()
    const labelSelection = selectLabelSelection(state)

    dispatch(
      doHandleLabelSelection(label, labelSelection, { applyAndClose: true })
    )

    // Note: at this point we fetch the state again! So we need to be sure
    // that the changes we made here don't get cleared in the meantime.
    return applyLabelings(getState(), dispatch, ticketId)
  }
}

// Adds/Removes labels in batches. We use the existing (mobile) style of editing
// labels client-side via doHandleLabelSelection
export function doToggleLabelBatched(ticketId, label) {
  return (dispatch, getState) => {
    if (!ticketId || !label) return false
    const state = getState()
    const labelSelection = selectLabelSelection(state)

    dispatch(
      doHandleLabelSelection(label, labelSelection, { applyAndClose: true })
    )

    applyLabelings(getState(), dispatch, ticketId)

    return true
  }
}

export function doUpdateActiveLabelBy(amount) {
  return (dispatch, getState) => {
    const state = getState()
    let oldIndex = selectActiveLabelIndex(state)

    const minValue = 0
    const maxValue = selectCurrentSelectableLabels(state).length - 1
    if (oldIndex > maxValue) oldIndex = maxValue

    let newIndex = oldIndex + amount
    if (newIndex < minValue) newIndex = minValue
    if (newIndex > maxValue) newIndex = amount < 0 ? maxValue - 1 : maxValue

    dispatch(doUpdateActiveLabel(newIndex))
  }
}

export function doSelectLabelSearchTerm() {
  return (dispatch, getState) => {
    const state = getState()
    const searchTerm = selectLabelSearchTerm(state)
    const labelSelection = selectLabelSelection(state)

    return dispatch(
      doHandleLabelSelection(
        { id: searchTerm, name: searchTerm },
        labelSelection,
        { isSearchResult: true, dontRemove: true }
      )
    )
  }
}

// Toggle label (via label selection state mechanism) and immediately update
// via API.
export function doCreateAndToggleLabel(ticketId, label) {
  return dispatch => {
    if (!ticketId || !label) return false

    return dispatch(doCreateLabel(label)).then(newLabel => {
      dispatch(doToggleLabel(ticketId, newLabel))
    })
  }
}

// SmartToggle handles the following cases
// 1.   if customer selects an existing label (id > 0)
//      then add label to the ticket
// 2.a  if customer selects new label (id === -1 and mode === single)
//      and label doesnt already exist (!labels.name.includes(searchTerm))
//      then create new label
//      then add label to ticket
// 2.b  if customer selects new label (id === -1 and mode === bulk)
//      and label doesnt already exist (!labels.name.includes(searchTerm))
//      then create new label
//      then select the new label
// 3.   if customer selects new label (id === -1)
//      and label already exist (labels.name.includes(searchTerm))
//      then add existing label to ticket
export function doSmartToggleLabel(ticketId, label, mode = 'single') {
  return (dispatch, getState) => {
    if (!ticketId && mode !== 'bulk')
      return Promise.reject('Invalid ticket id provided')
    if (!label) return Promise.reject('Invalid label provided')

    // Case 1
    if (label.id > 0) return dispatch(doToggleLabel(ticketId, label))

    const state = getState()
    const labels = selectLabelsForEditing(state)
    const searchTerm = selectLabelSearchTerm(state)
    const existingLabel = labels.find(l => {
      return (
        (l.name || '').localeCompare(searchTerm, { sensitivity: 'base' }) === 0
      )
    })

    if (!existingLabel && isEmpty(label.color)) {
      label.color = generateRandomLabelColor() // eslint-disable-line no-param-reassign
    }

    // Case 2.a
    if (label.id === -1 && !existingLabel && mode === 'single') {
      return dispatch(doCreateAndToggleLabel(ticketId, label))
    }

    // Case 2.b
    if (label.id === -1 && !existingLabel && mode === 'bulk') {
      return dispatch(doCreateLabel(label, { addAsSelected: true })).then(
        createdLabel =>
          dispatch(doWaitForLabelToIndex(createdLabel)).then(() =>
            dispatch(doSearchLabels(searchTerm))
          )
      )
    }

    // Case 3
    return dispatch(doToggleLabel(ticketId, existingLabel))
  }
}
