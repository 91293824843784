import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="M19 9.6V9.3c0-.1-.1-.2-.1-.2l-4.7-4.8-.2-.2h-.1c-.1-.1-.2-.1-.3-.1h-3.1C9.2 4 8.1 5.1 8.1 6.4v.8h-.8C6 7.2 5 8.3 5 9.6v8C5 18.9 6 20 7.3 20h6.2c1.3 0 2.3-1.1 2.3-2.4v-.8h.8c1.3 0 2.3-1.1 2.3-2.4V9.6zm-4.7-2.9l2 2.1h-1.2c-.4 0-.8-.4-.8-.8V6.7zm0 10.9c0 .4-.3.8-.8.8H7.3c-.4 0-.8-.4-.8-.8v-8c0-.4.3-.8.8-.8h.8v5.6c0 1.3 1 2.4 2.3 2.4h3.9v.8zm3.1-3.2c0 .4-.3.8-.8.8h-6.2c-.4 0-.8-.4-.8-.8v-8c0-.4.3-.8.8-.8h2.3V8c0 1.3 1 2.4 2.3 2.4h2.3v4z"
      />
    </svg>
  )
})
