import { BEGIN, REVERT } from 'redux-optimist'

import graphql from 'api/graphql'
import * as types from 'constants/action_types'

import { doOpenTicketPage, doOpenNewTicketPage } from 'actions/pages'
import { doDeleteTicket } from 'actions/tickets/state/doDeleteTicket'

import { undoSendPayload } from 'optimistic/undoSend'

import { oauthTokenSelector, selectCurrentFolderId } from 'selectors/app'
import { selectPreferences } from 'ducks/currentUser/selectors/preferences/selectPreferences'
import { selectCurrentTicketSearchQueryObject } from 'selectors/search'
import { selectUndoSendSafe } from 'selectors/undoSend'
import { doReinstateDraft } from 'ducks/drafts2/operations/doReinstateDraft'
import { retryOperation } from 'util/promises'

const currentUndo = { timer: null, changeset: null }

export function startTimer(changesetId, ticketId, draft) {
  return (dispatch, getState) => {
    // dont start the timer if it's been already running
    if (currentUndo.changeset === changesetId) return false
    const state = getState()
    const undo = selectUndoSendSafe(state)
    const { undo_send_delay: delaySeconds } = selectPreferences(state)
    if (undo && undo.state) return false // aleady sending, sent or undone

    // We support only one timer at a time so stop the previous timer
    stopTimer(currentUndo.changeset)

    // start and save the new timer
    currentUndo.changeset = changesetId
    currentUndo.timer = setTimeout(
      () =>
        dispatch(
          doEndTimer({
            changesetId,
            draftId: draft.id,
          })
        ),
      delaySeconds * 1000
    )

    return dispatch({
      type: types.UNDO_SEND_TIMER_START,
      data: {
        ticketId,
        changesetId,
        draft,
      },
    })
  }
}

// Handles when the undo send timer naturally expires. This function stops the
// timer.
export function doEndTimer({ changesetId, draftId }) {
  stopTimer(changesetId)

  return dispatch => {
    dispatch({
      type: types.UNDO_SEND_TIMER_END,
      data: { changesetId, draftId },
    })
  }
}

function stopTimer(changesetId) {
  if (currentUndo.changeset === changesetId) {
    clearInterval(currentUndo.timer)
    currentUndo.changeset = null
    currentUndo.timer = null
  }
  // Does not return an action - Use the UNDO_SEND_REQUEST action instead
}

const doUndoSendRequest = (changesetId, ticketId) => (dispatch, getState) => {
  const state = getState()
  const token = oauthTokenSelector(state)
  const { draft } = selectUndoSendSafe(state)

  const buildPayload = messageChangesetId => ({
    ...undoSendPayload(
      state,
      ticketId,
      messageChangesetId,
      selectCurrentFolderId(state),
      selectCurrentTicketSearchQueryObject(state)
    ),
    undoSendDraft: draft,
  })

  const optimisticData = buildPayload(changesetId)
  const transactionID = `undoSend-${changesetId}`
  dispatch({
    type: types.UNDO_SEND_REQUEST,
    data: optimisticData,
    optimist: { type: BEGIN, id: transactionID },
  })

  const mutation = `
      mutation DeleteChangeset($id: String, $ticketId: String) {
        deleteChangeset(id: $id, ticketId: $ticketId) {
          id
        }
      }
    `
  const variables = { id: changesetId, ticketId }

  const processResult = res => {
    const { id: deletedId } = res.json.data.deleteChangeset
    return dispatch({
      type: types.UNDO_SEND_SUCCESS,
      data: buildPayload(deletedId),
      optimist: { type: REVERT, id: transactionID },
    })
  }

  const errorHandler = err => {
    return dispatch({
      type: types.UNDO_SEND_FAIL,
      data: { err, changesetId, ticketId },
      optimist: { type: REVERT, id: transactionID },
    })
  }

  return retryOperation(() => graphql(token, mutation, variables), 1000, 3)
    .then(processResult)
    .catch(errorHandler)
}

export const doUndoSend = undoData => (dispatch, getState) => {
  const { ticketId, changesetId, draft } = undoData

  const isNewTicket = draft?.ticketId === 'new'

  dispatch({
    type: types.UNDO_SEND,
    data: { ticketId, changesetId, isNewTicket },
  })

  stopTimer(changesetId)

  // When we undo, go back to the new/reply page so the user can
  // start editing the draft again
  const state = getState()
  if (isNewTicket) {
    dispatch(
      doDeleteTicket(ticketId, {
        suppressSnackbar: true,
        disableNavigateToFolder: true,
      })
    )
    dispatch(doOpenNewTicketPage())
  } else {
    dispatch(doOpenTicketPage(ticketId))
  }
  const { draft: undoDraft } = selectUndoSendSafe(state)
  dispatch(doReinstateDraft(undoDraft))
  dispatch(doUndoSendRequest(changesetId, ticketId))

  return dispatch({
    type: types.UNDO_SEND_COMPLETE,
    data: { ticketId, changesetId, isNewTicket },
  })
}
