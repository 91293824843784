import { selectSelectedTicketIds } from 'selectors/ticket_list/base'

import applyBulkLabelings from './applyBulkLabelings'

export const doLabelSelectedTickets = () => (dispatch, getState) => {
  const state = getState()
  const ticketIds = selectSelectedTicketIds(state)

  return applyBulkLabelings(state, dispatch, ticketIds)
}
