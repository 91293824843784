import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="M18.3 4.6H5.7C4.2 4.6 3 5.8 3 7.4v9.3c0 1.5 1.2 2.8 2.7 2.8h12.6c1.5 0 2.7-1.2 2.7-2.8V7.4c0-1.6-1.2-2.8-2.7-2.8zm-.4 1.8l-5.3 5.5c-.2.2-.4.3-.6.3s-.5-.1-.6-.3L6.1 6.4h11.8zm1.3 10.2c0 .5-.4.9-.9.9H5.7c-.5 0-.9-.4-.9-.9V7.7l5.3 5.5c1.1 1.1 2.8 1.1 3.8 0l5.3-5.5v8.9z"
      />
    </svg>
  )
})
