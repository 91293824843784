import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h20v20H-3z" />
        <g className="stroke" strokeWidth="1.5" strokeLinecap="round">
          <path d="M1.019 1.607h11.949M1.019 6.983h8.808M1.019 12.415h5.636" />
        </g>
      </g>
    </svg>
  )
})
