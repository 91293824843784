import { doFetchLabelsByIds } from 'actions/labels'
import graphql from 'api/graphql'
import * as types from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectTicketSearchOperatorValueMap } from 'selectors/search'
import { filter } from 'util/objects'
import { constructSearchQueryString } from 'util/search'
import { selectFoldersById } from 'ducks/folders/selectors/folders'

export function doFetchTicketAggregationCountsFor(search, options = {}) {
  const filteredSearch = filter(v => !!v, search)
  const searchQueryString = constructSearchQueryString(filteredSearch)

  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const foldersById = selectFoldersById(state)
    const ticketSearchOperatorValueMap = selectTicketSearchOperatorValueMap(
      state
    )
    const query = `
      query TicketAggregationCountQuery($searchQuery: SearchQuery) {
        ticketAggregations(query: $searchQuery, groupByMailboxes: true, groups: ["labels.id", "state", "mailbox_id", "assignee_id", "assignee_group_id", "unassigned", "assigned", "is_starred", "is_deleted"]) {
          id
          timestamp
          mailboxId
          counts {
            id
            count
          }
        }
      }
    `

    const variables = {
      searchQuery: filteredSearch,
    }

    dispatch({
      type: types.FETCH_TICKET_AGGREGATION_COUNTS_REQUEST,
      data: {
        filteredSearch,
        searchQueryString,
      },
    })

    return graphql(token, query, variables)
      .then(res => {
        const ticketAggregations = res.json.data.ticketAggregations
        const labelCounts = ticketAggregations.find(x => x.id === 'labels.id')
          .counts
        const labelIds = labelCounts.map(x => x.id).filter(x => !!x && x !== '')
        dispatch({
          type: types.FETCH_TICKET_AGGREGATION_COUNTS_SUCCESS,
          data: {
            filteredSearch,
            searchQueryString,
            ticketAggregations,
            trackMetrics: options.trackMetrics,
            isManual: options.isManual,
            isAutomatic: options.isAutomatic,
            foldersById,
            ticketSearchOperatorValueMap,
          },
        })

        dispatch(doFetchLabelsByIds(labelIds, { reload: false }))

        return ticketAggregations
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_TICKET_AGGREGATION_COUNTS_FAIL,
          data: {
            filteredSearch,
            searchQueryString,
            error,
          },
        })
        // Swallow as not a train smash if some counts dont load.
      })
  }
}
