import { doLoadSearchTickets } from 'actions/search/doLoadSearchTickets'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'

export function doLoadSearchTicketsOnNewConversationPage() {
  return (dispatch, getState) => {
    const state = getState()
    const prefersClassicView = selectPrefersClassicView(state)

    if (prefersClassicView) {
      return Promise.resolve(true)
    }

    return dispatch(doLoadSearchTickets())
  }
}
