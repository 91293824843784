import { doNavigateToReturnPath } from 'actions/app'
import { doShowSnackbar } from 'actions/snackbar'

import { bulkStarPayload } from 'optimistic/star'
import { bulkStatusChangePayload } from 'optimistic/status'

import { selectCurrentFolderPath, selectCurrentFolderId } from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectedTicketsIncludesCurrentTicket } from 'selectors/tickets'
import { selectAreAllSelectedStarred } from 'selectors/ticket_list'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'

import { getLength } from 'util/arrays'
import { pluralize } from 'util/strings'
import { batchActions } from 'util/redux'

import { doSendBulkChangeset } from './changeset'
import { doBulkDeleteTickets, doRestoreTickets, isDeleteHard } from './state'
import { doUnsnoozeTickets } from './snooze'

function markSelectedTicketsAs(newState) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)
    dispatch(doBulkChangeState(ticketIds, newState))
  }
}

export function doMarkSelectedTicketsAsSpam() {
  return markSelectedTicketsAs('spam')
}

export function doMarkSelectedTicketsAsRead() {
  return markSelectedTicketsAs('opened')
}

export function doMarkSelectedTicketsAsUnread() {
  return markSelectedTicketsAs('unread')
}

// If all the selected tickets are starred, this action wil unstar all of them.
// If some the selected tickets are starred, this action wil star the unstarred
// ones.
// If none the selected tickets are starred, this action wil star all of them
export function doBulkStarSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    if (selectAreAllSelectedStarred(state))
      return dispatch(doBulkStar(ticketIds, false))

    // TODO optimize to only update unstarred
    // if (selectAreSomeSelectedStarred(state)) ...
    return dispatch(doBulkStar(ticketIds, true))
  }
}

export function doStarSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    return dispatch(doBulkStar(ticketIds, true))
  }
}

export function doUnstarSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    return dispatch(doBulkStar(ticketIds, false))
  }
}

export const doToggleSelectedTicketStates = commonState => (
  dispatch,
  getState
) => {
  const ticketIds = selectSelectedTicketIds(getState())
  // In theory we should only ever get all deleted or not in selected tickets,
  // because deleted tickets don't show up in other searches. So you must have
  // searched for `is:deleted` and so all must be deleted or not. We may want to
  // be more robust here at some point.
  if (commonState === 'deleted') {
    return dispatch(doRestoreTickets(ticketIds))
  }
  if (commonState === 'snoozed') {
    return dispatch(doUnsnoozeTickets(ticketIds))
  }
  const openable = ['closed', 'spam'].includes(commonState)
  const newState = openable ? 'opened' : 'closed'
  return dispatch(doBulkChangeState(ticketIds, newState))
}

export function doOpenSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)
    if (ticketIds.length === 0) return null
    // In theory we should only ever get all deleted or not in selected tickets,
    if (state.tickets.byId[ticketIds[0]].deleted_at) {
      return dispatch(doRestoreTickets(ticketIds))
    }
    return dispatch(doBulkChangeState(ticketIds, 'opened'))
  }
}

export function doCloseSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)
    if (ticketIds.length === 0) return
    dispatch(doBulkChangeState(ticketIds, 'closed'))
  }
}

export function doDeleteSelectedTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    const count = getLength(ticketIds)
    const currentFolderPath = selectCurrentFolderPath(state)
    const includesCurrentTicket = selectedTicketsIncludesCurrentTicket(state)

    if (
      isDeleteHard(ticketIds, state) &&
      // eslint-disable-next-line no-alert
      !confirm(
        `Are you sure you want to permanently delete the selected ${pluralize(
          count,
          'ticket'
        )}?`
      )
    ) {
      return
    }

    const actions = [
      doBulkDeleteTickets(ticketIds),
      doShowSnackbar(`${count} deleted`),
    ]

    if (includesCurrentTicket) {
      actions.unshift(doNavigateToReturnPath(currentFolderPath))
    }

    dispatch(batchActions(actions))
  }
}

export function doBulkChangeState(ticketIds, newState) {
  return (dispatch, getState) => {
    const state = getState()

    const optimisticData = bulkStatusChangePayload(
      state,
      ticketIds,
      newState,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )

    const inputs = ticketIds.map(ticketId => ({ state: newState, ticketId }))
    const actions = []
    actions.push(doSendBulkChangeset(inputs, { optimisticData }))
    dispatch(batchActions(actions))
  }
}

export const doBulkMarkSpam = ticketIds => doBulkChangeState(ticketIds, 'spam')

export function doBulkStar(ticketIds, isStarred) {
  return (dispatch, getState) => {
    const state = getState()
    const priority = isStarred ? 'urgent' : 'low'

    const optimisticData = bulkStarPayload(
      state,
      ticketIds,
      isStarred,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )

    const inputs = ticketIds.map(ticketId => {
      return { ticketId, priority }
    })
    const actions = []
    actions.push(doSendBulkChangeset(inputs, { optimisticData }))
    dispatch(batchActions(actions))
  }
}
