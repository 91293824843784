import { doOpenTicketPage } from 'actions/pages'
import { doOpenSearchPage } from 'actions/pages/doOpenSearchPage'
import { doOpenFolderPage } from 'actions/pages/doOpenFolderPage'
import { doFetchCurrentFolderWithTicketsNextPage } from 'actions/tickets/doFetchCurrentFolderWithTicketsNextPage'

import * as types from 'constants/action_types'

import {
  selectCurrentSearchQueryString,
  selectCurrentFolderId,
} from 'selectors/app'
import { selectAutoAdvanceToNextTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoAdvanceToNextTicketInList'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectCurrentTicketSearchResultTicketIds } from 'selectors/search'
import {
  selectNextTicketIdInList,
  selectPreviousTicketIdInList,
  selectCurrentPage,
  selectTotalPages,
  selectIsLoadingMoreTickets,
} from 'selectors/ticket_list'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

export function shouldStayOnPage(state, ticketId) {
  // If no more in the list, and it still matches search/folder, stay on ticket
  const ticketIds = selectCurrentTicketSearchResultTicketIds(state)
  const stillInTicketList = ticketIds.includes(ticketId)
  return stillInTicketList
}

function redirectPage(dispatch, state, ticketId, nextTicketId) {
  const shouldAdvance = selectAutoAdvanceToNextTicketInList(state)
  if (!shouldAdvance) return undefined
  if (shouldStayOnPage(state, ticketId)) return true

  const currentTicketId = selectCurrentTicketId(state)

  // If more in list, go to next ticket
  // don't go to next ticket if action not on current ticket e.g. closing another ticket using quick actions
  if (nextTicketId) {
    if (ticketId === currentTicketId) {
      return dispatch(doOpenTicketPage(nextTicketId))
    }
    return false
  }

  // Or show the empty search/folder
  const queryStr = selectCurrentSearchQueryString(state)
  if (queryStr) return dispatch(doOpenSearchPage(queryStr))

  const folderId = selectCurrentFolderId(state)
  if (folderId) {
    return dispatch(doOpenFolderPage(folderId, selectCurrentMailboxId(state)))
  }

  return false
}

export function doAutoRedirect(ticketId, nextTicketId) {
  return (dispatch, getState) => {
    if (!nextTicketId) return undefined
    const state = getState()
    dispatch({
      type: types.AUTO_REDIRECT,
      data: { ticketId, nextTicketId },
    })
    return redirectPage(dispatch, state, ticketId, nextTicketId)
  }
}

export function doOpenNextTicket() {
  return (dispatch, getState) => {
    const state = getState()
    const currentPage = selectCurrentPage(state)
    const totalPages = selectTotalPages(state)
    const isLoadingMore = selectIsLoadingMoreTickets(state)
    const nextTicketId = selectNextTicketIdInList(state)
    if (nextTicketId) return dispatch(doOpenTicketPage(nextTicketId))
    if (currentPage < totalPages && !isLoadingMore) {
      return dispatch(doFetchCurrentFolderWithTicketsNextPage()).then(() =>
        dispatch(doOpenNextTicket())
      )
    }
    return null
  }
}

export function doOpenPreviousTicket() {
  return (dispatch, getState) => {
    const state = getState()
    const previousTicketId = selectPreviousTicketIdInList(state)
    if (previousTicketId) dispatch(doOpenTicketPage(previousTicketId))
  }
}

export function doOpenCurrentSearch() {
  return (dispatch, getState) => {
    // Or show the empty search/folder
    const state = getState()
    const queryStr = selectCurrentSearchQueryString(state)
    if (queryStr) return dispatch(doOpenSearchPage(queryStr))
    return null
  }
}
