import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-3h20v20H-3z" />
        <path
          d="M1.034 10.379v1.931c0 .367.3.667.667.667h7.086"
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M8.743 12.977h1.853c1.297 0 2.35-1.067 2.35-2.384V1.724c0-.4-.32-.724-.714-.724H3.383c-1.297 0-2.35 1.067-2.35 2.384v.242M1 6.999h7.028"
          className="stroke"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.811 6.997L6.648 3.46a.304.304 0 0 0-.53.202v6.67c0 .279.344.41.53.203l3.163-3.538z"
          className="fill"
        />
      </g>
    </svg>
  )
})
