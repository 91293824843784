import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd" transform="translate(.333 .333)">
        <path
          className="fill"
          strokeWidth="1.5"
          strokeLinecap="round"
          d="M14 7h1.167c.644 0 1.166-.522 1.166-1.167 0-.644-.522-1.166-1.166-1.166H14c-.644 0-1.167.522-1.167 1.166C12.833 6.478 13.356 7 14 7zm0 4.667h1.167c.644 0 1.166-.523 1.166-1.167s-.522-1.167-1.166-1.167H14c-.644 0-1.167.523-1.167 1.167s.523 1.167 1.167 1.167zM8.167 7h1.166c.645 0 1.167-.522 1.167-1.167 0-.644-.522-1.166-1.167-1.166H8.167C7.522 4.667 7 5.19 7 5.833 7 6.478 7.522 7 8.167 7zm0 4.667h1.166c.645 0 1.167-.523 1.167-1.167s-.522-1.167-1.167-1.167H8.167C7.522 9.333 7 9.856 7 10.5s.522 1.167 1.167 1.167zm14 9.333H21V1.167C21 .522 20.478 0 19.833 0H3.5c-.644 0-1.167.522-1.167 1.167V21H1.167C.522 21 0 21.522 0 22.167c0 .644.522 1.166 1.167 1.166h21c.644 0 1.166-.522 1.166-1.166 0-.645-.522-1.167-1.166-1.167zm-9.334 0H10.5v-4.667h2.333V21zm5.834 0h-3.5v-5.833c0-.645-.523-1.167-1.167-1.167H9.333c-.644 0-1.166.522-1.166 1.167V21h-3.5V2.333h14V21z"
        />
      </g>
    </svg>
  )
})
