import { SEARCH_PAGE } from 'constants/pages'

export const doOpenSearchPayload = (
  term,
  isTypedSearch,
  currentQuery,
  options = {}
) => {
  return {
    type: SEARCH_PAGE,
    payload: { term, isTypedSearch },
    meta: {
      query: { ...currentQuery, ...(options.query || {}) },
    },
  }
}
