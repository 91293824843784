import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
      ref={forwardedRef}
    >
      <defs>
        <path
          id="smiley"
          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
        />
      </defs>
      <g id="new-icons/smiley-24" fill="none" fillRule="evenodd">
        <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        <mask id="smiley-mask" fill="#fff" className="fill">
          <use xlinkHref="#smiley" />
        </mask>
        <use
          className="fill"
          id="Shape"
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#smiley"
        />
        <g
          className="fill"
          id="Colors/@7E8F9F"
          mask="url(#smiley-mask)"
          fill="var(--color-monochrome-mediumDark)"
        >
          <rect id="Rectangle" width="24" height="24" />
        </g>
      </g>
    </svg>
  )
})
