import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 10"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-5h20v20H-3z" />
        <g transform="translate(1 1)">
          <path
            d="M8.584 8a.972.972 0 0 0 .669-.325l2.731-3.43c.172-.121.177-.342.003-.495L9.26.325A.972.972 0 0 0 8.591 0H.525a.416.416 0 0 0-.41.419V7.58c0 .23.185.419.41.419h8.059z"
            className="stroke"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
          <circle className="fill" cx="7" cy="4" r="1" />
        </g>
      </g>
    </svg>
  )
})
