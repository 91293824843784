import graphql from 'api/graphql'
import {
  actionPartials,
  actorPartials,
  basicFieldsWithTwitter,
} from 'api/tickets'
import {
  FETCH_TICKET_REQUEST,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKET_FAIL,
} from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import doFetchExternalResourcesForNewActions from 'ducks/integrations/operations/doFetchExternalResourcesForNewActions'
import debug from 'util/debug'
import { doFetchTicketMergers } from './doFetchTicketMergers'
import { doFetchChangesetTicketActions } from './doFetchChangesetTicketActions'
import { getChangesetParamsIfNeeded } from './getChangesetParamsIfNeeded'

export function fetchTicketOnDesktop(dispatch, state, ticketId, options = {}) {
  dispatch({ type: FETCH_TICKET_REQUEST, data: { ticketId } })
  const token = oauthTokenSelector(state)

  const query = `
      query TicketQuery($ticketId: String, $inlineNotes: Boolean) {
        ticket(id: ${ticketId}) {
          ${basicFieldsWithTwitter}
          drafts {
            body
          }
          conversationDrafts {
            id
            version
            draftType
            payload
          }
        }
        actions(ticketId: $ticketId, inlineNotes: $inlineNotes) {
          records {
            id
            created_at
            changeset
            preview
            change_type
            change {
              ${actionPartials}
            }
            actor {
              ${actorPartials}
            }
          }
        }
      }
    `

  const variables = {
    ticketId,
    inlineNotes: true,
  }
  return graphql(token, query, variables)
    .then(res => {
      const data = res.json.data
      const { ticket, actions } = data
      ticket.actions = actions
      dispatch(doFetchExternalResourcesForNewActions(ticketId, actions.records))
      dispatch({
        type: FETCH_TICKET_SUCCESS,
        data: { ticketId, ticket },
      })
      const changesetLoadParams = getChangesetParamsIfNeeded(ticket)
      if (!changesetLoadParams) return ticket
      const { changesetId, subchangesetIds } = changesetLoadParams
      return dispatch(
        doFetchChangesetTicketActions(ticketId, changesetId, subchangesetIds)
      )
    })
    .catch(err => {
      debug(err)

      dispatch({
        type: FETCH_TICKET_FAIL,
        data: { ticketId, err },
      })

      if (
        !options.skipMergeFetch &&
        err &&
        err.errors &&
        err.errors.some(error => error.status === 301)
      ) {
        dispatch(doFetchTicketMergers(ticketId)).catch(mergerFetchErr => {
          debug(mergerFetchErr)
          return mergerFetchErr
        })
      }

      throw err
    })
}
