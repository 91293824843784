import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="m7.432 10.632c-.0344042.0380159-.0664634.07809-.096.12-.0302771.0445962-.0544893.0930206-.072.144-.0230658.0453485-.0392435.0938817-.048.144-.0039269.0532611-.0039269.1067389 0 .16-.0027041.1049412.0192149.2090565.064.304.072739.2009842.2310158.359261.432.432.1936319.0855814.4143681.0855814.608 0 .2009842-.072739.359261-.2310158.432-.432.0355248-.0972716.0518264-.2005151.048-.304.0012294-.2126424-.0822508-.4170249-.232-.568-.1502133-.1514501-.35469-.2366387-.568-.2366387s-.4177867.0851886-.568.2366387zm.568-10.632c-4.418278 0-8 3.581722-8 8s3.581722 8 8 8 8-3.581722 8-8c0-2.12173192-.8428547-4.15656322-2.3431458-5.65685425-1.500291-1.50029103-3.5351223-2.34314575-5.6568542-2.34314575zm0 14.4c-3.5346224 0-6.4-2.8653776-6.4-6.4s2.8653776-6.4 6.4-6.4 6.4 2.8653776 6.4 6.4c0 1.6973855-.6742838 3.3252506-1.8745166 4.5254834s-2.8280979 1.8745166-4.5254834 1.8745166zm0-10.4000005c-.8579872-.00054959-1.6510065.45696153-2.08 1.2000005-.1576475.24799126-.16664618.56244958-.02343831.8190503.14320791.2566006.41556751.4140339.70939841.4100568.2938308-.0039771.5618296-.1687244.6980399-.4291076.1434728-.24850685.4090522-.40113871.696-.3999995.4418278 0 .8.3581722.8.8s-.3581722.8-.8.8-.8.3581722-.8.7999995v.8000005c0 .4418278.3581722.8.8.8s.8-.3581722.8-.8v-.144c1.0919755-.3962252 1.7434306-1.5176256 1.5467189-2.66248778-.1967117-1.14486215-1.185115-1.98450002-2.3467189-1.99351272z"
      />
    </svg>
  )
})
