import {
  doBulkFetchTicketActions,
  doFetchTicketActions,
  doFetchTicketLinkedResources,
} from 'actions/tickets'
import { doLoadSearchTickets } from 'actions/search/doLoadSearchTickets'
import { selectCurrentTicket } from 'selectors/tickets/current/selectCurrentTicket'
import {
  selectCurrentTicketSearchResultRawTickets,
  selectSearchResultTicket,
} from 'selectors/search'
import metrics from 'util/metrics'

export function doLoadTicketPage() {
  return (dispatch, getState) => {
    const state = getState()
    const ticket = selectCurrentTicket(state)
    if (ticket && ticket.full) {
      if (!ticket.actions) dispatch(doFetchTicketActions(ticket.id))
      if (!ticket.linkedExternalResources)
        dispatch(doFetchTicketLinkedResources(ticket.id))
    }
    dispatch(doLoadSearchTickets())
  }
}

export function doStartTicketLoadTimer(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const ticket = selectSearchResultTicket(state, ticketId)
    const hot = !!(ticket && ticket.full && ticket.actions)
    metrics.startTimer(`ticket-load-${ticketId}`, {
      ticketId,
      metric: 'ticket-load',
      logname: 'main',
      hot,
    })
  }
}

export function doLoadUnloadedSearchTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const tickets = selectCurrentTicketSearchResultRawTickets(state)
    const currentTicket = selectCurrentTicket(state)
    const ticketIds = []
    tickets.forEach(ticket => {
      // Prevent reloading the current ticket, because we loaded it through TicketActionsQuery already
      // So won't overide it, such as overiding the expanded ticket changeset according to the changeset hash id
      const isCurrentTicket = currentTicket && ticket.id === currentTicket.id

      if (ticket && ticket.full && !ticket.actions && !isCurrentTicket) {
        ticketIds.push(ticket.id)
      }
    })
    if (ticketIds.length > 0) dispatch(doBulkFetchTicketActions(ticketIds))
  }
}

export function doForceLoadUnloadedSearchTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const tickets = selectCurrentTicketSearchResultRawTickets(state)
    const ticketIds = []
    tickets.forEach(ticket => {
      if (ticket && ticket.full && !ticket.actions) {
        ticketIds.push(ticket.id)
      }
    })
    if (ticketIds.length > 0)
      dispatch(doBulkFetchTicketActions(ticketIds, true))
  }
}
