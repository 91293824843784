import { doShowModal, doHideModal } from 'actions/modals'
import * as modals from 'constants/modal_types'
import * as types from 'constants/action_types'
import { getLength } from 'util/arrays'

// Each image object needs only an src property
export const doOpen = (images, startingIndex = 0) => {
  return doShowModal(modals.LIGHTBOX, {
    imageIndex: startingIndex,
    images,
    totalImages: getLength(images),
  })
}

export const doClose = () => doHideModal(modals.LIGHTBOX)

export function doShowNext() {
  return {
    type: types.LIGHTBOX_NEXT,
  }
}

export function doShowPrevious() {
  return {
    type: types.LIGHTBOX_PREVIOUS,
  }
}
