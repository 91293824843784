import { selectDrawerQueryParams } from 'ducks/drawers/selectors'
import { doOpenSearchPayload } from './doOpenSearchPayload'

export const doOpenSearchPage = (term, isTypedSearch, options = {}) => (
  dispatch,
  getState
) => {
  const currentQuery = selectDrawerQueryParams(getState())
  return dispatch(
    doOpenSearchPayload(term, isTypedSearch, currentQuery, options)
  )
}
