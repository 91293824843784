export const basicFields = `
  nodes {
    __typename
    ... on Integration {
      id
      legacyId
      provider
      settings {
        settings
      }
    }

    ... on UserIntegration {
      agent {
        id
      }
    }

    ... on ChannelIntegration {
      channel {
        id
      }
    }

    ... on ShopifyV2Integration {
      storeName
      storeDomain
    }

    ... on ShopifyIntegration {
      storeName
      storeDomain
    }

    ... on SalesforceIntegration {
      salesforceDomain
    }

    ... on JiraServerIntegration {
      username
      url
    }

    ... on RechargeIntegration {
      storeName
      storeDomain
      fullStoreDomain
    }
  }
`
