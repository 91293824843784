import { doTogglePrintMode } from 'actions/app'
import { handlePrintClick } from 'components/App/DesktopView/Layout/TicketInspector/Changesets/ExpandedMessage/util'
import { doFetchAllChangesetsTicketActions } from './doFetchAllChangesetsTicketActions'

export const doPrintWholeTicket = ticketId => dispatch => {
  dispatch(doFetchAllChangesetsTicketActions(ticketId)).then(() => {
    dispatch(doTogglePrintMode(true))
    setTimeout(() => {
      handlePrintClick(null, document.getElementById('printableWholeTicket'))
      dispatch(doTogglePrintMode(false))
    }, 500)
  })
}
