import * as types from 'constants/action_types'
import { subscribeUserToPush } from 'util/webPush/subscriptions'

export const doSubscribeUserToPush = vapidPublicKey => dispatch => {
  dispatch({
    type: types.WEB_PUSH_SUBSCRIPTION_CREATE,
    data: { vapidPublicKey },
  })

  return subscribeUserToPush(vapidPublicKey)
    .then(subscription => {
      dispatch({
        type: types.WEB_PUSH_SUBSCRIPTION_CREATED,
        data: { subscription },
      })
      return subscription
    })
    .catch(err => {
      dispatch({
        type: types.WEB_PUSH_SUBSCRIPTION_CREATE_FAIL,
        data: { err },
      })
    })
}
