import { doUploadFiles } from 'actions/attachments'
import {
  doOpenTicketPage,
  doOpenNotePage,
  doOpenNewTicketPage,
  doOpenNewConversationPage,
} from 'actions/pages'
import {
  selectIsAddingNote,
  selectIsReplying,
  selectIsForwarding,
  selectIsCreatingNewEmail,
  selectIsLoggingNewConversation,
} from 'selectors/page'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'

export function doDropFiles(accepted, rejected, targetType) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketId = selectCurrentTicketId(state)
    if (!ticketId || accepted.length === 0) {
      return
    }
    const isAddingNote = selectIsAddingNote(state)
    const isReplying = selectIsReplying(state)
    const isForwarding = selectIsForwarding(state)
    const isCreatingNewEmail = selectIsCreatingNewEmail(state)
    const isLoggingNewConversation = selectIsLoggingNewConversation(state)
    // NOTE (jscheel): Have to use discreet dispatches here as there's a problem
    // with batchActions & changing the location. Will investigate later.
    if (targetType === 'reply' && !isReplying && !isForwarding) {
      dispatch(doOpenTicketPage(ticketId))
    } else if (targetType === 'note' && !isAddingNote) {
      dispatch(doOpenNotePage(ticketId))
    } else if (targetType === 'newEmail' && !isCreatingNewEmail) {
      dispatch(doOpenNewTicketPage())
    } else if (targetType === 'newConversation' && !isLoggingNewConversation) {
      dispatch(doOpenNewConversationPage())
    }
    const forNote = targetType === 'note' || targetType === 'newConversation'
    dispatch(doUploadFiles(accepted, forNote))
  }
}
