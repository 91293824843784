import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="m8.8535176.52350393 7.0119039 13.09537317c.1833128.342456.2201422.7530913-.1519311 1.1135008-.340421.3297496-.7117944.3136337-1.0473573.1713894l-4.5098982-1.912199c-.4428001-.1877474-.6483408-.6960521-.4590881-1.1353301.1892528-.4392781.7016328-.643184 1.1444329-.4554365l2.4083492 1.0211412-5.2499097-9.8046874-5.24990407 9.8046768 4.51588487-1.9149323.7340138-4.93171612.7678614 5.15145462c.0122262.0226015.0235493.0459334.0339048.0699701.1892522.4392783-.0162892.9475827-.4590895 1.1353295l-7.00888229 2.9717591c-.33552203.1422269-.70692047.1583443-1.04734993-.1714835-.37209434-.3605066-.33515969-.7711694-.15179072-1.1135314l7.01190094-13.09536743c.3737957-.69790134 1.3331056-.69790134 1.7069491.00008906z"
      />
    </svg>
  )
})
