import { doShowSnackbar } from 'actions/snackbar'
import { deletePayload } from 'optimistic/delete'
import {
  selectCurrentFolderId,
  selectCurrentTicketListRouteAction,
} from 'selectors/app'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { batchActions } from 'util/redux'
import { doSendChangeset } from '../changeset/doSendChangeset'
import { doHardDeleteTickets } from './doHardDeleteTickets'

export function doDeleteTicket(
  ticketId,
  { suppressSnackbar, disableNavigateToFolder, deleteMode = 'SOFT' } = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const optimisticData = deletePayload(
      state,
      ticketId,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )
    const shortTicketTitle = ticket =>
      ticket && ticket.title && !!ticket.title.length
        ? `${ticket.title.substring(0, 10)}... `
        : ''
    const ticket = selectTicketsById(state)[ticketId]
    const actions = []

    if (deleteMode === 'HARD') {
      const routeAction = selectCurrentTicketListRouteAction(state)
      const currentTicketId = selectCurrentTicketId(state)
      actions.push(doHardDeleteTickets([ticketId]))
      if (currentTicketId === ticketId) {
        // NOTE (jscheel): Because we are permanantly deleting the ticket, we
        // cannot remain on the ticket page.
        dispatch(routeAction)
      }
    } else {
      actions.push(
        doSendChangeset(
          ticketId,
          { delete: true, deleteMode },
          { optimisticData, disableNavigateToFolder }
        )
      )
    }

    if (!suppressSnackbar) {
      const deleteActionString =
        deleteMode === 'hard' ? 'deleted forever' : 'moved to Trash'
      actions.push(
        doShowSnackbar(
          `Conversation ${shortTicketTitle(ticket)} ${deleteActionString}`
        )
      )
    }
    return dispatch(batchActions(actions))
  }
}
