import * as types from 'constants/action_types'
import { isMobile } from 'util/media_query'

export function doShowSnackbar(
  message,
  { duration = 3000, link, linkText, mobileMessage } = {}
) {
  return {
    type: types.SHOW_SNACKBAR,
    data: {
      id: Date.now(),
      message: mobileMessage && isMobile() ? mobileMessage : message,
      duration,
      link,
      linkText,
    },
  }
}

export function doHideSnackbar() {
  return {
    type: types.HIDE_SNACKBAR,
  }
}
