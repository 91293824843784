import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ position: 'relative', top: '-2px' }}
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="M20.2 8.8l-6.2-6c-1.1-1-2.8-1-3.9 0l-6.2 6c-.6.5-.9 1.2-.9 1.9v8.6C3 20.8 4.3 22 5.8 22h12.4c1.5 0 2.8-1.2 2.8-2.7v-8.6c0-.7-.3-1.4-.8-1.9zm-8.8-4.6c.3-.3.8-.3 1.1 0L18 9.5l-5.5 5.3c-.3.3-.8.3-1.1 0L6 9.5l5.4-5.3zM19 19.3c0 .4-.4.7-.8.7H5.8c-.4 0-.8-.3-.8-.7v-7.9l4.1 3.9-1.7 1.6c-.4.4-.4 1 0 1.4.2.2.4.3.7.3.3 0 .5-.1.7-.3l1.8-1.7c.9.5 2 .5 2.9 0l1.8 1.7c.2.2.4.3.7.3.3 0 .5-.1.7-.3.4-.4.4-1 0-1.4L15 15.2l4-3.9v8z"
      />
    </svg>
  )
})
