import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...rest}
      ref={forwardedRef}
    >
      <defs>
        <path
          d="M10.2222222,17.4 C10.713142,17.4 11.1111111,16.9970563 11.1111111,16.5 L11.1111111,11.1 C11.1111111,10.6029437 10.713142,10.2 10.2222222,10.2 C9.73130244,10.2 9.33333333,10.6029437 9.33333333,11.1 L9.33333333,16.5 C9.33333333,16.9970563 9.73130244,17.4 10.2222222,17.4 Z M19.1111111,6.6 L15.5555556,6.6 L15.5555556,5.7 C15.5555556,4.20883118 14.3616482,3 12.8888889,3 L11.1111111,3 C9.63835178,3 8.44444444,4.20883118 8.44444444,5.7 L8.44444444,6.6 L4.88888889,6.6 C4.39796911,6.6 4,7.00294373 4,7.5 C4,7.99705627 4.39796911,8.4 4.88888889,8.4 L5.77777778,8.4 L5.77777778,18.3 C5.77777778,19.7911688 6.97168511,21 8.44444444,21 L15.5555556,21 C17.0283149,21 18.2222222,19.7911688 18.2222222,18.3 L18.2222222,8.4 L19.1111111,8.4 C19.6020309,8.4 20,7.99705627 20,7.5 C20,7.00294373 19.6020309,6.6 19.1111111,6.6 Z M10.2222222,5.7 C10.2222222,5.20294373 10.6201913,4.8 11.1111111,4.8 L12.8888889,4.8 C13.3798087,4.8 13.7777778,5.20294373 13.7777778,5.7 L13.7777778,6.6 L10.2222222,6.6 L10.2222222,5.7 Z M16.4444444,18.3 C16.4444444,18.7970563 16.0464753,19.2 15.5555556,19.2 L8.44444444,19.2 C7.95352467,19.2 7.55555556,18.7970563 7.55555556,18.3 L7.55555556,8.4 L16.4444444,8.4 L16.4444444,18.3 Z M13.7777778,17.4 C14.2686976,17.4 14.6666667,16.9970563 14.6666667,16.5 L14.6666667,11.1 C14.6666667,10.6029437 14.2686976,10.2 13.7777778,10.2 C13.286858,10.2 12.8888889,10.6029437 12.8888889,11.1 L12.8888889,16.5 C12.8888889,16.9970563 13.286858,17.4 13.7777778,17.4 Z"
          id="delete-path-1"
        />
      </defs>
      <g id="new-icons/delete-24" fill="none" fillRule="evenodd">
        <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        <mask id="delete-mask-2" fill="#fff" className="fill">
          <use xlinkHref="#delete-path-1" />
        </mask>
        <use
          className="fill"
          id="Shape"
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#delete-path-1"
        />
        <g
          className="fill"
          id="Colors/@7E8F9F"
          mask="url(#delete-mask-2)"
          fill="var(--color-monochrome-mediumDark)"
        >
          <rect id="Rectangle" width="24" height="24" />
        </g>
      </g>
    </svg>
  )
})
