import { isWebPushSupported } from 'util/browser'
import { isGranted, isDenied, askPermission } from 'util/webPush/permissions'
import { logUnsupported } from './logUnsupported'

export const doAskPushNotificationPermission = () => {
  // the app shouldnt even be triggering this function if web push not
  // supported, but we check if push is supported just in case
  if (!isWebPushSupported()) {
    logUnsupported()
    return Promise.reject(new Error('unsupported'))
  }
  if (isDenied()) return Promise.reject(new Error('denied'))
  if (isGranted()) return Promise.resolve() // already granted

  // At this point the Notification Permission  must be 'default' (i.e.
  // unknown) so ask user
  return askPermission()
}
