import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      {...rest}
      ref={forwardedRef}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="var(--color-monochrome-mediumDark)"
        d="M431.414955,614.446356 C434.117616,616.517881 437.891728,616.517881 440.594389,614.446356 L441.916453,613.127161 C442.945022,611.828827 443.503125,610.225541 443.501079,608.57501 C443.509465,605.320253 441.379455,602.438728 438.245058,601.464585 C435.110662,600.490441 431.701186,601.650341 429.831864,604.326744 C427.962541,607.003146 428.068324,610.573311 430.092891,613.136452 L431.414955,614.446356 Z M436.000025,614.129648 C432.89298,614.129648 430.374222,611.634055 430.374222,608.555585 C430.374222,605.477115 432.89298,602.981522 436.000025,602.981522 C439.10707,602.981522 441.625827,605.477115 441.625827,608.555585 C441.625827,610.033918 441.03311,611.451703 439.978068,612.497042 C438.923025,613.542382 437.49208,614.129648 436.000025,614.129648 Z M436.134801,604.375038 C435.691396,604.375038 435.331944,604.672023 435.331944,605.038372 L435.331944,608.30861 L434.23203,609.880713 C434.010327,610.199437 434.143324,610.606306 434.529087,610.789481 C434.91485,610.972655 435.407298,610.862771 435.629001,610.544047 L436.833287,608.819378 C436.91053,608.69947 436.941357,608.56286 436.921601,608.428011 C436.921601,608.428011 436.937659,608.355044 436.937659,608.355044 L436.937659,605.038372 C436.937659,604.672023 436.578207,604.375038 436.134801,604.375038 Z M429.540359,603.029478 L432.413442,600.64086 C432.810124,600.311052 432.861867,599.725073 432.529012,599.332026 C432.196143,598.938992 431.604726,598.887725 431.208036,599.217526 L428.334954,601.606144 C427.938271,601.935951 427.886529,602.52193 428.219383,602.914977 C428.552252,603.308012 429.14367,603.359278 429.540359,603.029478 Z M442.459641,603.029323 L439.586558,600.640705 C439.189876,600.310897 439.138133,599.724918 439.470988,599.331871 C439.803857,598.938837 440.395274,598.88757 440.791964,599.21737 L443.665046,601.605988 C444.061729,601.935796 444.113471,602.521775 443.780617,602.914822 C443.447748,603.307856 442.85633,603.359123 442.459641,603.029323 Z"
        transform="translate(-428 -599)"
      />
    </svg>
  )
})
