import * as modals from 'constants/modal_types'
import { SAVE_LAST_SNOOZED_DATE } from 'constants/action_types'

import { doShowSnackbar } from 'actions/snackbar'
import { doHideModal } from 'actions/modals'
import { doAutoRedirect } from 'actions/autoRedirect'

import {
  snoozePayload,
  bulkSnoozePayload,
  bulkUnsnoozePayload,
  unSnoozePayload,
  unSnoozeAndClosePayload,
} from 'optimistic/snooze'

import {
  selectCurrentFolderId,
  selectCurrentTicketListRouteAction,
} from 'selectors/app'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { selectPrefersOpenFolderOnTicketSave } from 'ducks/currentUser/selectors/preferences/selectPrefersOpenFolderOnTicketSave'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectNextTicketIdInList } from 'selectors/ticket_list'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'

import { runOnNextTick } from 'util/functions'
import { batchActions } from 'util/redux'
import { pluralize } from 'util/strings'

import { doSendBulkChangeset } from './changeset'
import { doSendChangeset } from './changeset/doSendChangeset'

const saveLastSnoozedDate = lastSnoozedDate => {
  return {
    type: SAVE_LAST_SNOOZED_DATE,
    data: {
      lastSnoozedDate,
    },
  }
}

export const doSnoozeTicket = (snoozeOption, ticketId) => {
  return (dispatch, getState) => {
    const state = getState()
    const nextTicketId = selectNextTicketIdInList(state) || null
    const actions = [
      doSnoozeSingleTicket(ticketId, snoozeOption, {
        disableNavigateToFolder: true,
      }),
      doShowSnackbar('Conversation snoozed'),
      doHideModal(modals.SNOOZING),
    ]

    runOnNextTick(() => {
      const classicView = selectPrefersClassicView(state)
      if (classicView) {
        const shouldOpenFolder = selectPrefersOpenFolderOnTicketSave(state)
        const routeAction = selectCurrentTicketListRouteAction(state)
        if (shouldOpenFolder && routeAction) {
          dispatch(routeAction)
        }
      } else if (nextTicketId) {
        dispatch(doAutoRedirect(ticketId, nextTicketId))
      }
    })

    return dispatch(batchActions(actions))
  }
}

export const doSnoozeSelectedTickets = snoozeOption => (dispatch, getState) => {
  const state = getState()
  const ticketIds = selectSelectedTicketIds(state)
  return dispatch(doSnoozeTickets(snoozeOption, ticketIds))
}

export function doSnoozeTickets(snoozeOption, ticketIds) {
  return (dispatch, getState) => {
    const state = getState()
    const optimisticData = bulkSnoozePayload(
      state,
      ticketIds,
      snoozeOption,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )

    const inputs = ticketIds.map(id => {
      return { ticketId: id, snoozeUntil: snoozeOption }
    })

    dispatch(saveLastSnoozedDate(snoozeOption))

    const message = `${pluralize(ticketIds.length, app.t('Ticket'))} snoozed`

    return dispatch(
      doSendBulkChangeset(inputs, { optimisticData, snackbar: { message } })
    )
  }
}

export function doUnsnoozeTickets(ticketIds) {
  return (dispatch, getState) => {
    const state = getState()
    const optimisticData = bulkUnsnoozePayload(
      state,
      ticketIds,
      selectLatestTicketSearchQueryObject(state)
    )

    const inputs = ticketIds.map(id => {
      return { ticketId: id, removeSnooze: true }
    })

    const message = `${pluralize(ticketIds.length, app.t('Ticket'))} un-snoozed`

    return dispatch(
      doSendBulkChangeset(inputs, { optimisticData, snackbar: { message } })
    )
  }
}

export function doSnoozeSingleTicket(
  ticketId,
  snoozeOption,
  { disableNavigateToFolder }
) {
  return (dispatch, getState) => {
    const state = getState()

    const optimisticData = snoozePayload(
      state,
      ticketId,
      snoozeOption,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )

    const data = { ticketId, snoozeUntil: snoozeOption }

    dispatch(saveLastSnoozedDate(snoozeOption))

    return dispatch(
      doSendChangeset(ticketId, data, {
        optimisticData,
        disableNavigateToFolder,
      })
    ).catch(err => {
      // eslint-disable-next-line no-console
      console.error('snooze ticket failed', { err })
      dispatch(doShowSnackbar("Oops, we couldn't snooze your conversation."))
    })
  }
}

const doUnsnoozeTicket = (ticketId, ticketData, payloadFn) => {
  return (dispatch, getState) => {
    const state = getState()

    const optimisticData = payloadFn(
      state,
      ticketId,
      selectLatestTicketSearchQueryObject(state)
    )

    const data = { ticketId, ...ticketData }

    return dispatch(doSendChangeset(ticketId, data, { optimisticData })).catch(
      err => {
        // eslint-disable-next-line no-console
        console.error('unsnooze ticket failed', { err })
        dispatch(
          doShowSnackbar("Oops, we couldn't un-snooze your conversation.")
        )
      }
    )
  }
}

export const doUnsnoozeAndOpen = ticketId => {
  // eslint-disable-next-line no-param-reassign
  if (Array.isArray(ticketId) && ticketId.length === 1) ticketId = ticketId[0]

  return doUnsnoozeTicket(ticketId, { state: 'opened' }, unSnoozePayload)
}

export const doUnsnoozeAndUnread = ticketId => {
  const id =
    Array.isArray(ticketId) && ticketId.length > 0 ? ticketId[0] : ticketId

  return doUnsnoozeTicket(id, { state: 'unread' }, unSnoozePayload)
}

export const doUnsnoozeAndKeepClosed = ticketId => {
  // eslint-disable-next-line no-param-reassign
  if (Array.isArray(ticketId) && ticketId.length === 1) ticketId = ticketId[0]

  return doUnsnoozeTicket(
    ticketId,
    { removeSnooze: true },
    unSnoozeAndClosePayload
  )
}
