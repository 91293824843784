import { LABEL_SELECTION_CLICK } from 'constants/action_types'
import {
  selectActiveLabelIndex,
  selectCurrentSelectableLabels,
} from 'selectors/labelings'
import { selectCurrentOrSelectedTickets } from 'selectors/ticket_list/current'

import { doUpdateActiveLabel } from './doUpdateActiveLabel'

export const doHandleLabelSelection = (label, labelSelection, opts = {}) => {
  return (dispatch, getState) => {
    const {
      isSearchResult = false,
      dontRemove = false,
      applyAndClose = false,
    } = opts
    let state = getState()
    const previousIndex = selectActiveLabelIndex(state)

    const ticketIds = selectCurrentOrSelectedTickets(state).map(t => t.id)

    dispatch({
      type: LABEL_SELECTION_CLICK,
      data: {
        label,
        labelSelection,
        isSearchResult,
        dontRemove,
        applyAndClose,
        ticketIds,
      },
    })

    // We dispatch a second event after the LABEL_SELECTION_CLICK so that the
    // current selectable labels is how it will be rather than it was.
    state = getState()
    const labels = selectCurrentSelectableLabels(state)
    const newActiveIndex =
      labels.findIndex(potentialLabel => {
        return potentialLabel && label && label.id === potentialLabel.id
      }) + 1
    dispatch(doUpdateActiveLabel((newActiveIndex || previousIndex || 1) - 1))
  }
}
