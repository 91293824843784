import { batchActions } from 'util/redux'

import {
  createMailbox,
  destroyMailbox,
  fetchMailbox,
  convertMailboxToDemo,
} from 'ducks/mailboxes/api'
import * as types from 'constants/action_types'

import { doClearFetchedStatuses } from 'actions/app'
import { doMarkFetchingStatus } from 'actions/app/doMarkFetchingStatus'
import { doShowSnackbar } from 'actions/snackbar'
import { doOpenMainPage } from 'actions/pages'
import { doFetchTicketSearchCountsForPinnedSearches } from 'actions/search/doFetchTicketSearchCountsForPinnedSearches'
import { doFetchTicketAggregationCountsForUnloadedNavigationSections } from 'actions/search/doFetchTicketAggregationCountsForUnloadedNavigationSections'

import {
  oauthTokenSelector,
  fetchingStatusesSelector,
} from 'selectors/app/base'
import { selectCurrentMailbox } from 'selectors/mailboxes/selectCurrentMailbox'
import { selectMailboxes } from 'selectors/mailboxes/selectMailboxes'
import { selectMailboxesById } from 'selectors/mailboxes/selectMailboxesById'
import { selectFolders } from 'ducks/folders/selectors/folders'
import {
  doFetchMailboxes,
  doUpdateMailboxLocally,
} from 'ducks/mailboxes/actions'
import {
  DESTROY_MAILBOX_FAILED,
  DESTROY_MAILBOX_STARTED,
  DESTROY_MAILBOX_SUCCESS,
} from 'ducks/mailboxes/actionTypes'
import { selectCurrentAgents } from 'ducks/agents/selectors'
import { selectCurrentUser } from 'ducks/currentUser/selectors/base'
import { changeEntity } from 'ducks/entities/actionUtils'

import debug from 'util/debug'
import { isEmpty } from 'util/objects'
import { selectIsInInbox } from 'selectors/location'
import { selectPreviousDrawer } from 'ducks/drawers/selectors'
import { selectChannelEntitiesToAddAfterMailboxCreation } from 'ducks/channels/selectors'

export function doFetchMailbox(
  id,
  { skipLoaded = true, shouldRebuildMenu = false } = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const fetchingStatuses = fetchingStatusesSelector(state)
    const fetchingStatusKey = 'fetchMailbox'
    if (skipLoaded && fetchingStatuses[fetchingStatusKey]) return
    dispatch(doMarkFetchingStatus(fetchingStatusKey, true))
    const token = oauthTokenSelector(state)
    // eslint-disable-next-line consistent-return
    return fetchMailbox(token, id).then(mailbox => {
      const actions = [doMarkFetchingStatus(fetchingStatusKey, false)]
      if (mailbox) {
        actions.unshift(doUpdateMailboxLocally(mailbox, { shouldRebuildMenu }))
      }

      dispatch(batchActions(actions))
      return mailbox
    })
  }
}

export function doCreateMailbox(mailbox, responseFields) {
  return (dispatch, getState) => {
    dispatch({ type: types.CREATE_MAILBOX_REQUEST, payload: { mailbox } })
    const state = getState()
    const token = oauthTokenSelector(state)
    return createMailbox(token, mailbox, responseFields)
      .then(newMailbox => {
        dispatch({
          type: types.CREATE_MAILBOX_SUCCESS,
          payload: { mailbox: newMailbox },
          ...selectChannelEntitiesToAddAfterMailboxCreation(
            getState(),
            newMailbox
          ),
        })
        return newMailbox
      })
      .catch(error => {
        debug(error)
        dispatch({
          type: types.CREATE_MAILBOX_FAILURE,
          payload: {
            error: `Unable to create ${app.t('mailbox')}, please try again`,
          },
        })
        throw error
      })
  }
}

export function doDestroyMailbox(id) {
  return (dispatch, getState) => {
    dispatch({ type: DESTROY_MAILBOX_STARTED, payload: { id } })
    const state = getState()
    const token = oauthTokenSelector(state)
    const mailboxes = (selectMailboxes(state) || []).filter(m => m.id !== id)
    const folders = selectFolders(state)
    const agents = selectCurrentAgents(state)
    const currentUser = selectCurrentUser(state)

    return destroyMailbox(token, id)
      .then(() => {
        dispatch({
          type: DESTROY_MAILBOX_SUCCESS,
          payload: {
            id,
            leftNavPayload: { folders, mailboxes, agents, currentUser },
          },
          ...changeEntity('channel', id, {}, 'remove'),
        })
      })
      .catch(error => {
        debug(error)
        dispatch({
          type: DESTROY_MAILBOX_FAILED,
          payload: {
            error: `Unable to delete ${app.t('mailbox')}, please try again`,
          },
        })
      })
  }
}

export function doConvertMailboxToDemo(id) {
  return (dispatch, getState) => {
    dispatch({ type: types.CONVERT_MAILBOX_TO_DEMO_REQUEST, payload: { id } })
    const state = getState()
    const token = oauthTokenSelector(state)
    return convertMailboxToDemo(token, id)
      .then(newMailbox => {
        dispatch({
          type: types.CONVERT_MAILBOX_TO_DEMO_SUCCESS,
          payload: { mailbox: newMailbox },
        })
        return newMailbox
      })
      .catch(error => {
        debug(error)
        dispatch({
          type: types.CONVERT_MAILBOX_TO_DEMO_FAILURE,
          payload: {
            error: `Unable to convert ${app.t(
              'mailbox'
            )} to demo, please try again`,
          },
        })
        throw error
      })
  }
}

export function doRemoveMailboxLocally(id) {
  return (dispatch, getState) => {
    const state = getState()
    const mailboxes = (selectMailboxes(state) || []).filter(m => m.id !== id)
    const folders = selectFolders(state)
    const agents = selectCurrentAgents(state)
    const currentUser = selectCurrentUser(state)
    const currentMailbox = selectCurrentMailbox(state)
    const isInInbox = selectIsInInbox(state)
    const isInDrawer = !!selectPreviousDrawer(state)

    const actions = [
      {
        type: types.REMOVE_MAILBOX_LOCALLY,
        data: {
          id,
          leftNavPayload: { folders, mailboxes, agents, currentUser },
        },
        ...changeEntity('channel', id, {}, 'remove'),
      },
    ]
    if (currentMailbox?.id === id && !isInDrawer) {
      // We don't want to show the snackbar or redirect to the main page
      // if the demo mailbox is deleted during the mailbox creation
      actions.unshift(doShowSnackbar(`Current ${app.t('mailbox')} deleted`))
      if (isInInbox) {
        dispatch(doOpenMainPage())
      }
    }
    return dispatch(batchActions(actions))
  }
}

export function doCurrentMailboxChanged(mailboxId) {
  return batchActions(
    doClearFetchedStatuses(/ticketAggregationCounts-/),
    doFetchTicketSearchCountsForPinnedSearches({ mailbox: mailboxId }),
    doFetchTicketAggregationCountsForUnloadedNavigationSections({
      mailbox: mailboxId,
    })
  )
}

// Conditionally fetches mailboxes, or returns those already in store.
export function doLoadMailboxes() {
  return (dispatch, getState) => {
    const state = getState()
    const mailboxes = selectMailboxesById(state)
    if (!mailboxes || isEmpty(mailboxes)) return dispatch(doFetchMailboxes())
    return Promise.resolve(mailboxes)
  }
}

export function doRememberPreviousMailbox(mailboxId) {
  return {
    type: types.REMEMBER_PREVIOUS_MAILBOX,
    data: { mailboxId },
  }
}
