import { allAgentsQuery } from 'api/agents'
import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'
import { AGENT_DATA_TABLE_QUERY_ID } from 'ducks/agents/constants'
import {
  createFetchAgentsRequestAction,
  createFetchAgentsSuccessAction,
  createFetchAgentsFailAction,
} from './createFetchAgents'

export default function doFetchAllAgents() {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(createFetchAgentsRequestAction())

    return graphql(oauthTokenSelector(state), allAgentsQuery())
      .then(res => {
        // eslint-disable-next-line no-param-reassign
        res.json.data.fetchedAll = true
        dispatch(
          createFetchAgentsSuccessAction(res, {
            queryId: AGENT_DATA_TABLE_QUERY_ID,
          })
        )
      })
      .catch(err => {
        dispatch(createFetchAgentsFailAction(err))
        throw err
      })
  }
}
