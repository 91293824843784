export const basicFields = `
  archived
  avatar_url
  chat_allow_round_robin
  created_at
  email
  first_name
  hasAcceptedInvitation
  id
  gid
  include_in_analytics
  last_name
  name
  role
  username
  chatId
  status2fa
  visible_in_widget
  invitationAcceptedAt
`

export const query = agentId => `
  query AgentQuery {
    agent(id: "${agentId}") {
      ${basicFields}
    }
  }
`
export const agentsQuery = () => `
  query AgentsQuery {
    agents(includeArchived: true) {
      ${basicFields}
    }
  }
`

export const allAgentsQuery = () => `
  query AgentsQuery {
    agents(includeAll: true) {
      ${basicFields}
    }
  }
`
