import { REALTIME_NOTE_UPDATE } from 'constants/action_types'

export default function doRealtimeNoteEvent(message) {
  const {
    data: { id: noteId, body: noteText },
  } = message
  return {
    type: REALTIME_NOTE_UPDATE,
    data: { noteId, noteText },
  }
}
