import { MARK_FETCHING_STATUS } from 'constants/action_types'

/* @joelserino - 12/19/17
 ** This needs to be moved to a ticketing init() -
 ** probably somewhere in ./components/App/DesktopVioew/Layout/index.jsx
 */

export function doMarkFetchingStatus(action, newStatus) {
  return {
    type: MARK_FETCHING_STATUS,
    data: {
      action,
      status: newStatus,
    },
  }
}
