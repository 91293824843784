import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        strokeWidth="1"
        fill="currentColor"
        d="M13.054 8.966v-2.55c-.003-2.346-1.78-4.35-4.21-4.74V.802C8.843.36 8.465 0 8 0c-.464 0-.84.36-.84.802v.874c-2.43.39-4.21 2.394-4.212 4.74v2.55c-1.007.34-1.68 1.245-1.684 2.262v1.603c0 .444.377.804.842.804H4.75C5.15 15.032 6.48 16 8 16s2.853-.968 3.25-2.366h2.646c.465 0 .842-.36.842-.803V11.23c-.004-1.017-.677-1.923-1.684-2.262zm-8.42-2.55C4.633 4.645 6.14 3.21 8 3.21c1.862 0 3.37 1.436 3.37 3.21V8.82H4.632V6.417zM8 14.437c-.597-.004-1.15-.31-1.447-.802H9.45c-.3.492-.85.798-1.45.8zm5.054-2.407H2.948v-.802c0-.443.378-.803.842-.803h8.42c.466 0 .844.36.844.803v.8z"
      />
    </svg>
  )
})
