import { v4 as uuidV4 } from 'uuid'
import { redirect } from 'redux-first-router'

import { customerPartial } from 'ducks/customers/api'
import graphql from 'api/graphql'
import {
  actionPartials,
  actorPartials,
  filterableFieldsPartials,
} from 'api/tickets'

import * as types from 'constants/action_types'
import * as modals from 'constants/modal_types'

import { doUpdateAgentMention, doUpdateMentions } from 'actions/app'
import { doMarkFetchingStatus } from 'actions/app/doMarkFetchingStatus'
import { doHideModal } from 'actions/modals'
import { doOpenNotePage, doOpenTicketPage } from 'actions/pages'
import { doShowSnackbar } from 'actions/snackbar'

import { starPayload } from 'optimistic/star'
import { assignmentPayload, bulkAssignmentPayload } from 'optimistic/assign'
import { mailboxPayload, bulkMailboxPayload } from 'optimistic/mailbox'
import { selectMailbox } from 'selectors/mailboxes/selectMailbox'
import { pluralize } from 'util/strings'

import {
  oauthTokenSelector,
  selectCurrentFolderId,
  isAgentInGroup,
  selectGroupsById,
  fetchingStatusesSelector,
} from 'selectors/app'
import { selectAgentsById } from 'selectors/agents/base'
import {
  selectCurrentRawChangesetsForTicket,
  selectIsChangesetFetched,
} from 'selectors/currentChangesets'
import { selectCustomersById } from 'ducks/customers/selectors'
import { selectInaccessibleMailbox } from 'selectors/mailboxes'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectAgentMention } from 'selectors/mentions'
import { selectIsAddingNote } from 'selectors/page'
import { selectCurrentRawEmailId } from 'selectors/raw_emails'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectTicketSorting } from 'selectors/sorting'
import { selectCurrentTicketIsStarred } from 'selectors/tickets'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { selectAutoAdvanceToNextTicketInList } from 'ducks/currentUser/selectors/preferences/selectAutoAdvanceToNextTicketInList'
import { selectShouldUseSpeedyGroove } from 'ducks/currentUser/selectors/preferences/selectShouldUseSpeedyGroove'

import {
  currentFolderTicketsSelector,
  selectNextTicketIdInList,
  selectFirstTicketIdInList,
  selectCurrentOrSelectedTickets,
} from 'selectors/ticket_list'
import { selectSelectedTicketIds } from 'selectors/ticket_list/base'

import { getLength, all, isEmpty, chunk } from 'util/arrays'
import debug from 'util/debug'
import { runOnNextTick } from 'util/functions'
import { batchActions } from 'util/redux'
import { isStarred } from 'util/ticketState'

import editor from 'shared/editor/utils'

import { doSendBulkChangeset } from './changeset'
import { doSendChangeset } from './changeset/doSendChangeset'
import { doFetchTicket } from './doFetchTicket'
import { doFetchTicketMergers } from './doFetchTicketMergers'

export * from './bulkSelection'
export * from './bulkSelectionMode'
export * from './changeset'
export * from './linkedResources'
export * from './print'
export * from './snooze'
export * from './state'
export * from './actions'

export function doFetchCurrentFolderWithTickets() {
  return (dispatch, getState) => {
    const state = getState()
    const folderId = selectCurrentFolderId(state)
    const mailboxId = selectCurrentMailboxId(state)

    if (!folderId) return Promise.resolve(null)

    dispatch({
      type: types.FETCH_FOLDER_WITH_TICKETS_REQUEST,
      data: { folderId, mailboxId },
    })

    const ticketSorting = selectTicketSorting(state)
    const [sortBy, sortOrder] = ticketSorting.split(':')

    let mailboxIdParam = ''
    let mailboxIdParamWithParentheses = '' // eslint-disable-line no-unused-vars
    if (mailboxId) {
      mailboxIdParam = `mailboxId: "${mailboxId}",`
      mailboxIdParamWithParentheses = `(${mailboxIdParam})`
    }

    const query = `
      query FolderQuery {
        folder(id: "${folderId}") {
          id
          name
          tickets(${mailboxIdParam} sortBy: "${sortBy}", sortOrder: "${sortOrder}") {
            metadata {
              sortBy
              sortOrder
              currentPage
              totalPages
              totalCount
            }
            records {
              id
              type
              title
              state
              ${filterableFieldsPartials}
              body
              priority
              message_count
              attachment_count
              labels {
                id
                name
                color
              }
              system_updated_at
              deleted_at
              snoozedUntil
              mailboxId
              ${customerPartial}
              assignee {
                id
                name
                email
                avatar_url
              }
            }
          }
        }
      }
    `
    const token = oauthTokenSelector(state)

    return (
      graphql(token, query)
        .then(res => {
          const data = res.json.data
          dispatch({
            type: types.FETCH_FOLDER_WITH_TICKETS_SUCCESS,
            data: { folder: data.folder, folderId, mailboxId },
          })
        })
        // dont catch errors - let it bubble up as a hard failure
        .then(() =>
          dispatch({ type: types.FETCH_FOLDER_WITH_TICKETS_COMPLETE })
        )
    )
  }
}

export function doPreloadNextTicket() {
  return (dispatch, getState) => {
    const state = getState()
    const isViewingTicket = !!selectCurrentTicketId(state)
    let ticketId
    if (isViewingTicket) {
      ticketId = selectNextTicketIdInList(state)
    } else {
      ticketId = selectFirstTicketIdInList(state)
    }

    if (ticketId) return dispatch(doPreloadTicket(ticketId))

    return false
  }
}

export function doPreloadTicket(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const byId = selectTicketsById(state)
    const ticket = byId[ticketId]
    if (!ticket || !ticket.full) {
      const key = `preloadTicket:${ticketId}`
      const isAlreadyFetching = fetchingStatusesSelector(state)[key] === true
      if (!isAlreadyFetching) {
        dispatch(doMarkFetchingStatus(key, true))
        dispatch(doFetchTicket(ticketId))
          .catch(() => debug('Could not prefetch ticket', ticketId))
          .finally(() => {
            dispatch(doMarkFetchingStatus(key, false))
          })
      }
    }
  }
}

export function doFetchCurrentTicket() {
  return (dispatch, getState) => {
    const state = getState()
    const ticketId = selectCurrentTicketId(state)
    if (ticketId === 'new') return false
    if (!ticketId) return false

    return dispatch(doFetchTicket(ticketId, { skipMergeFetch: true })).catch(
      err => {
        debug('doFetchCurrentTicket failed', { err })
        const redirectError = err?.errors?.find(e => e.status === 301)
        if (redirectError) {
          const parentId = redirectError.meta?.parent_id
          if (parentId && parentId !== ticketId) {
            dispatch(redirect(doOpenTicketPage(parentId)))
          } else {
            dispatch(doFetchTicketMergers(ticketId)).catch(mergerFetchErr => {
              debug(mergerFetchErr)
              return mergerFetchErr
            })
          }
        }
      }
    )
  }
}

export function doPreFetchChangesetTicketActions(ticketId, force = false) {
  return (dispatch, getState) => {
    const state = getState()
    const shouldPreload = selectShouldUseSpeedyGroove(state)
    if (!force && !shouldPreload) return
    const tickets = selectTicketsById(state)
    const ticket = tickets[ticketId]
    if (!ticket) return
    const changesets = selectCurrentRawChangesetsForTicket(state, ticketId)
    if (!changesets) return

    const filtered = changesets.filter(
      ({ id }) => id.match('collapsed') && !selectIsChangesetFetched(state, id)
    )
    let collapsedChangesets = []
    if (filtered.length > 3) {
      collapsedChangesets.push(filtered[0])
      collapsedChangesets.push(filtered[filtered.length - 2])
      collapsedChangesets.push(filtered[filtered.length - 1])
    } else {
      collapsedChangesets = filtered
    }

    const token = oauthTokenSelector(state)

    const query = `
      query TicketActionsQuery($ticketId: String, $changesetIds: [String]) {
        actions(ticketId: $ticketId, changesetIds: $changesetIds) {
          records {
            id
            created_at
            changeset
            preview
            change_type
            change {
              ${actionPartials}
            }
            actor {
              ${actorPartials}
            }
          }
        }
      }
    `

    const sets = []
    const collapsedIds = []
    collapsedChangesets.forEach(changeset => {
      if (isEmpty(changeset.actions)) return
      const action = changeset.actions[0]
      const change = action.change
      if (!change) return
      collapsedIds.push(changeset.id)
      sets.push({
        leaderId: changeset.id,
        pieceChangesetIds: change.subchangeset_ids,
      })
    })

    dispatch({
      type: types.BULK_FETCH_CHANGESET_TICKET_ACTIONS_REQUEST,
      data: { changesetIds: collapsedIds },
    })

    const chunks = chunk(sets, 3)

    const run = () => {
      if (chunks.length === 0) return // we're done
      const singleChunk = chunks.pop()
      let changesetIds = []

      singleChunk.forEach(piece => {
        changesetIds = changesetIds.concat(piece.pieceChangesetIds)
      })

      const variables = {
        ticketId,
        changesetIds,
      }

      // eslint-disable-next-line consistent-return
      return graphql(token, query, variables).then(res => {
        const data = res.json.data
        const records = data.actions.records
        const lookup = {}
        records.forEach(action => {
          const actionChangesetId = action.changeset
          lookup[actionChangesetId] = lookup[actionChangesetId] || []
          lookup[actionChangesetId].push(action)
        })
        const payloads = []
        singleChunk.forEach(piece => {
          const { leaderId, pieceChangesetIds } = piece
          let actions = []
          pieceChangesetIds.forEach(
            id => (actions = actions.concat(lookup[id]))
          )
          payloads.push({
            ticketId,
            sourceChangesetId: leaderId,
            actions,
          })
        })
        dispatch({
          type: types.BULK_FETCH_CHANGESET_TICKET_ACTIONS_SUCCESS,
          data: { ticketId, payloads },
        })

        setTimeout(run, 1000) // space out
      })
    }
    run()
  }
}

export function doFetchFirstFolderTicket() {
  return (dispatch, getState) => {
    const state = getState()
    const tickets = currentFolderTicketsSelector(state)
    if (!tickets || getLength(tickets) <= 0) return false
    const ticket = tickets[0]
    const ticketId = ticket.id
    if (ticketId === 'new') return false
    if (!ticketId) return false

    return dispatch(doFetchTicket(ticketId))
  }
}

export function doUpdateCurrentMailbox(currentMailbox) {
  return {
    type: types.UPDATE_CURRENT_MAILBOX,
    data: {
      currentMailbox,
    },
  }
}

export function doAddPageToCurrentFolder(folder, mailboxId, pageNo) {
  return {
    type: types.ADD_PAGE_TO_CURRENT_FOLDER,
    data: {
      page: pageNo,
      folder,
      mailboxId,
    },
  }
}

export function doUpdateCurrentTicket(currentTicket) {
  return {
    type: types.FETCH_TICKET_SUCCESS,
    data: {
      currentTicket,
    },
  }
}

export function doUpdateFirstMailbox(firstMailbox) {
  return {
    type: types.UPDATE_FIRST_MAILBOX,
    data: {
      firstMailbox,
    },
  }
}

export function doAssignTicketToGroupAndAgent(
  ticketId,
  groupId,
  agentId,
  options
) {
  return (dispatch, getState) => {
    const state = getState()
    const mailboxId = selectCurrentMailboxId(state)
    const currentUser = selectCurrentUser(state)
    dispatch({
      type: types.ASSIGN_TICKET_TO_AGENT,
      data: {
        ticketId,
        agentId,
        mailboxId,
        groupId,
        currentUserId: currentUser.id,
      },
    })
    const assignee = agentId ? { id: agentId } : null
    const data = {
      assigned_group_id: groupId,
      assignee,
    }
    const optimisticData = assignmentPayload(
      state,
      ticketId,
      groupId,
      agentId,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )
    const newOpts = { ...options, optimisticData }

    dispatch(doShowSnackbar('Conversation reassigned'))
    // NOTE (jscheel): This is currently broken. We never updated this to happen
    // in drafts v2. We will have a product meeting to decide if we still want
    // this behavior and then I will update if needed.
    // if (groupId) {
    //   dispatch(doAssignGroupToDraft(ticketId, groupId))
    // } else {
    //   dispatch(doAssignAgentToDraft(ticketId, agentId))
    // }
    return dispatch(doSendChangeset(ticketId, data, newOpts))
  }
}

export function doAssignToSelf(ticketId, options) {
  return (dispatch, getState) => {
    const state = getState()
    const currentUser = selectCurrentUser(state)
    const byId = selectTicketsById(state)
    const existingGroup = selectGroupsById(state)[
      byId[ticketId].assigned_group_id
    ]
    const groupId =
      existingGroup && isAgentInGroup(existingGroup, currentUser.id)
        ? existingGroup.id
        : null
    return dispatch(
      doAssignTicketToGroupAndAgent(ticketId, groupId, currentUser.id, options)
    )
  }
}

export function doToggleStarred(ticketId, newPriority) {
  return (dispatch, getState) => {
    const state = getState()
    const ticket = selectTicketsById(state)[ticketId]

    const newPriorityOrInverted =
      newPriority || (isStarred(ticket) ? 'low' : 'urgent')

    const changesetId = uuidV4()
    const optimisticData = starPayload(
      state,
      ticketId,
      newPriorityOrInverted,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state),
      changesetId
    )

    return dispatch(
      doSendChangeset(
        ticketId,
        { priority: newPriorityOrInverted },
        { optimisticData }
      )
    ).catch(err => {
      const starred = newPriorityOrInverted === 'urgent'
      const type = starred ? 'star' : 'unstar'
      // eslint-disable-next-line no-console
      console.error(`${type} ticket failed`, { err, ticketId, newPriority })
      dispatch(doShowSnackbar(`Oops, we couldn't ${type} your conversation.`))
    })
  }
}

export function doStarTicket(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const isTicketStarred = selectCurrentTicketIsStarred(state)
    if (isTicketStarred) return false
    return dispatch(doToggleStarred(ticketId, 'urgent'))
  }
}

export function doUnstarTicket(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const isTicketStarred = selectCurrentTicketIsStarred(state)
    if (!isTicketStarred) return false
    return dispatch(doToggleStarred(ticketId, 'low'))
  }
}

function doRawUpdateTickets(tickets, options) {
  return {
    type: types.UPDATE_TICKETS,
    data: {
      tickets,
      options,
    },
  }
}

// NOTE (jscheel): If you try to update a ticket with a new customer reference
// and that customer does not exist in the store, this action creator will
// attempt to fetch the missing customers so that the reference does not point
// to an undefined object.
export function doUpdateTickets(tickets, options = {}) {
  return (dispatch, getState) => {
    const state = getState()
    const ids = tickets
      .map(ticket => {
        const customer = ticket.customer
        if (customer && !selectCustomersById(state)[customer.id]) {
          return customer.id
        }
        return undefined
      })
      .filter(id => id !== undefined)
    if (ids.length > 0) {
      dispatch(doRawUpdateTickets(tickets, options))
    } else {
      dispatch(doRawUpdateTickets(tickets, options))
    }
  }
}

export function doToggleTicketsSorting() {
  return {
    type: types.TOGGLE_TICKETS_SORTING,
  }
}

export function doFetchRawEmail() {
  return (dispatch, getState) => {
    dispatch(doMarkFetchingStatus('fetchRawEmail', true))
    const state = getState()
    const commentId = selectCurrentRawEmailId(state)
    if (!commentId) return
    const query = `
      query RawEmailQuery {
        rawEmail(id: "${commentId}") {
          headers
          plain
          html
          ticket_id
        }
      }`

    const token = oauthTokenSelector(state)

    graphql(token, query)
      .then(res => {
        const data = res.json.data.rawEmail
        data.commentId = commentId
        data.retrieved = true
        dispatch(doUpdateRawEmail(data))
      })
      .catch(err => {
        debug(err)
        dispatch(doUpdateRawEmail({ commentId, retrieved: false }))
      })
      .then(() => {
        dispatch(doMarkFetchingStatus('fetchRawEmail', false))
      })
  }
}

export function doUpdateRawEmail(rawEmail) {
  return {
    type: types.UPDATE_RAW_EMAIL,
    data: {
      rawEmail,
    },
  }
}

export function doToggleShowCcBcc() {
  return {
    type: types.TOGGLE_SHOW_CC_BCC,
  }
}

export function doSetTicketAssignmentFilter(term) {
  return {
    type: types.SET_TICKET_ASSIGNMENT_FILTER,
    data: {
      term,
    },
  }
}

export function doUpdateTicketDirtyStatus(ticketId, status) {
  return {
    type: types.UPDATE_TICKET_DIRTY_STATUS,
    data: {
      ticketId,
      dirty: status,
    },
  }
}

export function doCurrentMailboxChanged(ticketId, mailboxId) {
  return (dispatch, getState) => {
    const state = getState()
    const optimisticData = mailboxPayload(
      state,
      ticketId,
      mailboxId,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )
    const actions = []
    actions.push(doSendChangeset(ticketId, { mailboxId }, { optimisticData }))
    actions.push(doHideModal(modals.MAILBOX_CHANGE))
    dispatch(batchActions(actions))
  }
}

export function doBulkChangeMailbox(ticketIds, mailboxId) {
  return (dispatch, getState) => {
    const state = getState()
    const autoAdvanceToNextTicketInList = selectAutoAdvanceToNextTicketInList(
      state
    )
    const mailbox =
      selectInaccessibleMailbox(state, mailboxId) ||
      selectMailbox(state, mailboxId)
    const { name } = mailbox || {}

    const snackbar = {
      message: `${pluralize(
        ticketIds.length,
        app.t('Ticket')
      )} moved to "${name}"`,
    }

    if (ticketIds.length === 1 && autoAdvanceToNextTicketInList) {
      snackbar.link = `/tickets/${ticketIds[0]}`
      snackbar.linkText = `Go back to ${app.t('Ticket')}`
    }

    const optimisticData = bulkMailboxPayload(
      state,
      ticketIds,
      mailboxId,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )
    const inputs = ticketIds.map(id => {
      return { ticketId: id, mailboxId, includeTicketInChangesetResponse: true }
    })
    const actions = []
    actions.push(
      doSendBulkChangeset(inputs, {
        optimisticData,
        snackbar,
      })
    )
    actions.push(doHideModal(modals.MAILBOX_CHANGE))
    // NOTE (jscheel): Commenting this out as a part of our removal of drafts
    // v1. This code is actually already broken as-is, and we need to fix it.
    // I've made a note of it and will figure out a fix once we talk about it
    // from a product perspective. Then we can update to drafts v2.
    // if (ticketIds.length === 1 && ticketIds[0] === currentTicketId) {
    //   actions.push(doUpdateDraftMailbox(mailboxId))
    // }
    dispatch(batchActions(actions))
  }
}

export function doChangeCurrentTicketMailbox(mailboxId) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketId = selectCurrentTicketId(state)

    dispatch(doBulkChangeMailbox([ticketId], mailboxId))
  }
}

export function doChangeSelectedTicketsMailbox(mailboxId) {
  return (dispatch, getState) => {
    const state = getState()
    const ticketIds = selectSelectedTicketIds(state)

    dispatch(doBulkChangeMailbox(ticketIds, mailboxId))
  }
}

export function doFetchOptimisticMergeTickets(ticketIds) {
  return (dispatch, getState) => {
    if (!ticketIds) return Promise.reject(new Error('no ticket ids supplied'))

    dispatch({
      type: types.FETCH_OPTIMISTIC_MERGE_TICKETS_REQUEST,
      data: {
        ticketIds,
      },
    })

    const state = getState()
    const queries = ticketIds.map(id => {
      return `
        ticket${id}: ticket(id: ${id}) {
          id
          message_count
          state
          actions {
            records {
              id
              created_at
              changeset
              preview
              change_type
              change {
                ${actionPartials}
              }
              actor {
                ${actorPartials}
              }
            }
          }
        }
      `
    })
    const query = `
      query TicketQuery {
        ${queries.join('\n')}
      }
    `
    const token = oauthTokenSelector(state)

    return graphql(token, query).then(res => {
      const data = res.json.data
      const tickets = {}

      Object.keys(data).forEach(key => {
        tickets[key.replace('ticket', '')] = data[key]
      })

      return dispatch({
        type: types.FETCH_OPTIMISTIC_MERGE_TICKETS_SUCCESS,
        data: {
          ticketIds,
          tickets,
        },
      })
    })
  }
}

export function doBulkAssign(ticketIds, groupId, agentId) {
  return (dispatch, getState) => {
    const mailboxId = selectCurrentMailboxId(getState())
    dispatch({
      type: types.ASSIGN_TICKETS_TO_AGENT,
      data: { ticketIds, mailboxId, groupId, agentId },
    })

    const state = getState()
    const optimisticData = bulkAssignmentPayload(
      state,
      ticketIds,
      groupId,
      agentId,
      selectCurrentFolderId(state),
      selectLatestTicketSearchQueryObject(state)
    )

    const agent = agentId ? selectAgentsById(state)[agentId] : null

    const inputs = ticketIds.map(id => {
      return {
        ticketId: id,
        assigned_group_id: groupId,
        assignee: agent,
      }
    })

    return dispatch(
      doSendBulkChangeset(inputs, {
        optimisticData,
        snackbar: { message: `${ticketIds.length} reassigned` },
      })
    ).catch(() => {
      dispatch(
        doShowSnackbar("Oops, we couldn't reassign those conversations.")
      )
    })
  }
}

const insertAgentMention = agent => {
  if (!agent) return false

  return setTimeout(() => {
    editor.fire('mention-insert', {
      mention: `@${agent.username}`,
    })

    // eslint-disable-next-line no-undef
    tinymce.activeEditor.fire('change')
  }, 50)
}

export function doMentionAgent(agentId, _editorInstance) {
  return (dispatch, getState) => {
    const state = getState()
    const agent = selectAgentsById(state)[agentId]
    if (!agent) return Promise.reject('cannot find agent')

    const agentMention = selectAgentMention(state)
    // NOTE (jscheel): If we are mentioning an agent from a partial match that
    // is typed in the editor, we use that partial match. Otherwise, we try to
    // insert the mention normally.
    if (agentMention) {
      const editorInstance = _editorInstance || editor.getEditor()

      if (editorInstance && editorInstance.fire) {
        editorInstance.fire('mention-select', {
          mention: `@${agent.username}`,
        })

        // eslint-disable-next-line no-undef
        editorInstance.fire('change')
      }
      dispatch(doUpdateMentions(agent.id))
      return dispatch(doUpdateAgentMention(null))
    }

    const ticketId = selectCurrentTicketId(state)
    const isAddingNote = selectIsAddingNote(state)

    if (!isAddingNote) dispatch(redirect(doOpenNotePage(ticketId)))

    runOnNextTick(() => {
      insertAgentMention(agent)
    })

    return Promise.resolve(agent)
  }
}

export function doAssignCurrentOrSelectedToCurrentUser() {
  return (dispatch, getState) => {
    const state = getState()
    const tickets = selectCurrentOrSelectedTickets(state)
    const currentUser = selectCurrentUser(state)
    const areAllAssignedToCurrentUser = all(ticket => {
      return ticket.assignee && ticket.assignee.id === currentUser.id
    }, tickets)
    const assignTo = areAllAssignedToCurrentUser ? null : currentUser.id
    const ticketIds = tickets.map(ticket => ticket.id)
    dispatch(doBulkAssign(ticketIds, null, assignTo))
  }
}
