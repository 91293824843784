import config from '../../config'
import { graphQLAPI } from './api'

export { default as mapErrorsToFormFields } from './mapErrorsToFormFields'

export const getData = res => res.json.data
export const getResult = key => data => data[key]

const graphql = graphQLAPI(config.graphql_url)

export default graphql
