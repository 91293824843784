import {
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_SUBDOMAIN_REQUEST,
  FORGOT_SUBDOMAIN_SUCCESS,
  FORGOT_SUBDOMAIN_FAIL,
  LOAD_RESET_PASSWORD_REQUEST,
  LOAD_RESET_PASSWORD_SUCCESS,
  LOAD_RESET_PASSWORD_FAIL,
  UPDATE_ACCOUNT,
} from 'constants/action_types'
import graphql from 'api/graphql'
import { doGraphqlRequest } from 'ducks/requests/operations'
import { updateAccountMutation } from 'api/account'
import { selectAccount } from 'selectors/app'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import doRedirectToBilling from 'subapps/settings/actions/doRedirectToBilling'

export function doRealtimeAccountEvent(message) {
  return dispatch => {
    const { data } = message
    const state = data?.billing_state?.state
    // If an account has been closed then we want to kick them out to the login
    // screen
    if (['expired', 'closed'].includes(state)) {
      dispatch(doRedirectToBilling())
    }
  }
}

export function doForgotPassword(email, subdomain) {
  return dispatch => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST, data: { email, subdomain } })

    const mutation = `
      mutation SendForgotPasswordMail($input: AccountForgotPasswordInput!) {
        sendForgotPasswordMail(input: $input) {
          message
          valid
        }
      }
    `
    const variables = { input: { subdomain, email } }

    return graphql(null, mutation, variables)
      .then(res => {
        const { message, valid } = res.json.data.sendForgotPasswordMail
        dispatch({
          type: valid ? FORGOT_PASSWORD_SUCCESS : FORGOT_PASSWORD_FAIL,
          data: { message, valid },
        })
      })
      .catch(e => {
        console.error(e) // eslint-disable-line
        dispatch({
          type: FORGOT_PASSWORD_FAIL,
          data: { message: 'Error occurred, please try again in a moment' },
        })
      })
  }
}

export function doForgotSubdomain(email) {
  return dispatch => {
    dispatch({ type: FORGOT_SUBDOMAIN_REQUEST, data: { email } })

    const mutation = `
      mutation SendForgotSubdomainMail($input: AccountForgotSubdomainInput!) {
        sendForgotSubdomainMail(input: $input) {
          message
          valid
        }
      }
    `
    const variables = { input: { email } }

    return graphql(null, mutation, variables)
      .then(res => {
        const { message, valid } = res.json.data.sendForgotSubdomainMail
        dispatch({
          type: valid ? FORGOT_SUBDOMAIN_SUCCESS : FORGOT_SUBDOMAIN_FAIL,
          data: { message, valid },
        })
      })
      .catch(e => {
        console.error(e) // eslint-disable-line
        dispatch({
          type: FORGOT_SUBDOMAIN_FAIL,
          data: { message: 'Error occurred, please try again in a moment' },
        })
      })
  }
}

export function doLoadResetPasswordRequest(token) {
  return dispatch => {
    dispatch({
      type: LOAD_RESET_PASSWORD_REQUEST,
    })

    const query = `
      query ResetPasswordRequestQuery($resetPasswordToken: ID!) {
        resetPasswordRequest(reset_password_token: $resetPasswordToken) {
          id
          require2fa
        }
      }
    `

    const variables = {
      resetPasswordToken: token,
    }

    return graphql(null, query, variables)
      .then(res => {
        const { id, require2fa } = res.json.data.resetPasswordRequest
        dispatch({
          type: LOAD_RESET_PASSWORD_SUCCESS,
          data: { id, require2fa },
        })
      })
      .catch(e => {
        console.error(e) // eslint-disable-line
        if (
          e &&
          e.errors &&
          e.errors[0] &&
          e.errors[0].message &&
          e.errors[0].message.indexOf('404') > -1
        ) {
          return dispatch({
            type: LOAD_RESET_PASSWORD_FAIL,
            data: {
              message: 'Reset password token has expired',
              missingToken: true,
            },
          })
        }
        return dispatch({
          type: LOAD_RESET_PASSWORD_FAIL,
          data: {
            message: 'Error occurred, please reload the page',
            missingToken: false,
          },
        })
      })
  }
}

export const doUpdateAccount = (fields, options = {}) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const currentAccountPreferences = selectAccount(state)?.preferences || {}
  const rebuildLeftNav =
    fields.unified_inbox !== currentAccountPreferences.unified_inbox ||
    fields.v2_show_all_mailboxes_section !==
      currentAccountPreferences.v2_show_all_mailboxes_section

  const retVal = await dispatch(
    doGraphqlRequest(
      UPDATE_ACCOUNT,
      updateAccountMutation,
      {
        input: { ...fields },
      },
      {
        moduleOptions: {
          toasts: {
            enabled: true,
            started: {
              enabled: false,
            },
            success: {
              enabled: true,
              content: 'Preferences changes saved',
            },
            failed: {
              content: 'Preferences changes failed',
              onClickAction: () => dispatch(doUpdateAccount(fields, options)),
            },
          },
        },
        ...options,
      }
    )
  )

  if (rebuildLeftNav) {
    await dispatch(doBuildInboxMenuFromMailboxes())
  }

  return retVal
}
