import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="5 5 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="M12.591,19.3C11.963,19.3 11.455,19.792 11.455,20.4C11.455,21.008 11.963,21.5 12.591,21.5C13.219,21.5 13.727,21.008 13.727,20.4C13.727,19.792 13.219,19.3 12.591,19.3ZM4.636,18.2C4.009,18.2 3.5,18.692 3.5,19.3L3.5,21.5C3.5,22.108 4.009,22.6 4.636,22.6C5.264,22.6 5.773,22.108 5.773,21.5L5.773,19.3C5.773,18.692 5.264,18.2 4.636,18.2ZM27.364,18.2C26.736,18.2 26.227,18.692 26.227,19.3L26.227,21.5C26.227,22.108 26.736,22.6 27.364,22.6C27.991,22.6 28.5,22.108 28.5,21.5L28.5,19.3C28.5,18.692 27.991,18.2 27.364,18.2ZM21.682,10.5L17.136,10.5L17.136,9.092C17.835,8.701 18.268,7.981 18.273,7.2C18.273,5.985 17.255,5 16,5C14.745,5 13.727,5.985 13.727,7.2C13.732,7.981 14.165,8.701 14.864,9.092L14.864,10.5L10.318,10.5C8.435,10.5 6.909,11.977 6.909,13.8L6.909,23.7C6.909,25.523 8.435,27 10.318,27L21.682,27C23.565,27 25.091,25.523 25.091,23.7L25.091,13.8C25.091,11.977 23.565,10.5 21.682,10.5ZM17.955,12.7L17.386,14.9L14.614,14.9L14.045,12.7L17.955,12.7ZM22.818,23.7C22.818,24.308 22.309,24.8 21.682,24.8L10.318,24.8C9.691,24.8 9.182,24.308 9.182,23.7L9.182,13.8C9.182,13.192 9.691,12.7 10.318,12.7L11.705,12.7L12.591,16.264C12.719,16.767 13.192,17.115 13.727,17.1L18.273,17.1C18.808,17.115 19.281,16.767 19.409,16.264L20.295,12.7L21.682,12.7C22.309,12.7 22.818,13.192 22.818,13.8L22.818,23.7ZM19.409,19.3C18.781,19.3 18.273,19.792 18.273,20.4C18.273,21.008 18.781,21.5 19.409,21.5C20.037,21.5 20.545,21.008 20.545,20.4C20.545,19.792 20.037,19.3 19.409,19.3Z"
      />
    </svg>
  )
})
