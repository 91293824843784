// Common API code. Can be called from either the app, or from a ServiceWorker
import {
  customerPartial,
  customerPartialWithTwitter,
} from 'ducks/customers/api'
import { callGraphQL } from './graphql/api'
import graphql from './graphql'

const getData = res => res.json.data

export const filterableFieldsPartials = `
  assigned_group_id
  assignedAt
  bodyAuthor {
    id
    type
  }
  bodyType
  interactionCount
  snoozedById
  snoozedUntil
  stateChangedAt
  updated_at
  latest_collaborator_comment_at
  last_unanswered_user_message_at
  deleted_at
`

export const generateFieldsPayload = partials => {
  return `
  id
  type
  subType
  title
  state
  body
  priority
  message_count
  attachment_count
  labels {
    id
    name
    color
  }
  ${filterableFieldsPartials}
  system_updated_at
  deleted_at
  mailboxId
  full
  assignee {
    id
    name
    email
    avatar_url
  }
  platform
  browser
  pageUrl
  pageTitle
  ${partials && partials.join('\n')}
  follower_ids
`
}

// Basic ticket props, sans changesets
export const basicFields = generateFieldsPayload([customerPartial])
export const basicFieldsWithTwitter = generateFieldsPayload([
  customerPartialWithTwitter,
])

export const actionPartials = `
  ... on MergerChange {
    type
    ticket
  }
  ... on RatingChange {
    agent
    grade
    feedback
    message
  }
  ... on MessageChange {
    id
    body
    parts {
      index
      type
      text
    }
    note
    forward
    hasRawEmail
    ruleId
    editedAt
    to {
      id
      email
      first_name
      last_name
      avatar_url
      username
      type
      name
    }
    agent_response
    message_type
    deliverBy
    cc {
      id
      email
      name
      first_name
      last_name
      avatar_url
      username
      type
    }
    bcc {
      id
      email
      name
      first_name
      last_name
      avatar_url
      username
      type
    }
    attachments {
      id
      url
      download_url
      file_name
      file_type
      file_size
      content_id
      type
      scan_report {
        status
        matches
      }
    }
    conversationType
  }
  ... on StateChange {
    state
  }
  ... on TitleChange {
    title
    previousTitle
  }
  ... on AgentChange {
    id
    email
  }
  ... on GroupChange {
    id
  }
  ... on StarChange {
    starred
    priority
  }
  ... on SnoozeChange {
    until
  }
  ... on MailboxChange {
    mailbox
    previous_mailbox
  }
  ... on LabelChange {
    label_id
    delta
  }
  ... on UnsnoozeChange {
    type
  }
  ... on CustomerReadChange {
    event
    messageId
  }
  ... on DeletedChange {
    restored
  }
  ... on SeparatorChange {
    comments_count
    skipped_changeset_ids
  }
  ... on CollapsedChangesetChange {
    agent_response
    has_attachments
    is_note
    is_forward
    is_from_merge
    snippet
    subchangeset_ids
    subchangeset_types
  }
  ... on IntegrationChange {
    external_id
    provider
    removed
    title
    url
  }
  ... on IntegrationExternalChange {
    action
    external_id
    provider
  }
  ... on CustomerChange {
    id
    email
    previousCustomer {
      id
      email
      name
    }
  }
  ... on ShopifyExternalChange {
    external_id
    change_type
    link
    meta
  }
  ... on CommentDeletionChange {
    comment_id
  }
  ... on CommentEditChange {
    comment_id
    body
    previous_body
    created_at
  }
  ... on CommentReactionChange {
    comment_id
    reaction
    is_added
  }
`

export const actorPartials = `
  id
  type
  ... on AgentActor {
    name
    first_name
    last_name
    avatar_url
    email
  }
  ... on CustomerActor {
    name
    first_name
    last_name
    company_name
    location
    email
    avatar_url
    twitter {
      username
    }
  }
  ... on OauthCredentialActor {
    provider
  }
  ... on RuleActor {
    name
  }
`

export const actionsPartials = `
  actions {
    records {
      id
      created_at
      change_type
      changeset
      preview
      actor {
        ${actorPartials}
      }
      change {
        type
        ${actionPartials}
      }
    }
  }
`

export const changesetFields = `
  id
  changesetId
  diff
  ticket {
    id
    type
    title
    state
    snoozedUntil
    linkedExternalResources {
      externalId
      linkedAt
      provider
      title
      url
    }
    message_count
    priority
    ${filterableFieldsPartials}
    system_updated_at
    deleted_at
    mailboxId
    assignee {
      id
      name
      email
      avatar_url
    }
    ${customerPartial}
    labels {
      id
      name
    }
    follower_ids
  }
  ${actionsPartials}
`

export const changesetFieldsForNewTicket = `
  id
  changesetId
  diff
  ticket {
    ${basicFields}
  }
  ${actionsPartials}
`

export const query = ticketId => `
  query TicketQuery {
    ticket(id: ${ticketId}) {
      ${basicFields}
    }
  }
`

export const fetch = (url, token, ticketId, fields = null) =>
  callGraphQL(url, token, query(ticketId, fields || basicFields))
    .then(getData)
    .then(data => data.ticket)

const changeStateMutation = `
  mutation CreateChangeset(
    $userInitiated: Boolean,
    $ticketId: String,
    $state: String,
  ) {
    createChangeset(
      userInitiated: $userInitiated,
      ticketId: $ticketId,
      state: $state,
    ) {
      ${changesetFields}
    }
  }
`
const getChangeset = data => data.createChangeset

export const changeState = (
  url,
  token,
  ticketId,
  state,
  userInitiated = true
) =>
  callGraphQL(url, token, changeStateMutation, {
    userInitiated,
    ticketId,
    state,
  })
    .then(getData)
    .then(getChangeset)

export const open = (url, token, ticketId, userInitiated = true) =>
  changeState(url, token, ticketId, 'opened', userInitiated)

export const close = (url, token, ticketId, userInitiated = true) =>
  changeState(url, token, ticketId, 'closed', userInitiated)

const changeAssigneeMutation = `
  mutation CreateChangeset(
    $userInitiated: Boolean,
    $ticketId: String,
    $assignee: String,
    $removeAssignee: Boolean,
  ) {
    createChangeset(
      userInitiated: $userInitiated,
      ticketId: $ticketId,
      assignee: $assignee,
      removeAssignee: $removeAssignee,
    ) {
      ${changesetFields}
    }
  }
`

// To remove an assignee you MUST pass null.
export const assign = (
  url,
  token,
  ticketId,
  assigneeId = null,
  userInitiated = true
) =>
  callGraphQL(url, token, changeAssigneeMutation, {
    userInitiated,
    ticketId,
    assignee: assigneeId,
    removeAssignee: assigneeId === null,
  })
    .then(getData)
    .then(getChangeset)

function buildSingleChangesetDefinition(fields) {
  return `mutation CreateChangeset(
      $ticketId: String,
      $commentId: String,
      $mailboxId: String,
      $assignee: String,
      $removeAssignee: Boolean,
      $group: String,
      $removeGroup: Boolean,
      $priority: String,
      $state: String,
      $requiredState: String,
      $title: String,
      $snoozeUntil: String,
      $removeSnooze: Boolean,
      $message: MessageInputV2,
      $userInitiated: Boolean,
      $skipTicketTouch: Boolean,
      $changesetId: String,
      $delete: Boolean,
      $restore: Boolean,
      $templateId: String,
      $tagsToAddById: [String],
      $tagsToRemoveById: [String],
      $follows: Boolean,
      $removeDraftId: String,
      $removeAllLabels: Boolean,
      $removeLabels: [String],
      $deleteCommentId: String,
      $updateCommentId: String,
      $reactionToAdd: String,
      $reactionToRemove: String,
    ) {
      createChangeset(
        ticketId: $ticketId,
        commentId: $commentId,
        mailboxId: $mailboxId,
        assignee: $assignee,
        removeAssignee: $removeAssignee,
        group: $group,
        removeGroup: $removeGroup,
        priority: $priority,
        state: $state,
        requiredState: $requiredState,
        title: $title,
        messagev2: $message,
        userInitiated: $userInitiated,
        skipTicketTouch: $skipTicketTouch,
        snoozeUntil: $snoozeUntil,
        removeSnooze: $removeSnooze,
        changesetId: $changesetId,
        delete: $delete,
        restore: $restore,
        templateId: $templateId,
        tagsToAddById: $tagsToAddById,
        tagsToRemoveById: $tagsToRemoveById,
        follows: $follows,
        removeDraftId: $removeDraftId,
        removeAllLabels: $removeAllLabels,
        removeLabels: $removeLabels,
        deleteCommentId: $deleteCommentId,
        updateCommentId: $updateCommentId,
        reactionToAdd: $reactionToAdd,
        reactionToRemove: $reactionToRemove,
      ) {
        ${fields}
      }
    }
  `
}

export const singleChangesetDefinition = buildSingleChangesetDefinition(
  changesetFields
)
export const newTicketChangesetDefinition = buildSingleChangesetDefinition(
  changesetFieldsForNewTicket
)

export const bulkChangesetDefinition = `
  mutation CreateBulkChangeset(
    $inputs: [ChangesetInput]
  ) {
    createBulkChangeset(
      inputs: $inputs
    ) {
      tickets {
        ${changesetFields}
      }
    }
  }
`

export const attachIntegration = (
  token,
  ticketId,
  provider,
  title,
  id,
  url
) => {
  const mutation = `
    mutation CreateChangeset(
      $ticketId: String,
      $integration: IntegrationInput,
    ) {
      createChangeset(
        ticketId: $ticketId,
        integration: $integration,
      ) {
        ${changesetFields}
        }
      }
    }
`
  graphql(token, mutation, {
    ticketId,
    integration: { provider, title, id, url },
  })
    .then(getData)
    .then(getChangeset)
}

export const integrationChangesetMutation = `
  mutation CreateChangeset(
    $ticketId: String,
    $integration: IntegrationInput,
  ) {
    createChangeset(
      ticketId: $ticketId,
      integration: $integration,
    ) {
      ${changesetFields}
    }
}
`

export const customerChangesetMutation = `
  mutation CreateChangeset(
    $ticketId: String,
    $customer: String,
  ) {
    createChangeset(
      ticketId: $ticketId,
      customer: $customer,
    ) {
      ${changesetFields}
    }
}
`

export const deleteTicketsMutation = `
  mutation DeleteTickets(
    $ticketIds: [String],
    $deleteMode: DeleteModeType,
  ) {
    deleteTickets(
      ticketIds: $ticketIds,
      deleteMode: $deleteMode,
    ) {
      id
    }
  }
`
