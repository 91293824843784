const preferences = `
  preferences {
    custom_profile_app_installed
    custom_profile_unsafe
    groove_20_beta
    groove_legacy_access
    historical_reports
    reports_average
    scoped_contacts_search
    show_pinned_searches
    experimental_realtime_warnings
    mention_any_agent
    experimental_label_ui
    self_help_cancellation
    ticket_preloading
    chat_room_id
    should_use_fixed_drafts_v2
    should_use_notes_v2
    crm_sidebar
    debug_frontend
    chat_enabled
    sort_conversation_by_collaborator_comment_enabled
    mail_chimp_installed
    team_chat_app
    campaign_monitor_installed
    constant_contact_installed
    evoice_installed
    outbound_email_branding_disabled
    skip_email_beacon
    unified_inbox
    v2_show_all_mailboxes_section
    usage_onboarding
    hide_reporting
    reporting_permission
    reply_button
    show_legacy_integrations_page
    edit_mailbox_view_settings
    enabled_features
    disabled_features
    sort_conversation_by_last_unanswered_user_message_at_enabled
    show_demo_mailbox
    inbox_billing_version
    kb_billing_version
    addon_billing_version
    billing_coupon
    ai_instant_replies
    allow_noreply
    allow_warning_status_attachments
    two_factor_authentication_globally_enforced
    ai_enabled
  }
`

export const basicFields = `
  id
  subdomain
  name
  stripe_last4
  primary_knowledge_base_id
  is_onboarding_completed
  has_v2_widgets
  industry
  timezone
  activated_at
  ${preferences}
`

export const withIntegrationsSettings = `
  ${basicFields}
  integrationSettings {
    provider
    value
  }
`

export const accountQuery = () => `
  query AccountQuery {
    account {
      ${withIntegrationsSettings}
    }
  }
`

export const accountPreferencesQuery = `
  query AccountPreferencesQuery {
    account {
      ${preferences}
    }
  }
`

export const accountPreferenceQuery = preference => `
  query AccountPreferenceQuery {
    account {
      preferences {
        ${preference}
      }
    }
  }
`

export const updateAccountMutation = `
  mutation UpdateAccount($input: AccountUpdateInput!) {
    updateAccount(input: $input) {
      ${basicFields}
    }
  }
`
