import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.7 5H8.3A3.3 3.3 0 0 0 5 8.3v11a3.3 3.3 0 0 0 3.3 3.3h12.749l4.07 4.08a1.1 1.1 0 0 0 .781.32.924.924 0 0 0 .418-.088A1.1 1.1 0 0 0 27 25.9V8.3A3.3 3.3 0 0 0 23.7 5Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
