import { v4 as uuidV4 } from 'uuid'
import { BEGIN, REVERT } from 'redux-optimist'

import graphql from 'api/graphql'
import { bulkChangesetDefinition } from 'api/tickets'

import * as types from 'constants/action_types'

import { doAutoRedirect, doOpenCurrentSearch } from 'actions/autoRedirect'
import { doClearNonNamedTicketSearchResults } from 'actions/search'

import { oauthTokenSelector } from 'selectors/app'
import { selectIsMailboxInaccessible } from 'selectors/mailboxes'

import { selectCurrentTicketId } from 'selectors/tickets/current/selectCurrentTicketId'
import { selectNextTicketIdInList } from 'selectors/ticket_list'

import debug from 'util/debug'
import { runOnNextTick } from 'util/functions'
import { batchActions } from 'util/redux'
import { normalizeDiffSearchesPayload } from 'util/search'
import { buildSingleChangesetInput } from './buildSingleChangesetInput'

export function doSendBulkChangeset(
  inputs,
  {
    userInitiated = true,
    optimisticData = null,
    defaultChangesetId = null, // recommend using the optimistic changeset ID
    snackbar,
  } = {}
) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)

    const changesetId = defaultChangesetId || uuidV4() // 'bulk' changeset id.

    dispatch({
      type: types.CREATE_BULK_CHANGESET_REQUEST,
      data: {
        tickets: optimisticData,
      },
      optimist: optimisticData ? { type: BEGIN, id: changesetId } : null,
    })

    const mutation = bulkChangesetDefinition

    const variables = {
      inputs: inputs.map(data =>
        buildSingleChangesetInput(
          data.ticketId,
          data,
          state,
          userInitiated,
          changesetId
        )
      ),
    }

    let movedToInaccesibleMailbox = false
    if (variables.inputs.length === 1) {
      movedToInaccesibleMailbox = selectIsMailboxInaccessible(
        state,
        variables.inputs[0].mailboxId
      )
    }

    if (movedToInaccesibleMailbox) {
      runOnNextTick(() => {
        dispatch(doOpenCurrentSearch())
      })
    }

    if (!movedToInaccesibleMailbox && userInitiated) {
      runOnNextTick(() => {
        const afterState = getState()
        const currentTicketId = selectCurrentTicketId(afterState)
        const nextTicketId = selectNextTicketIdInList(afterState) || null
        if (currentTicketId && nextTicketId) {
          dispatch(doAutoRedirect(currentTicketId, nextTicketId))
        }
      })
    }

    return graphql(token, mutation, variables)
      .then(res => {
        const response = res.json.data
        const actions = []
        const { tickets } = response.createBulkChangeset
        const ticketsWithOptimisticSearchDiff = tickets.map((ticket, index) => {
          const optimisticTicketData = optimisticData[index]
          const optimisticSearches =
            optimisticTicketData &&
            optimisticTicketData.diff &&
            optimisticTicketData.diff.searches
          const ticketDiff = JSON.parse(ticket.diff)
          const ticketSearches =
            ticketDiff && normalizeDiffSearchesPayload(ticketDiff.searches)

          return {
            ...ticket,
            diff: {
              ...ticketDiff,
              searches: {
                ...optimisticSearches,
                ...ticketSearches,
              },
            },
          }
        })
        actions.push({
          type: types.CREATE_BULK_CHANGESET_SUCCESS,
          data: {
            tickets: ticketsWithOptimisticSearchDiff,
            snackbar,
          },
          // We REVERT not COMMIT otherwise the filter diff counts may be wrong.
          optimist: optimisticData ? { type: REVERT, id: changesetId } : null,
        })

        const responseData = response.createBulkChangeset

        responseData.tickets.forEach(ticket => {
          // eslint-disable-next-line no-param-reassign
          ticket.diff = JSON.parse(ticket.diff) || {}
          actions.push(
            doAddChangeset(
              ticket.id,
              ticket.ticket,
              ticket.changesetId,
              ticket.actions.records,
              ticket.diff
            )
          )
        })

        actions.push(
          doClearNonNamedTicketSearchResults({
            keepCurrent: true,
            refetchListOnNextUpdate: true,
          })
        )
        dispatch(batchActions(actions))
      })
      .catch(err => {
        debug(err)
        dispatch({
          type: types.CREATE_BULK_CHANGESET_FAIL,
          optimist: optimisticData ? { type: REVERT, id: changesetId } : null,
        })
      })
      .then(() => dispatch({ type: types.CREATE_BULK_CHANGESET_COMPLETE }))
  }
}

function doAddChangeset(
  ticketId,
  ticketData,
  changesetId,
  actions,
  diff,
  preserveDraft = false
) {
  return {
    type: types.ADD_CHANGESET,
    data: {
      ticketId,
      changesetId,
      actions,
      ticketData,
      diff,
      preserveDraft,
    },
  }
}
