import Bugsnag from '@bugsnag/js'
import debug from 'util/debug'

export const logUnsupported = () => {
  debug('This browser does not support web push notifications')
  Bugsnag.notify(new Error('web push not supported'), event => {
    // eslint-disable-next-line no-param-reassign
    event.errors[0].errorClass = 'WebPushCompatibilityError'
    // eslint-disable-next-line no-param-reassign
    event.errors[0].errorMessage = 'web push not supported'
  })
}
