import { selectCurrentRawChangesetsForTicket } from 'selectors/currentChangesets'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { isEmpty } from 'util/arrays'
import { doFetchChangesetTicketActions } from './doFetchChangesetTicketActions'

export function doFetchAllChangesetsTicketActions(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const tickets = selectTicketsById(state)
    const ticket = tickets[ticketId]
    const changesets = selectCurrentRawChangesetsForTicket(state, ticketId)
    const collapsedChangesets = changesets.filter(({ id }) =>
      id.match('collapsed')
    )

    return Promise.all(
      collapsedChangesets.map(changeset => {
        if (isEmpty(changeset.actions)) return null
        const action = changeset.actions[0]
        const change = action.change
        if (!change) return null

        return dispatch(
          doFetchChangesetTicketActions(
            ticket.id,
            changeset.realId,
            change.subchangeset_ids
          )
        )
      })
    )
  }
}
