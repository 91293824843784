import metrics from 'util/metrics'
import { doFetchMissingLabelsForCurrentAndPinnedSearch } from 'actions/labels'
import { searchTickets } from 'api/search'
import * as types from 'constants/action_types'
import { oauthTokenSelector } from 'selectors/app'
import { selectSortOrderForSearchQuery } from 'selectors/search/sortOrderForSearchQuery'
import { selectTicketSearchOperatorValueMap } from 'selectors/search'
import { selectAccountPreferenceSortByCollaboratorCommentEnabled } from 'selectors/app/selectAccountPreferences'
import debug from 'util/debug'
import { constructSearchQueryString } from 'util/search'
import { selectFoldersById } from 'ducks/folders/selectors/folders'
import storage from 'util/storage'
import { doOpenFirstTicketForLoad } from './doOpenFirstTicketForLoad'
import { doFindTicketByNumber } from './doFindTicketByNumber'
import { isValidNumber } from './isValidNumber'

export const doFetchTicketSearch = (
  queryId,
  query,
  apiQuery,
  sortOrder,
  page = 1,
  perPage = 25,
  options = {}
) => {
  return (dispatch, getState) => {
    const params = { queryId, queryObject: query, apiQuery, page, sortOrder }
    const { storeLastSearchQueryId = true } = options || {}
    const state = getState()
    const foldersById = selectFoldersById(state)
    const ticketSearchOperatorValueMap = selectTicketSearchOperatorValueMap(
      state
    )
    const token = oauthTokenSelector(state)
    const sortByCollaboratorCommentAtEnabled = selectAccountPreferenceSortByCollaboratorCommentEnabled(
      state
    )

    const newSort = selectSortOrderForSearchQuery(state, {
      queryId,
      sort: params.sortOrder,
    })

    if (params.sortOrder !== newSort) {
      params.sortOrder = newSort
    }

    if (storeLastSearchQueryId) storage.set('lastSearchQueryId', queryId)
    dispatch({
      type: types.SEARCH_REQUEST,
      data: params,
    })

    const sortOptions = {
      sortByCollaboratorCommentAtEnabled,
    }

    const promises = [
      searchTickets(
        token,
        apiQuery,
        params.sortOrder,
        page,
        perPage,
        sortOptions
      ),
    ]

    if (isValidNumber(queryId)) {
      promises.push(dispatch(doFindTicketByNumber(queryId)))
    }
    // We need the labels loaded fully first so our query IDs are actually IDs
    // and not queryStrings. This can happen when you load a page directly.
    return dispatch(doFetchMissingLabelsForCurrentAndPinnedSearch()).then(
      () => {
        return Promise.all(promises)
          .then(data => {
            const response = data[0]
            const ticketByNr = !isNaN(queryId) ? data[1] : null
            const { tickets } = response

            if (ticketByNr && tickets) tickets.unshift(ticketByNr)

            const queryString = constructSearchQueryString(query)
            metrics.enhanceTimer(`search-load-${queryString}`, {
              responseRuntime: response.runtime,
              responseLatency: response.latency,
            })

            dispatch({
              type: types.SEARCH_SUCCESS,
              data: {
                ...params,
                ...response,
                tickets,
                foldersById,
                ticketSearchOperatorValueMap,
              },
            })

            return response
          })
          .catch(error => {
            debug(error)
            dispatch({
              type: types.SEARCH_ERROR,
              error: true,
              data: {
                ...params,
                message: error.message,
                stack: error.stack,
              },
            })
            return {}
          })
          .then(response => {
            dispatch({
              type: types.SEARCH_COMPLETE,
              data: {
                ...params,
                ...response,
              },
            })
            dispatch(
              doOpenFirstTicketForLoad(
                response.tickets &&
                  response.tickets[0] &&
                  response.tickets[0].id
              )
            )
            return response
          })
      }
    )
  }
}
