import bootstrap from 'util/bootstrap'
import metrics from 'util/metrics'
import { redirect, replace } from 'redux-first-router'
import * as types from 'constants/action_types'
import {
  selectCurrentTicketSearchResultTicketIds,
  shouldFetchTicketSearchByQueryString,
  selectCurrentTicketSearchQueryString,
} from 'selectors/search'
import debug from 'util/debug'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import { TICKET_PAGE } from 'constants/pages'
import { selectIsTicketPagePush } from 'selectors/location'
import { doOpenFirstTicketForLoad } from './doOpenFirstTicketForLoad'
import { doFetchTicketSearchByQueryString } from './doFetchTicketSearchByQueryString'

export function doLoadSearchTickets(isRefreshBtnClick) {
  return (dispatch, getState) => {
    let promise = Promise.resolve(true)
    const state = getState()

    if (shouldFetchTicketSearchByQueryString(state, isRefreshBtnClick)) {
      const queryString = selectCurrentTicketSearchQueryString(state)
      metrics.startOrEnhanceTimer(`search-load-${queryString}`, {
        metric: 'search-load',
        logname: 'main',
        hot: false,
        queryString,
      })
      // We need agents to search by assignee. This is a little janky - ideally
      // we'd just be able to observe the success of the bootstrapQuery
      promise = bootstrap
        .wait()
        .then(() => dispatch(doFetchTicketSearchByQueryString()))
    } else {
      promise.then(() => dispatch(doOpenFirstTicketForLoad()))
      debug('not fetching')
    }

    return promise.then(result => {
      const { term } = state.location.payload
      if (!isNaN(term)) {
        debug('redirecting to ticket page')
        // Open the ticket page directly if its an ID search, and that ticket
        // matches first (sorted) result
        const sortedIds = selectCurrentTicketSearchResultTicketIds(getState())
        if (term === sortedIds[0]) {
          // Support going back to ticket list with number search: we use redirect instead of replace
          // https://trello.com/c/JZAaPzxe/4512-conversation-number-search-loads-before-results-constructtrue
          const prefersClassicView = selectPrefersClassicView(state)
          const isTicketPagePush = selectIsTicketPagePush(state)
          const shouldRedirectToTicketPage =
            prefersClassicView && !isTicketPagePush
          const shouldReplaceWithTicketPage = !prefersClassicView
          if (shouldRedirectToTicketPage) {
            dispatch(
              redirect({
                type: TICKET_PAGE,
                payload: { id: term },
              })
            )
          } else if (shouldReplaceWithTicketPage) {
            // HACK (jscheel): We have to manually force the latest history entry
            // to be replaced, because RFR's redirect is completely unintuitive and
            // cannot actually replace the entry itself once you are already in
            // the thunk for the route.
            dispatch({
              type: types.TICKET_ID_SEARCH_REDIRECT,
              payload: {
                prev: state.location.prev,
              },
            })
            replace(`/tickets/${term}`)
            // END HACK
          }
        }
      } else {
        debug('no number search. doing nothing')
      }

      return result
    })
  }
}
