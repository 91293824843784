import graphql from 'api/graphql'
import { TICKET_PAGE } from 'constants/pages'
import { oauthTokenSelector } from 'selectors/app'

export function doFetchTicketMergers(ticketId) {
  return (dispatch, getState) => {
    const state = getState()
    const token = oauthTokenSelector(state)
    const query = `
      query TicketMergerQuery {
        mergers(childTicketId: ${ticketId}) {
          records {
            parent_ticket {
              id
            }
          }
        }
      }
    `
    return graphql(token, query).then(res => {
      const records = res.json.data.mergers.records

      if (records.length > 0) {
        return dispatch({
          type: TICKET_PAGE,
          payload: {
            id: records[0].parent_ticket.id,
          },
        })
      }

      throw new Error(`Could not find merger for ticket ${ticketId}`)
    })
  }
}
