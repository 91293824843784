import { ticketHasAttachments } from 'util/draft'
import { getRecipientInput } from './getRecipientInput'

export const buildSingleChangesetInput = (
  ticketId,
  data,
  state,
  userInitiated,
  changesetId
) => {
  const isForwarding = data.isForwarding

  // NOTE (jscheel || marcinbunsch): To remove an assignee or group (or
  // snoozeState), you MUST pass null. Undefined will be ignored, as we
  // strict check so that undefined values don't accidentally creep in on
  // this uber- method.
  const variables = {
    ticketId,
    commentId: data.commentId,
    mailboxId: data.mailboxId,
    assignee: data.assignee ? data.assignee.id : null,
    removeAssignee: data.assignee === null,
    group: data.assigned_group_id,
    snoozeUntil: data.snoozeUntil,
    removeSnooze: data.removeSnooze,
    removeGroup: data.assigned_group_id === null,
    priority: data.priority,
    state: data.state,
    title: data.title,
    tagsToAddById: data.labels && data.labels.map(label => label.id),
    tagsToRemoveById:
      data.removeLabels && data.removeLabels.map(label => label.id),
    delete: data.delete,
    deleteMode: data.deleteMode,
    restore: data.restore,
    templateId: data.templateId,
    integration: data.integration,
    customer: data.customer,
    userInitiated,
    changesetId,
    follows: data.follows,
    deleteCommentId: data.deleteCommentId,
    updateCommentId: data.updateCommentId,
    reactionToAdd: data.reactionToAdd,
    reactionToRemove: data.reactionToRemove,
  }

  if (data.requiredState) variables.requiredState = data.requiredState
  if (data.removeDraftId) variables.removeDraftId = data.removeDraftId
  if (data.includeTicketInChangesetResponse) {
    variables.includeTicketInChangesetResponse =
      data.includeTicketInChangesetResponse
  }

  // NOTE (jscheel): Instead of having to remember a nested structure for
  // the message input object throughout the app, we simple assemble it here.
  if (isForwarding || data.body || data.note || ticketHasAttachments(data)) {
    const message = (({ body, note, isNote }) => ({ body, note, isNote }))(data)

    if (data.attachments) {
      const keys = Object.keys(data.attachments)
      message.attachments = keys.map(key => {
        const attachment = data.attachments[key]
        return {
          key: attachment.key,
          file_name: attachment.filename,
          content_type: attachment.content_type,
          file_size: attachment.file_size,
        }
      })
    }

    // perform sanitization on the message body to clean up quills
    // block level tags
    if (data.to) message.to = data.to.map(getRecipientInput)
    if (data.cc) message.cc = data.cc.map(getRecipientInput)
    if (data.bcc) message.bcc = data.bcc.map(getRecipientInput)
    if (typeof data.quotedText === 'string')
      message.quotedText = data.quotedText

    message.isForwarding = isForwarding || false
    message.isNote = message.isNote || false
    if (message.isNote) {
      message.note = message.body
      message.body = undefined
    }
    variables.message = message
  }

  return variables
}
