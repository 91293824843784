import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g>
        <path
          d="M3.3 9.51a4.39 4.39 0 1 0 0-6.21 4.39 4.39 0 0 0 0 6.21z"
          className="stroke"
          strokeWidth="1.6"
          strokeLinecap="round"
          fill="none"
        />
        <path
          d="M9.51 9.51L14 14"
          className="stroke"
          strokeWidth="1.6"
          strokeLinecap="round"
          fill="none"
        />
      </g>
    </svg>
  )
})
