import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'
import { agentsQuery } from 'api/agents'
import { BootstrapLoader } from 'util/bootstrap'
import requestAction from './requestAction'
import successAction from './successAction'
import failAction from './failAction'

export default function loader() {
  return new BootstrapLoader(
    'agents',
    requestAction,
    successAction,
    failAction,
    getState => {
      return graphql(oauthTokenSelector(getState()), agentsQuery())
    }
  )
}
