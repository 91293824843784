import { MAILBOX_FOLDER_PAGE, FOLDER_PAGE } from 'constants/pages'

export const doOpenFolderPage = (folderId, mailboxId) => {
  if (mailboxId) {
    return {
      type: MAILBOX_FOLDER_PAGE,
      payload: {
        folderId,
        mailboxId,
      },
    }
  }
  return {
    type: FOLDER_PAGE,
    payload: { folderId },
  }
}
