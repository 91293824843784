import React from 'react'
import themeVars from 'ui_theme/site/globals/site.variables'

export default React.forwardRef(
  (
    { className, defaultFill = themeVars.ultraLight, ...rest },
    forwardedRef
  ) => {
    return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
        ref={forwardedRef}
      >
        <path fill={defaultFill} d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z" />
      </svg>
    )
  }
)
