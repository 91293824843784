import failAction from './failAction'
import requestAction from './requestAction'
import successAction from './successAction'
import loader from './loader'

export default {
  createFetchAgentsRequestAction: requestAction,
  createFetchAgentsSuccessAction: successAction,
  createFetchAgentsFailAction: failAction,
  createFetchAgentsLoader: loader,
}
