import config from 'config'
import { BEGIN, REVERT } from 'redux-optimist'
import { v4 as uuidV4 } from 'uuid'
import * as types from 'constants/action_types'
import { getUserAgent } from 'util/browser'
import { getMatrixEnvironment } from 'ducks/chat/utils/client/getMatrixEnvironment'

const { sygnalServerUrl: SYGNAL_SERVER_URL } = config

// Send a (granted) push subscription to the back end
export const doSaveWebPushSubscription = subscriptionObj => async dispatch => {
  if (!subscriptionObj) return false
  const transactionID = uuidV4()
  const userAgent = getUserAgent()
  const {
    endpoint,
    keys: { p256dh, auth },
  } = subscriptionObj.toJSON()
  dispatch({
    type: types.SAVE_WEB_PUSH_SUBSCRIPTION_REQUEST,
    data: {
      endpoint,
      id: `optimist-${transactionID}`, // for debugging
    },
    optimist: { type: BEGIN, id: transactionID },
  })

  const handleError = err => {
    // destroy the subscription we just created so the SW isnt out of sync
    // with our API
    if (subscriptionObj) subscriptionObj.unsubscribe() // returns boolean
    dispatch({
      type: types.SAVE_WEB_PUSH_SUBSCRIPTION_FAIL,
      data: { err },
      optimist: { type: REVERT, id: transactionID },
    })
  }

  const client = await getMatrixEnvironment()
  try {
    await client.setPusher({
      lang: 'en-US',
      kind: 'http',
      app_display_name: 'GrooveHQ',
      device_display_name: userAgent,
      // profile_tag: 'xxyyzz',
      app_id: 'com.groovehq.app.web',
      pushkey: p256dh,
      data: {
        url: `${SYGNAL_SERVER_URL}/_matrix/push/v1/notify`,
        endpoint,
        auth,
        events_only: true,
      },
      append: false,
    })
  } catch (err) {
    return handleError(err)
  }

  return dispatch({
    type: types.SAVE_WEB_PUSH_SUBSCRIPTION_SUCCESS,
    data: { endpoint },
    // We need to revert the optimistic one since itll hang around in
    // byEndpoint otherwise
    optimist: { type: REVERT, id: transactionID },
  })
}
