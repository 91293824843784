import React from 'react'

const style = { transform: 'translate3d(0, -1px, 0)' }

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 6"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        style={style}
        d="m15.2078755 4.2857143h-14.4078755c-.4418278 0-.8.3837559-.8.8571428 0 .473387.3581722.8571429.8.8571429h14.4078755c.4418278 0 .8-.3837559.8-.8571429 0-.4733869-.3581722-.8571428-.8-.8571428zm-.0078755-4.2857143h-14.4c-.4418278 0-.8.38375593-.8.85714286 0 .47338694.3581722.85714284.8.85714284h14.4c.4418278 0 .8-.3837559.8-.85714284 0-.47338693-.3581722-.85714286-.8-.85714286z"
      />
    </svg>
  )
})
