import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 18 4"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-1-8h20v20H-1z" />
        <g transform="translate(.5 .5)" className="fill">
          <circle cx="1.5" cy="1.5" r="1.5" />
          <circle cx="8.5" cy="1.5" r="1.5" />
          <circle cx="15.5" cy="1.5" r="1.5" />
        </g>
      </g>
    </svg>
  )
})
