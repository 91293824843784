import { doFetchTicket } from 'actions/tickets/doFetchTicket'
import {
  SEARCH_BY_TICKET_NUMBER_REQUEST,
  SEARCH_BY_TICKET_NUMBER_SUCCESS,
} from 'constants/action_types'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import debug from 'util/debug'

// Attempts to fetch the ticket by given number either from local cache or from
// API. If API fails with a 404, its not treated as an exception.

export const doFindTicketByNumber = ticketId => async (dispatch, getState) => {
  if (!ticketId) return Promise.reject('no ticket number given')

  dispatch({
    type: SEARCH_BY_TICKET_NUMBER_REQUEST,
    data: { ticketId },
  })

  const state = getState()
  let ticket = selectTicketsById(state)[ticketId]

  try {
    if (!ticket) {
      ticket = await dispatch(doFetchTicket(ticketId))
    }
    // doFetchTicket wont throw on a 404
    if (ticket.loadFailed) throw new Error('ticket load failed')
    dispatch({
      type: SEARCH_BY_TICKET_NUMBER_SUCCESS,
      data: {
        ticketId,
        ticket,
      },
    })
    return ticket
  } catch (err) {
    // Kevin R (2023-04-12)
    // WARNING!!: We intentionally dont have a error handler here because this is used by
    // the doFetchTicketSearch function which gets triggered by doFetchDefaultTicketSearch
    // which in turn gets trigged by the "shouldFetchDefaultPinnedSearch" trigger.
    // The problem is that dispatching the SEARCH_BY_TICKET_NUMBER_FAIL here previously
    // would set the fetchTicketSearch without modifying the "search" at all because it
    // was assumed that the SEARCH_SUCCESS or SEARCH_ERROR would update the search once
    // this promise returns. The problem is if this search also dispatches and action which
    // causes the trigger to run, then it'll run this code again and then you have yourself
    // a infinite loop.
    debug(err)
    return null
  }
}
