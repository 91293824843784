import React from 'react'

const style = { transform: 'translate3d(1px, 0, 0)' }

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        style={style}
        d="m13.3 0h-9.26948242c-1.84472656 0-4.03051758 1.56030273-4.03051758 3.55322266v9.74677734c0 .3865993.31340068.7.7.7h12.6c.3865993 0 .7-.3134007.7-.7v-12.6c0-.38659932-.3134007-.7-.7-.7zm-9.1 12.6h-2.8v-7h2.8zm4.2 0h-2.8v-7h2.8zm4.2 0h-2.8v-7h2.8zm0-8.4h-11.2c0-1.5463973 1.2536027-2.8 2.8-2.8h8.4z"
      />
    </svg>
  )
})
