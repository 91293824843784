import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.636 18.2c-.627 0-1.136.492-1.136 1.1v2.2c0 .608.509 1.1 1.136 1.1.628 0 1.137-.492 1.137-1.1v-2.2c0-.608-.509-1.1-1.137-1.1Zm22.728 0c-.628 0-1.137.492-1.137 1.1v2.2c0 .608.509 1.1 1.137 1.1.627 0 1.136-.492 1.136-1.1v-2.2c0-.608-.509-1.1-1.136-1.1Zm-5.682-7.7h-4.546V9.092A2.189 2.189 0 0 0 18.273 7.2C18.273 5.985 17.255 5 16 5c-1.255 0-2.273.985-2.273 2.2a2.189 2.189 0 0 0 1.137 1.892V10.5h-4.546c-1.883 0-3.409 1.477-3.409 3.3v9.9c0 1.823 1.526 3.3 3.41 3.3h11.363c1.883 0 3.409-1.477 3.409-3.3v-9.9c0-1.823-1.526-3.3-3.41-3.3Zm-2.273 8.8c.628 0 1.136.492 1.136 1.1 0 .608-.508 1.1-1.136 1.1-.628 0-1.136-.492-1.136-1.1 0-.608.508-1.1 1.136-1.1Zm-6.818 0c.628 0 1.136.492 1.136 1.1 0 .608-.508 1.1-1.136 1.1-.628 0-1.136-.492-1.136-1.1 0-.608.508-1.1 1.136-1.1Zm5.364-6.6-.569 2.2h-2.772l-.569-2.2h3.91Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
