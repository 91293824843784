import React from 'react'
import themeVars from 'ui_theme/site/globals/site.variables'

export default React.forwardRef(
  (
    { className, defaultFill = themeVars.ultraLight, ...rest },
    forwardedRef
  ) => {
    return (
      <svg
        className={className}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
        ref={forwardedRef}
      >
        <path
          fill={defaultFill}
          d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
        />
      </svg>
    )
  }
)
