import { groupsQuery } from 'api/groups'
import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'
import {
  createFetchGroupsRequestAction,
  createFetchGroupsSuccessAction,
  createFetchGroupsFailAction,
} from './createFetchGroups'

export default function doFetchGroups() {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(createFetchGroupsRequestAction())

    return graphql(oauthTokenSelector(state), groupsQuery())
      .then(res => {
        dispatch(createFetchGroupsSuccessAction(res))
      })
      .catch(err => {
        dispatch(createFetchGroupsFailAction(err))
        throw err
      })
  }
}
