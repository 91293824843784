import { fetchTicketOnDesktop } from './fetchTicketOnDesktop'

export function doFetchTicket(ticketId, options = {}) {
  return (dispatch, getState) => {
    if (!ticketId || ticketId === 'new') return false

    const state = getState()
    return fetchTicketOnDesktop(dispatch, state, ticketId, options)
  }
}
