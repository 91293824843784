import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 10"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="m9.9648123 1.3817964 1.8177326 4.443167.7956372-1.2182196c.5253766-.8044033 1.6910442-.8180202 2.2350162-.0266504l1.0298002 1.498252c.2754921.4008122.1789996.9526261-.2155221 1.2325109-.3945216.2798847-.937675.1818536-1.2131671-.2189586l-.7014476-1.020533-.6822248 1.0445711c-.5861529 1.091834-2.1888903 1.0220539-2.6665137-.145461l-1.9414153-4.74549032-1.953361 6.26274872c-.6156111 1.9737938-2.37163511 2.0260624-3.10438259.0944331l-.98945795-2.6083042c-.24066522.6079899-.25718183.6497185-.47222177 1.1930103-.18063468.4563806-.18063468.4563806-.22341224.5644652-.17951292.4535694-.68695677.6734157-1.13340757.4910405s-.6628467-.6979102-.48333377-1.1514796c.04278394-.1081008.04278394-.1081008.22342715-.564503.23294065-.5885173.23294065-.5885173.53719591-1.3571536.73616415-1.859986 2.4737562-1.6677626 3.17617406.1839134l.892 2.3513959 1.92318427-6.16599812c.6122735-1.96309253 2.3718945-2.0379016 3.1496999-.13675668zm-8.30124661-1.31971098c.43744488 0 .79206402.36846153.79206402.82298186 0 .45452034-.35461914.82298187-.79206402.82298187-.43744487 0-.79206401-.36846153-.79206401-.82298187 0-.45452033.35461914-.82298186.79206401-.82298186z"
      />
    </svg>
  )
})
