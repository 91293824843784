import { customerPartial } from 'ducks/customers/api'
import graphql from 'api/graphql'
import { filterableFieldsPartials } from 'api/tickets'
import metrics from 'util/metrics'
import {
  CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_REQUEST,
  CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_SUCCESS,
  CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_FAIL,
  CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_COMPLETE,
} from 'constants/action_types'
import { doFetchTicketSearchByQueryString } from 'actions/search/doFetchTicketSearchByQueryString'
import { oauthTokenSelector, selectCurrentFolderId } from 'selectors/app'
import { selectCurrentMailboxId } from 'selectors/mailboxes/selectCurrentMailboxId'
import { selectCurrentTicketSearchQueryString } from 'selectors/search'
import { selectTicketSorting } from 'selectors/sorting'
import { selectCurrentPage } from 'selectors/ticket_list'
import debug from 'util/debug'

export function doFetchCurrentFolderWithTicketsNextPage() {
  return (dispatch, getState) => {
    const state = getState()
    const folderId = selectCurrentFolderId(state)
    const mailboxId = selectCurrentMailboxId(state)
    const queryString = selectCurrentTicketSearchQueryString(state)
    const page = selectCurrentPage(state) + 1

    metrics.startOrEnhanceTimer(`search-load-${queryString}`, {
      metric: 'search-load',
      logname: 'main',
      hot: false,
      queryString,
      nextPage: true,
    })

    if (queryString) return dispatch(doFetchTicketSearchByQueryString(page))
    if (!folderId) return Promise.resolve(null)

    dispatch({ type: CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_REQUEST })

    const ticketSorting = selectTicketSorting(state)
    const [sortBy, sortOrder] = ticketSorting.split(':')

    let mailboxIdParam = ''
    if (mailboxId) {
      mailboxIdParam = `mailboxId: "${mailboxId}",`
    }

    const query = `
      query FolderNextPageQuery {
        folder(id: "${folderId}") {
          id
          tickets(${mailboxIdParam} sortBy: "${sortBy}", sortOrder: "${sortOrder}", page: ${page}) {
            metadata {
              currentPage
              totalPages
              totalCount
              sortBy
              sortOrder
            }
            records {
              id
              type
              title
              state
              body
              priority
              message_count
              labels {
                id
                name
              }
              ${filterableFieldsPartials}
              system_updated_at
              snoozedUntil
              mailboxId
              ${customerPartial}
              assignee {
                id
                name
                email
              }
            }
          }
        }
      }
    `
    const token = oauthTokenSelector(state)

    return graphql(token, query)
      .then(res => {
        const responseData = res.json.data
        const data = {
          page,
          folder: responseData.folder,
          mailboxId,
        }
        dispatch({
          type: CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_SUCCESS,
          data,
        })
        return data
      })
      .catch(err => {
        debug(err)
        dispatch({
          type: CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_FAIL,
          err,
        })
        return err.json.data // we can still use the partial result data!
      })
      .then(data =>
        dispatch({
          type: CURRENT_FOLDER_WITH_TICKETS_NEXT_PAGE_COMPLETE,
          data,
        })
      )
  }
}
