import React, { forwardRef } from 'react'

const SvgComponent = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.015 5C9.95 5 5.031 9.925 5.031 16c-.01 2.54.869 5.003 2.482 6.962l-2.196 2.2c-.312.316-.403.79-.231 1.199.187.405.599.657 1.043.638h9.886c6.067 0 10.985-4.925 10.985-11C27 9.926 22.082 5 16.015 5Z"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgComponent)
export default ForwardRef
