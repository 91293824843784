import config from '../../config'
import { graphQLAPI } from '../graphql/api'

export {
  default as mapErrorsToFormFields,
} from '../graphql/mapErrorsToFormFields'

export const getData = res => res.json.data
export const getResult = key => data => data[key]

const graphql = graphQLAPI(config.app_graphql_url)

export default graphql
