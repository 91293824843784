import config from 'config'
import Bugsnag from '@bugsnag/js'
import { BEGIN, REVERT } from 'redux-optimist'
import { v4 as uuidV4 } from 'uuid'
import * as types from 'constants/action_types'
import { logError } from 'util/debug'
import { getUserAgent } from 'util/browser'
import { doUpdateAudioNotificationsFromUI } from 'ducks/chat/actions/notifications'
import { doSaveWebPushSubscription } from './doSaveWebPushSubscription'
import { doSubscribeUserToPush } from './doSubscribeUserToPush'
import { doAskPushNotificationPermission } from './doAskPushNotificationPermission'

const { vapidPublicKey: VAPID_PUBLIC_KEY } = config

export const doSubscribe = () => dispatch => {
  const transactionID = uuidV4()
  dispatch({
    type: types.WEB_PUSH_SUBSCRIBE,
    // create a dummy sub optimistically
    data: {
      endpoint: `optimist-${transactionID}`,
      userAgent: getUserAgent(),
    },
    optimist: { type: BEGIN, id: transactionID },
  })

  return doAskPushNotificationPermission()
    .then(() => dispatch(doSubscribeUserToPush(VAPID_PUBLIC_KEY)))
    .then(webPushSubscription =>
      dispatch(doSaveWebPushSubscription(webPushSubscription))
    )
    .catch(reason => {
      logError('Web Push Subscribing failed', { reason })
      dispatch({
        type: types.WEB_PUSH_SUBSCRIBE_FAIL,
        data: { reason },
        optimist: { type: REVERT, id: transactionID },
      })
      dispatch(doUpdateAudioNotificationsFromUI(false))
      Bugsnag.notify(reason)
    })
    .then(() =>
      dispatch({
        type: types.WEB_PUSH_SUBSCRIBE_COMPLETE,
        // Throw away the dummy sub either way
        optimist: { type: REVERT, id: transactionID },
      })
    )
}
