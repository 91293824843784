import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="m12.3162589 0c2.036126 0 3.6837411 1.66294997 3.6837411 3.71059368v10.62318202c0 .9196713-.7507354 1.6662243-1.6700041 1.6662243h-5.3755706c-.4816521 0-.8721083-.3907541-.8721083-.8727737 0-.4820195.3904562-.8727736.8721083-.8727736h5.3013581v-10.54385902c0-1.0869109-.8700378-1.96504637-1.9395245-1.96504637l-5.7622589-.00054731.0005883 13.1892419c0 .839835-.88198156 1.2978411-1.56923202.8215851l-1.70802339-1.184056-1.70794256 1.1839462c-.68705304.4765118-1.56939033.0184641-1.56939033-.8214753v-13.19986288l.001-.01637902.00533077-.13302529c.07534648-.88660106.81494205-1.58495663 1.7194933-1.58495663zm-7.5062589 1.745-3.06584423.00056539.00006085 11.76555991.83584095-.5794052c.4090162-.2836768.98521077-.2836768 1.39440573-.0001098l.83590838.5794782zm7.0985849 8.5362516c.4816521 0 .8721083.390754.8721083.8727736s-.3904562.8727737-.8721083.8727737h-2.9996082c-.4816521 0-.8721083-.3907541-.8721083-.8727737s.3904562-.8727736.8721083-.8727736zm0-3.128996c.4816521 0 .8721083.390754.8721083.8727736s-.3904562.8727737-.8721083.8727737h-2.9996082c-.4816521 0-.8721083-.3907541-.8721083-.8727737s.3904562-.8727736.8721083-.8727736zm0-3.1288966c.4816521 0 .8721083.39075407.8721083.87277365s-.3904562.87277366-.8721083.87277366h-2.9996082c-.4816521 0-.8721083-.39075408-.8721083-.87277366s.3904562-.87277365.8721083-.87277365z"
      />
    </svg>
  )
})
