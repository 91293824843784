import React from 'react'

export default React.forwardRef(({ className, ...rest }, forwardedRef) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      ref={forwardedRef}
    >
      <path
        className="fill"
        d="m12.0056349 5.59975071h-7.98873055c-.44120541 0-.79887305.35815625-.79887305.79996439 0 .4418081.35766764.7999644.79887305.7999644h7.98873055c.4412054 0 .798873-.3581563.798873-.7999644 0-.44180814-.3576676-.79996439-.798873-.79996439zm-3.1954922 3.19985759h-4.79323835c-.44120541 0-.79887305.3581562-.79887305.7999643 0 .4418082.35766764.7999644.79887305.7999644h4.79323835c.4412054 0 .798873-.3581562.798873-.7999644 0-.4418081-.3576676-.7999643-.798873-.7999643zm-.7988731-8.7996083c-4.41205403 0-7.98873051 3.58156256-7.98873051 7.9996439-.00698388 1.8472252.63174258 3.6386685 1.8054531 5.0637745l-1.59774611 1.5999288c-.22667588.2300383-.29286424.5740568-.16776334.8719612.13570321.294377.43543072.4776185.7589294.4639793h7.18985746c4.412054 0 7.9887304-3.5815626 7.9887304-7.9996438 0-4.4180813-3.5766764-7.9996439-7.9887304-7.9996439zm0 14.399359h-5.2645734l.74295193-.7439669c.30974193-.3120161.30974193-.8159337 0-1.1279498-2.15705044-2.1575974-2.49177756-5.5405053-.79949051-8.08002274 1.69228705-2.53951748 4.94030798-3.52842143 7.75784738-2.36198169s4.4199486 4.16338463 3.8273294 7.15815613c-.5926192 2.9947716-3.2152299 5.1533642-6.2640648 5.155765z"
      />
    </svg>
  )
})
