import { agentsQuery } from 'api/agents'
import graphql from 'api/graphql'
import { oauthTokenSelector } from 'selectors/app'
import {
  createFetchAgentsRequestAction,
  createFetchAgentsSuccessAction,
  createFetchAgentsFailAction,
} from './createFetchAgents'

export default function doFetchAgents() {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(createFetchAgentsRequestAction())

    return graphql(oauthTokenSelector(state), agentsQuery())
      .then(res => {
        dispatch(createFetchAgentsSuccessAction(res))
      })
      .catch(err => {
        dispatch(createFetchAgentsFailAction(err))
        throw err
      })
  }
}
