import { UPDATE_INACCESSIBLE_MAILBOXES } from 'constants/action_types'

export function doUpdateInaccessibleMailboxes(mailboxes) {
  return {
    type: UPDATE_INACCESSIBLE_MAILBOXES,
    data: {
      mailboxes,
    },
  }
}
