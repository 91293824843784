import { selectTicketSearchResultsByQueryId } from 'selectors/search'
import metrics from 'util/metrics'

export function doStartSearchTimer(queryId) {
  return (dispatch, getState) => {
    const state = getState()
    const byId = selectTicketSearchResultsByQueryId(state)
    const search = byId[queryId]

    metrics.startOrEnhanceTimer(`search-load-${queryId}`, {
      metric: 'search-load',
      logname: 'main',
      hot: search && search.loaded,
      queryString: queryId,
    })
  }
}
