import { v4 as uuidV4 } from 'uuid'
import { BEGIN, REVERT } from 'redux-optimist'
import * as types from 'constants/action_types'
import { doSendBulkChangeset } from 'actions/tickets'
import { bulkLabelChangePayload } from 'optimistic/label'
import { selectCurrentFolderId } from 'selectors/app'
import { selectAdded, selectRemoved } from 'selectors/labelings'
import { selectLatestTicketSearchQueryObject } from 'selectors/search'
import { selectTicketsById } from 'selectors/tickets/byId/selectTicketsById'
import { getLength, diff, intersection, isEmpty } from 'util/arrays'
import debug from 'util/debug'
import { getId } from 'util/labels'

export default function applyBulkLabelings(state, dispatch, ticketIds) {
  const byId = selectTicketsById(state)
  const addedLabels = selectAdded(state)
  const removedLabels = selectRemoved(state)
  const transactionId = uuidV4()
  const addedLabelIds = addedLabels.map(getId)
  const removedLabelIds = removedLabels.map(getId)
  const payload = {
    addedLabels,
    removedLabels,
    addedLabelIds,
    removedLabelIds,
    ticketIds,
  }

  const addedCount = getLength(addedLabelIds)
  const removedCount = getLength(removedLabelIds)
  if (addedCount + removedCount <= 0) {
    debug('no net label changes to apply!')
    return false
  }

  dispatch({
    type: types.BULK_UPDATE_LABELING_REQUEST,
    data: payload,
    optimist: { type: BEGIN, id: transactionId },
  })

  // For each added/removed label, we need to know which of the selected ticket
  // id(s) to apply it for. We compute two 'diffs', looking to see if the
  // selected tickets labels includes/excludes any of the labels we are
  // adding/removing. This is a naive implementation, meaning some existing
  // labels might be re-added to some of the tickets, but the benefit is that
  // it only uses one graphQL API call
  let ticketIdsToAddLabel = []
  let ticketIdsToRemoveLabel = []

  if (addedCount) {
    ticketIdsToAddLabel = ticketIds.filter(
      id => getLength(diff(addedLabelIds, byId[id].labelIds)) > 0
    )
  }

  if (removedCount) {
    ticketIdsToRemoveLabel = ticketIds.filter(
      id => getLength(intersection(removedLabelIds, byId[id].labelIds)) > 0
    )
  }

  // now structure these diffs in a format that doSendBulkChangeset understands
  const inputs = ticketIds
    .map(id => {
      const out = { ticketId: id }
      if (ticketIdsToAddLabel.includes(id)) out.labels = addedLabels
      if (ticketIdsToRemoveLabel.includes(id)) out.removeLabels = removedLabels
      return out
    })
    .filter(e => e.labels || e.removeLabels) // keep only the ones to update

  if (isEmpty(inputs)) {
    debug('no label changes to apply!')
    return false
  }

  const changesetId = uuidV4()
  const optimisticData = bulkLabelChangePayload(
    state,
    inputs,
    selectCurrentFolderId(state),
    selectLatestTicketSearchQueryObject(state),
    changesetId
  )

  return dispatch(
    doSendBulkChangeset(inputs, {
      optimisticData,
      defaultChangesetId: changesetId,
    })
  )
    .then(() => {
      return dispatch({
        type: types.BULK_UPDATE_LABELING_SUCCESS,
        data: payload,
        optimist: { type: REVERT, id: transactionId },
      })
    })
    .catch(err => {
      return dispatch({
        type: types.BULK_UPDATE_LABELING_FAIL,
        data: { ...payload, err },
        optimist: { type: REVERT, id: transactionId },
      })
    })
}
