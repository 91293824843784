import { selectCurrentSortOrder } from 'selectors/app'
import { selectPrefersClassicView } from 'ducks/currentUser/selectors/preferences/selectPrefersClassicView'
import {
  selectCurrentTicketSearch,
  selectCurrentTicketSearchQueryId,
  selectCurrentTicketSearchQueryObject,
  selectCurrentTicketSearchQueryObjectForAPI,
} from 'selectors/search'
import { doFetchTicketSearch } from './doFetchTicketSearch'

let fetchTicketPerPage

export function doFetchTicketSearchByQueryString(page = 1) {
  return (dispatch, getState) => {
    const state = getState()
    const prefersClassicView = selectPrefersClassicView(state)
    const searchQueryId = selectCurrentTicketSearchQueryId(state)
    const searchQueryObject = selectCurrentTicketSearchQueryObject(state)
    const sortOrder = selectCurrentSortOrder(state)
    const searchQueryObjectForAPI = selectCurrentTicketSearchQueryObjectForAPI(
      state
    )
    const previousPages = selectCurrentTicketSearch(state)
    const previous = previousPages && previousPages[page]
    if (previous && previous.length > 1) return Promise.resolve() // no need to dispatch
    if (window.innerHeight > 960 && !fetchTicketPerPage && prefersClassicView) {
      fetchTicketPerPage = 35
    }
    return dispatch(
      doFetchTicketSearch(
        searchQueryId,
        searchQueryObject,
        searchQueryObjectForAPI,
        sortOrder,
        page,
        fetchTicketPerPage
      )
    )
  }
}
