export { default as addFolder } from './addFolder'
export { default as addressBook } from './addressBook'
export { default as assign } from './assign'
export { default as bot } from './bot'
export { default as chatBotFilled } from './chatBotFilled'
export { default as chatDuoFilled } from './chatDuoFilled'
export { default as chatRectangularDuoFilled } from './chatRectangularDuoFilled'
export {
  default as chatRectangularSingleFilled,
} from './chatRectangularSingleFilled'
export { default as chatSingleFilled } from './chatSingleFilled'
export { default as crm } from './crm'
export { default as company } from './company'
export { default as copyToClipboard } from './copyToClipboard'
export { default as done } from './done'
export { default as replay } from './replay'
export { default as inbox } from './inbox'
export { default as integrations } from './integrations'
export { default as knowledgeBase } from './knowledgeBase'
export { default as liveChat } from './liveChat'
export { default as menu } from './menu'
export { default as merge } from './merge'
export { default as options } from './options'
export { default as reporting } from './reporting'
export { default as search } from './search'
export { default as settings } from './settings'
export { default as shortcuts } from './shortcuts'
export { default as showHide } from './showHide'
export { default as snooze } from './snooze'
export { default as sort } from './sort'
export { default as tag } from './tag'
export { default as inboxLayout } from './inboxLayout'
export { default as updatedPhoneLog } from './updated-phone-log'
export { default as updatedAttach } from './updated-attach'
export { default as updatedAssign } from './updated-reassign'
export { default as updatedDelete } from './updated-delete'
export { default as moveToInbox } from './moveToInbox'
export { default as notificationsActive } from './notifications-active'
export { default as notificationsOff } from './notifications-off'
export { default as smiley } from './smiley'
export { default as mail } from './mail'
export { default as star } from './star'
export { default as stars } from './stars'
export { default as starOutline } from './star-outline'
export { default as stripeLogo } from './stripeLogo'
export { default as mailOpen } from './mail-open'
export { default as addKb } from './add-kb'
export { default as googleLogo } from './googleLogo'
export { default as question } from './question'
