import * as types from 'constants/action_types'

export function doMarkBulkSelectionMode(ticketIds = []) {
  return { type: types.MARK_BULK_SELECTION_MODE, data: { ticketIds } }
}

export function doUnmarkBulkSelectionMode() {
  return {
    type: types.UNMARK_BULK_SELECTION_MODE,
  }
}
